import CustomTooltip from 'components/Tooltips/CustomTooltip/CustomTooltip';
import { StyledTextFieldAutoComplete } from 'components/CustomInputs/CustomAutoCompleteGovernorates/CustomAutoCompleteGovernorates.style';
import { translate } from 'locales/i18n';
import { ICustomAutoCompleteTextFieldProps } from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.type';
import { convertBooleanToBooleanToDom } from 'utils/helpers/string.helpers';

export default function CustomAutoCompleteTextField<T>({
  onChangeTextFieldValue,
  renderTextFieldValue,
  openTooltipText,
  value,
  onMouseOver,
  onMouseOut,
  size,
  anchorRef,
  placeholder,
  multiple,
  disabled,
  fieldName,
  isTextArea,
}: ICustomAutoCompleteTextFieldProps<T>) {
  return (
    <CustomTooltip title={renderTextFieldValue()} placement={'top'} open={openTooltipText}>
      <StyledTextFieldAutoComplete
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        placeholder={
          multiple && value && Array.isArray(value) && value?.length > 0
            ? undefined
            : translate(placeholder ?? 'common.search')
        }
        value={renderTextFieldValue() ?? ''}
        onChange={onChangeTextFieldValue}
        autoComplete={'off'}
        name={fieldName}
        decreasewidth={convertBooleanToBooleanToDom(
          (size && size === 'small') ||
            Boolean(multiple && value && Array.isArray(value) && value?.length > 0),
        )}
        disabled={disabled}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        multiline={isTextArea}
        maxRows={isTextArea ? 2 : undefined}
      />
    </CustomTooltip>
  );
}
