import { Theme } from '@mui/material';

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
          borderRadius: 4,
          height: 48,
        },
      },
    },
  };
}
