import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import {
  injectExpand,
  injectFilterStatus,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { transformZoneItemResponse, transformZoneResponse } from 'redux/api/zones/zone.transform';
import {
  IZonDetailsApiResponse,
  IZoneApiResponse,
  IZoneDetailsData,
} from 'types/models/Zone/Zone.schema';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const zonesApi = createApi({
  reducerPath: 'zonesApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['zonesApi'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getZones: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.ZONES.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: IZoneApiResponse) => {
        return transformZoneResponse(response);
      },
      providesTags: ['zonesApi'],
    }),
    getZoneById: builder.query({
      query: (id: string) => ENDPOINTS.ZONES.GET_BY_ID(id),
      transformResponse: (response: IZonDetailsApiResponse): IZoneDetailsData =>
        transformZoneItemResponse(response),
      providesTags: ['zonesApi'],
    }),
    deleteZone: builder.mutation({
      query: ({ id_zone }) => ({
        url: ENDPOINTS.ZONES.DELETE(id_zone),
        method: 'DELETE',
      }),
      invalidatesTags: ['zonesApi'],
    }),
    createZone: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ZONES.ADD,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['zonesApi'],
    }),
    updateZone: builder.mutation({
      query: ({ id_zone, data }) => ({
        url: ENDPOINTS.ZONES.UPDATE(id_zone),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['zonesApi'],
    }),
  }),
});
export const {
  useGetZonesQuery,
  useLazyGetZonesQuery,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
  useCreateZoneMutation,
  useGetZoneByIdQuery,
} = zonesApi;
