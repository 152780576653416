import { ThemeOptions } from '@mui/material';
import breakpoints from './breakpoints';
import darkPalette from './palettes/palette.dark';
import typography from './typography';

// A custom theme for this app
const darkTheme: ThemeOptions = {
  palette: darkPalette,
  typography,
  breakpoints,
};

export default darkTheme;
