import {
  DealTargetApi,
  IDetailsDeal,
  IDetailsDealApi,
  IDetailsDiscountsApi,
} from 'types/models/Deals/deals.schema';
import { GlobalVariables } from 'config/constant';
import {
  transformItemArticleResponse,
  transformMediaArticle,
  transformPcbObjectArticle,
  transformPricesObject,
} from 'redux/api/article/articlesApi.transform';
import {
  IDiscount,
  IDiscountApi,
  IDiscountResponse,
  IDiscountResponseApi,
} from 'types/models/Discount/discounts.schema';
import { Article, IPackingPrice } from 'types/models/Article/article';
import { getPriceAfterDiscount } from 'utils/helpers/number.helpers';
import { DealTypeEnum, ProductNatureEnum, ProductTypeEnum } from 'types/interfaces/sharedType.type';

function transformDealTarget(data: DealTargetApi[]) {
  return data?.map((target) => {
    return {
      id: target?.id,
      zone: {
        id: target?.zone?.id,
        nameAr: target?.zone?.nameAr,
        nameFr: target?.zone?.nameFr,
        status: target?.zone?.status === GlobalVariables.STATUS.ACTIVE,
        deletedAt: target?.zone?.deletedAt,
      },
      clientCategories: target?.clientCategories?.map((category) => {
        return {
          id: category?.id,
          nameAr: category?.nameAr,
          nameFr: category?.nameFr,
          status: category?.status === GlobalVariables.STATUS.ACTIVE,
          sageId: category?.sageId,
          minimumOrderPrice: category?.minimumOrderPrice,
          deletedAt: category?.deletedAt,
        };
      }),
      clientClasses: target?.clientClasses?.map((clientClass) => {
        return {
          id: clientClass?.id,
          nameAr: clientClass?.nameAr,
          nameFr: clientClass?.nameFr,
          status: clientClass?.status === GlobalVariables.STATUS.ACTIVE,
        };
      }),
      clients: target?.clients?.map((client) => {
        return {
          id: client?.id,
          deletedAt: client?.deletedAt,
          phoneNumber: client?.phoneNumber,
          firstName: client?.firstName,
          lastName: client?.lastName,
          email: client?.email,
          cin: client?.cin,
          sageCode: client?.sageCode,
          clientRisk: client?.clientRisk,
          createdAt: client?.createdAt,
          paymentMethod: client?.paymentMethod,
          clientStatus: client?.clientStatus,
          activationStatus: client?.activationStatus === GlobalVariables.STATUS.ACTIVE,
          budgetLimit: client?.budgetLimit,
          currentBudgetLimit: client?.currentBudgetLimit,
          defaultShop: {
            id: client?.defaultShop?.id,
            name: client?.defaultShop?.name,
            companyName: client?.defaultShop?.companyName,
            latitude: client?.defaultShop?.latitude,
            longitude: client?.defaultShop?.longitude,
            taxRegistrationNumber: client?.defaultShop?.taxRegistrationNumber,
          },
          categoryId: client?.categoryId,
        };
      }),
      sectors: target?.sectors?.map((sector) => {
        return {
          id: sector?.id,
          nameAr: sector?.nameAr,
          nameFr: sector?.nameFr,
          status: sector?.status === GlobalVariables.STATUS.ACTIVE,
          deletedAt: sector?.deletedAt,
          profile: sector?.profile,
          attractionAbility: sector?.attractionAbility,
        };
      }),
      deletedAt: target?.deletedAt,
    };
  });
}

function transformDealsItems(data: IDetailsDealApi) {
  return data?.dealItems?.map((item) => {
    return {
      id: item?.id,
      type: item?.type,
      discount: item?.discount,
      packingType: item?.packingType,
      discountedPrice: item?.discountedPrice,
      originalPrice: item?.originalPrice,
      quantity: item?.quantity,
      article: transformItemArticleResponse(item?.article) as Article,
    };
  });
}

export const transformDealsDetails = (data: IDetailsDealApi): IDetailsDeal => {
  return {
    id: data?.id,
    nameFr: data?.nameFr,
    nameAr: data?.nameAr,
    dealStock: data?.dealStock,
    status: data?.status === GlobalVariables.STATUS.ACTIVE,
    dealEndDate: data?.dealEndDate,
    dealStartDate: data?.dealStartDate,
    descriptionFr: data?.descriptionFr,
    descriptionAr: data?.descriptionAr,
    brand: data?.brand,
    catalogs: data?.catalogs,
    dealType: data?.dealType,
    dealRemainingStock: data?.dealRemainingStock,
    deletedAt: data?.deletedAt,
    name: data?.name,
    type: data?.type,
    ean13Code: data?.ean13Code,
    sageLabel: data?.sageLabel,
    sageReference: data?.sageReference,
    description: data?.description,
    media: transformMediaArticle(data?.media),
    pcbsArray: transformPcbObjectArticle(data?.pcbsObject),
    pricesObject: transformPricesObject(data?.pricesObject),
    dealTargets: transformDealTarget(data?.dealTargets),
    dealItems: transformDealsItems(data),
    dealWarehouse: data?.dealWarehouse,
  };
};

export const transformDiscountResponse = (data: IDiscountResponseApi): IDiscountResponse => {
  return {
    lastPage: data?.lastPage,
    totalPerPage: data?.totalPerPage,
    nextPage: data?.nextPage,
    total: data?.total,
    previousPage: data?.previousPage,
    data: data?.results?.map((item) => transformDiscountItemResponse(item)),
  };
};

const transformDiscountItemResponse = (data: IDiscountApi): IDiscount => {
  return {
    id: data?.id,
    createdAt: data?.createdAt,
    discountPackingPrices: data?.discountPackingPrices?.map((item) => {
      return {
        type: item?.discountPackingPrices?.[0]?.type,
        percentage: item?.discountPackingPrices?.[0]?.percentage,
        warehouseId: item?.warehouseId,
        warehouse: item.warehouse,
      };
    }),
    discountPrices: data?.discountPrices,
    updatedAt: data?.updatedAt,
    startDate: data?.startDate,
    endDate: data?.endDate,
    nameFr: data?.nameFr,
    discountStocks: data?.discountStocks,
    discountRemainingStocks: data?.discountRemainingStocks,
    status: data?.status === GlobalVariables.STATUS.ACTIVE,
    canShowTag: data?.canShowTag ?? false,
    warehouses: data?.warehouses,
  };
};
const getOriginalPrice = (data: IPackingPrice[] | undefined, discountType: string) =>
  data ? data.find((item) => item.type === discountType)?.amount ?? 0 : 0;
const getDiscountedPrice = (data: IPackingPrice[], discountType: string, discount: number) =>
  getPriceAfterDiscount(getOriginalPrice(data, discountType), discount);
export const transformDiscountsDetails = (data: IDetailsDiscountsApi): IDetailsDeal => {
  return {
    id: data?.id,
    nameAr: null,
    catalogs: [],
    status: data?.status === GlobalVariables.STATUS.ACTIVE,
    type: ProductTypeEnum.Deal,
    dealEndDate: data?.endDate,
    nameFr: data?.nameFr,
    name: data?.nameFr,
    dealRemainingStock: data?.discountRemainingStock,
    deletedAt: null,
    sageLabel: null,
    pricesObject: undefined,
    ean13Code: null,
    dealStock: data?.discountStock,
    dealStartDate: data?.startDate,
    dealType: DealTypeEnum.Discount,
    descriptionAr: null,
    descriptionFr: null,
    description: null,
    dealItems: data.discountPrices
      ?.map((item) => {
        return item.discountPackingPrices?.map((discountItem) => {
          return {
            article: transformItemArticleResponse(item?.price?.article) as Article,
            discount: discountItem.percentage,
            type: ProductNatureEnum.Advantage,
            originalPrice: getOriginalPrice(item?.price?.packingPrices, discountItem.type),
            discountedPrice: item?.price?.packingPrices
              ? getDiscountedPrice(
                  item?.price?.packingPrices,
                  discountItem.type,
                  discountItem.percentage,
                )
              : 0,
            id: item?.price?.id ?? 0,
            packingType: discountItem.type,
            // we dont have bundle quantity in the discount so we set it to 1
            quantity: 1,
            warehouse: item?.warehouse,
            warehouseId: item?.warehouseId,
            minOrderDiscountQuantity: discountItem?.minOrderDiscountQuantity,
          };
        });
      })
      .flat(),
    dealTargets: transformDealTarget(data?.targets),
    pcbsArray: [],
    sageReference: null,
    media: [],
    brand: null,
    dealWarehouse: null,
    discountWarehouses: data?.warehouses,
  };
};
