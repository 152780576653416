export const GlobalVariables = {
  NoDataLength: 0,
  ManyDataLength: 2,
  EmptyString: '',
  SpaceSeparator: ' ',
  UnderscoreSeparator: '_',
  TwoPointSeparator: ':',
  DashSeparator: '-',
  TOKEN: 'token',
  EmptyJsonString: '{}',
  TRUE: 'true',
  FALSE: 'false',
  STRING: 'string',
  DATE: 'date',
  ArrowOperator: ' -> ',
  shortTextInputLength: 100,
  MIN: 'min',
  DecimalSeparator: '.',
  ParenthesisOpen: '(',
  ParenthesisClose: ')',
  DebounceTime: {
    Short: 100,
    Medium: 250,
  },
  TableHeight: {
    Default: 350,
    Large: 550,
  },
  ProfileId: { AdminRegional: 1 },
  PHONE_PREFIX: '+216',
  PlusSymbol: '+',

  Date: {
    DefaultFormat: 'DD/MM/YYYY',
    DefaultFormatWithTime: 'DD/MM/YYYY HH:mm',
    DefaultFormatWithTimeSeconds: 'DD/MM/YYYY HH:mm:ss',
    DefaultStringFormatWithTime: 'DD/MM/YYYY - HH[h]mm',
    StringDateFormat: 'dddd DD MMMM',
    StringDateFormatWithYear: 'dddd DD MMMM YYYY',
    PointsDateFormat: 'DD.MM.YY',
    TextFormat: 'DD MMM YYYY',
    TimeFormat: 'HH:mm',
    TimeSecondsFormat: 'HH:mm:ss',
    FullDateFormat: 'dddd DD MMMM YYYY',
    ISO8601DateFormat: 'YYYY-MM-DDT',
    ISO8601DateFormatWithTime: 'YYYY-MM-DDTHH:mm',
    MonthYearFormat: 'MMMM YYYY',
    YearFirstFormat: 'YYYY-MM-DD',
  },

  Map: {
    DefaultZoom: 15,
    DefaultPosition: { lat: 48.856614, lng: 2.3522219 },
  },

  Inputs: {
    shortText: {
      minLength: 3,
      maxLength: 30,
    },
    longText: {
      minLength: 3,
      maxLength: 255,
    },
    shortPassword: {
      minLength: 8,
    },
    siretNumber: {
      minLength: 13,
      maxLength: 13,
      regex: /^[0-9]*$/,
    },
    proNumberCard: {
      minLength: 11,
      maxLength: 11,
      regex: /^[0-9]{5}[a-zA-Z]{2}[0-9]{4}$/,
    },
  },

  Alerts: {
    DefaultDuration: 3000,
    LongDuration: 5000,
    ClearStateDuration: 5500,
  },

  CurrencySymbol: 'TND ',

  Percentage: '%',

  /* Pagination */
  Pagination: {
    ElementsPerPage: { min: 25, median: 50, max: 100 },
    RowsPerPage: 10,
    FirstPage: 1,
  },

  numberOfInstallments: { max: 3 },

  KeyDownTypes: {
    Enter: 'Enter',
    Escape: 'Escape',
  },

  File: {
    DefaultType: 'file',
    DefaultAccept: '*',
    DefaultAcceptType: ['*'],
    DefaultMaxSize: 10485760,
    DefaultMaxSizeInMb: 10,
    maxFileListCount: 5,
    Image: {
      Type: 'file',
      MaxSize: 10485760,
      MaxSizeInMb: 10,
      AcceptType: ['.jpg', '.jpeg', '.png'],
      DefaultAlt: 'image',
    },
    Document: {
      Type: 'file',
      MaxSize: 10485760,
      MaxSizeInMb: 10,
      AcceptType: ['.jpg', '.jpeg', '.png', 'gif', 'svg', '.pdf'],
    },
  },

  /* Inputs autocomplete */
  AUTOCOMPLETE_OFF: 'off',
  AUTOCOMPLETE_ON: 'on',
  AUTOCOMPLETE_EMAIL: 'email',
  AUTOCOMPLETE_CURRENT_PASSWORD: 'current-password',
  AUTOCOMPLETE_ON_NEW_PASSWORD: 'new-password',
  DEBOUNCE_TIME: 500,
  DEBOUNCE_TIME_LONG: 1000,
  DEBOUNCE_TIME_SHORT: 100,
  ALL_CHARACTERS: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789=-&()=+!-',
  STATUS: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  PROFILE: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
  },
  RisqueClientOptions: {
    Risky: 'Risque',
    Solvent: 'Solvable',
    NonSolvent: 'Non Solvable',
  },
  RisqueClientOptionsValue: {
    Risky: 'à risque',
    Solvent: 'solvable',
    NonSolvent: 'non solvable',
  },
  PaymentMethodOptions: {
    Cash: 'Espèce',
    Check: 'Chèque',
    Mixed: 'Mixte',
  },
  StatusClientOptions: {
    Pending: 'En attente',
    Actif: 'Activé',
    Blocked: 'Bloquée',
  },
  MediaTags: {
    Company: 'company',
    TaxRegistration: 'taxRegistration',
  },
  DecimalDegit: 3,
  Slash: '/',
  DoubleDash: '--',
  ErrorCodes: {
    CLIENT_ALREADY_EXISTS: 'CLIENT_ALREADY_EXISTS',
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    INSUFFICIENT_ZONE_STOCK: 'INSUFFICIENT_ZONE_STOCK',
    INSUFFICIENT_STOCK: 'INSUFFICIENT_STOCK',
    UNRESPECTED_MAX_ORDER_QTY: 'UNRESPECTED_MAX_ORDER_QTY',
    UNRESPECTED_MIN_ORDER_PRICE: 'UNRESPECTED_MIN_ORDER_PRICE',
    PRICE_NOT_FOUND: 'PRICE_NOT_setSelectedWarehouseClassErrorFOUND',
    INVALID_DEAL_ITEMS: 'INVALID_DEAL_ITEMS',
    SECTOR_ALREADY_EXISTS: 'SECTOR_ALREADY_EXISTS',
    ZONE_ALREADY_EXISTS: 'ZONE_ALREADY_EXISTS',
    CLIENT_CLASS_ALREADY_EXISTS: 'CLIENT_CLASS_ALREADY_EXISTS',
    CLIENT_CATEGORY_ALREADY_EXISTS: 'CLIENT_CATEGORY_ALREADY_EXISTS',
    CHILDLESS_CATALOG: 'CHILDLESS_CATALOG',
    WAREHOUSE_ALREADY_EXISTS: 'WAREHOUSE_ALREADY_EXISTS',
    FOREIGN_KEY_CONSTRAINT: 'FOREIGN_KEY_CONSTRAINT',
    CATALOG_ALREADY_EXISTS: 'CATALOG_ALREADY_EXISTS',
    INCOMPLETE_ENTITY: 'INCOMPLETE_ENTITY',
    ORDER_CAN_NOT_BE_UPDATED: 'ORDER_CAN_NOT_BE_UPDATED',
    BLOCKED_CLIENT: 'BLOCKED_CLIENT',
    OVERLAPPING_DISCOUNTS_FOUND: 'OVERLAPPING_DISCOUNTS_FOUND',
    OVERLAPPING_DEALS_FOUND: 'OVERLAPPING_DEALS_FOUND',
    OVERLAPPING_PROMO_CODES_FOUND: 'OVERLAPPING_PROMO_CODES_FOUND',
    OVERLAPPING_DEALS_FOUND_WITHOUT_NAME: 'OVERLAPPING_DEALS_FOUND_WITHOUT_NAME',
    OVERLAPPING_DISCOUNTS_FOUND_WITHOUT_NAME: 'OVERLAPPING_DISCOUNTS_FOUND_WITHOUT_NAME',
    SALES_LINE_ALREADY_EXISTS: 'SALES_LINE_ALREADY_EXISTS',
    ARTICLE_DOES_NOT_EXIST: 'ARTICLE_DOES_NOT_EXIST',
    ACCESS_PROFILE_ALREADY_EXISTS: 'ACCESS_PROFILE_ALREADY_EXISTS',
    PROMO_CODE_EXISTS: 'PROMO_CODE_EXISTS',
    ORDERS_SYNC_FAILS: 'ORDERS_SYNC_FAILS',
    MISSING_CLIENT_CATEGORY_SAGE_ID: 'MISSING_CLIENT_CATEGORY_SAGE_ID',
    MISSING_CLIENT_CODE: 'MISSING_CLIENT_CODE',
    MISSING_AGENT_CODE: 'MISSING_AGENT_CODE',
    MISSING_SALES_LINE: 'MISSING_SALES_LINE',
    ALREADY_SYNCHED: 'ALREADY_SYNCHED',
    CART_NOT_FOUND: 'CART_NOT_FOUND',
    DISABLED_USER: 'DISABLED_USER',
    ORDER_DOES_NOT_EXIST: 'ORDER_DOES_NOT_EXIST',
    ENTITY_ARCHIVED: 'ENTITY_ARCHIVED',
    UNRESPECTED_MIN_ORDER_QTY: 'UNRESPECTED_MIN_ORDER_QTY',
  },
  // this const will be used to concatenate zone and sector in the same string to be used in access profiles
  ZONE_AND_SECTOR_LABEL: 'page.area',
  REGEX: {
    MATRICULE_FISCALE: /^[0-9]{8}[a-zA-Z]{3,}[0-9]{8}$/,
    EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    PHONE_NUMBER: /^[0-9]{8}$/,
    LoginFieldRegex: /^(?:[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}|[0-9]{8})$/i,
  },
};
