import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import Paginator from 'types/interfaces/Paginator';
import {
  injectFilterStatus,
  paginatorToApiPagination,
  injectFilterProfileId,
  injectFilterClientClassId,
  injectFilterSectorId,
  injectFilterClientCategoryId,
  injectFilterSalesLineId,
  injectExpand,
} from 'utils/services/api.service';
import { IUser, IUserAPI, IUserAPIResponse, IUserResponse } from 'types/models/User/Team/User';
import { transformItemUserResponse, transformUserResponse } from './users.transform';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['Users'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        profileId?: number | null;
        salesLineId?: number | null;
        clientClassId?: number | null;
        sectorId?: number | null;
        clientCategoryId?: number | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.USERS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectFilterProfileId(params.profileId),
          ...injectFilterSalesLineId(params.salesLineId),
          ...injectFilterClientClassId(params.clientClassId),
          ...injectFilterSectorId(params.sectorId),
          ...injectFilterClientCategoryId(params.clientCategoryId),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: IUserAPIResponse): IUserResponse => {
        return transformUserResponse(response);
      },
      providesTags: ['Users'],
    }),
    createUser: builder.mutation({
      query: ({ userData }) => ({
        url: ENDPOINTS.USERS.GET,
        method: 'POST',
        body: { ...userData },
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: ({ id, userData, status }) => ({
        url: ENDPOINTS.USERS.GETBYID(id),
        method: 'PATCH',
        body: { ...userData, status: status },
      }),
      invalidatesTags: ['Users'],
    }),
    getUserById: builder.query({
      query: (id: number) => ENDPOINTS.USERS.GETBYID(id),
      transformResponse: (response: IUserAPI): IUser => transformItemUserResponse(response),
      providesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: ENDPOINTS.USERS.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useLazyGetUsersQuery,
} = usersApi;
