import { Box, Link, Stack, styled, Typography } from '@mui/material';

export const NotFoundContainer = styled(Box)`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
`;

export const NotFoundContent = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const EmojiIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[50],
  zIndex: -1,
  width: 200,
  height: 200,
}));
export const EmojiImage = styled('img')`
  width: 140px;
  height: 140px;
`;
export const NotFoundWrapper = styled(Stack)`
  width: 65%;
`;

export const Title = styled(Typography)`
  && {
    font-size: 65px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-size: 21px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
  }
`;

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 400,
}));
export const StyledLink = styled(Link)`
  && {
    font-weight: 700;
    border-radius: 40px;
    text-decoration: none;
    color: #388dbc;
  }
`;
