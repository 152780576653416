import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import Paginator from 'types/interfaces/Paginator';
import { injectFilterStatus, paginatorToApiPagination } from 'utils/services/api.service';
import { IAdAPIResponse, IAdDetails, IAdDetailsApi, IAdResponse } from 'types/models/Ad/ad';
import { transformAdDetailsResponse, transformAdResponse } from './Ad.transform';

export const adsApi = createApi({
  reducerPath: 'AdsApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['AdsApi'],
  endpoints: (builder) => ({
    getAd: builder.query({
      query: (params: { paginator?: Paginator; status: boolean | null }) =>
        injectParams(ENDPOINTS.AD.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
        }),
      transformResponse: (response: IAdAPIResponse): IAdResponse => {
        return transformAdResponse(response);
      },
      providesTags: ['AdsApi'],
    }),
    getAdById: builder.query({
      query: (id: number) => ENDPOINTS.AD.GETBYID(id),
      transformResponse: (response: IAdDetailsApi): IAdDetails =>
        transformAdDetailsResponse(response),
      providesTags: ['AdsApi'],
    }),
    createAd: builder.mutation({
      query: ({ adData, status }) => ({
        url: ENDPOINTS.AD.GET,
        method: 'POST',
        body: { ...adData, status },
      }),
      invalidatesTags: ['AdsApi'],
    }),
    addAdMedia: builder.mutation({
      query: ({ id_ad, data }) => ({
        url: ENDPOINTS.AD.ADD_MEDIA(id_ad),
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AdsApi'],
    }),
    updateAd: builder.mutation({
      query: ({ id_Ad, data, status }) => ({
        url: ENDPOINTS.AD.GETBYID(id_Ad),
        method: 'PATCH',
        body: { ...data, status: status },
      }),
      invalidatesTags: ['AdsApi'],
    }),
    deleteAd: builder.mutation({
      query: (id) => ({
        url: ENDPOINTS.AD.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['AdsApi'],
    }),
  }),
});
export const {
  useGetAdQuery,
  useUpdateAdMutation,
  useDeleteAdMutation,
  useGetAdByIdQuery,
  useCreateAdMutation,
  useAddAdMediaMutation,
} = adsApi;
