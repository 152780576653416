import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {
  FormHelperTextStyle,
  TypographyStyle,
  buttonStyle,
  inputStyle,
} from './CustomPhoneInput.style';
import { CustomPhoneInputProps } from './CustomPhoneInput.type';
import { useRef } from 'react';
import { PhoneInputMaxLength } from 'components/CustomInputs/CustomPhoneInput/CustomPhoneInput.constants';

function CustomPhoneInput({
  label,
  value,
  error,
  required,
  name,
  onChange,
}: CustomPhoneInputProps) {
  const { t } = useTranslation();
  const refPhoneInput = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  return (
    <Box>
      {label && <TypographyStyle>{t(label)}</TypographyStyle>}
      <PhoneInput
        inputProps={{
          name: name,
          required: required,
          maxLength: PhoneInputMaxLength,
          ref: refPhoneInput,
        }}
        containerStyle={{ borderRadius: '10px' }}
        dropdownStyle={{
          borderRadius: '10px',
          width: refPhoneInput.current?.offsetWidth,
        }}
        specialLabel=""
        inputStyle={{ ...inputStyle(error, theme) }}
        value={value}
        onChange={(valueToChange) => {
          onChange(valueToChange);
        }}
        buttonStyle={{ ...buttonStyle(theme) }}
        country={'tn'}
        onlyCountries={['tn']}
        autoFormat={true}
        countryCodeEditable={false}
        disableDropdown={true}
        masks={{ tn: '..-...-...' }}
      />
      {error?.message && <FormHelperTextStyle>{t(error?.message)}</FormHelperTextStyle>}
    </Box>
  );
}

export default CustomPhoneInput;
