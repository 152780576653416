import { styled, Typography } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

export const MessageStyle = styled(Typography)({
  fontFamily: GlobalFonts.FONT_PRIMARY,
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  maxWidth: 500,
});
