import { Grid, IconButton } from '@mui/material';
import {
  ContentStyle,
  MenuIconStyle,
  RootStyle,
  ToolbarStyle,
  UserNameStyle,
} from './Navbar.style';
import { NavbarProps } from './Navbar.type';
import PageTitle from './PageTitle/PageTitle';
import ProfileBox from './ProfileBox/ProfileBox';
import SearchBox from './SearchBox/SearchBox';
import { useAppSelector } from 'redux/hooks';
import { GlobalVariables } from 'config/constant';
import { useTranslation } from 'react-i18next';
import { convertBooleanToBooleanToDom } from 'utils/helpers/string.helpers';

export default function Navbar({
  onOpenSidebar: onOpenSidebar,
  isOpenSidebar,
  isSmallScreen,
}: NavbarProps) {
  const config = useAppSelector((state) => state.appReducer.navBarConfig);
  const user = useAppSelector((state) => state.authReducer.user);
  const { t } = useTranslation();
  return (
    <RootStyle isdraweropen={convertBooleanToBooleanToDom(!isSmallScreen || isOpenSidebar)}>
      {isSmallScreen && (
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            mr: 1,
            color: 'text.primary',
          }}
        >
          <MenuIconStyle />
        </IconButton>
      )}

      <ToolbarStyle>
        <ContentStyle container alignItems="center">
          <Grid item xs={12} md={5} lg={4}>
            <PageTitle title={config.title} subtitle={config.subtitle} />
          </Grid>
          <Grid item xs={12} md={5} lg={4} sx={{ display: 'flex' }} justifyContent={'center'}>
            <SearchBox
              searchPlaceholder={config.searchPlaceholder}
              searchText={config.searchText}
              disabled={config.searchDisabled}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={4}
            sx={{ display: 'flex' }}
            justifyContent={'end'}
            alignItems={'center'}
          >
            {/* TODO: get notifications and profile picture from slice */}

            <UserNameStyle variant="h3">
              {t('dashboard.goodMorning')} {user?.firstName} {user?.lastName}
            </UserNameStyle>
            {/* TODO: Check the profilePicture with PM */}
            <ProfileBox profilePicture={GlobalVariables.EmptyString} notifications={[1]} />
          </Grid>
        </ContentStyle>
      </ToolbarStyle>
    </RootStyle>
  );
}
