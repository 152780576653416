import { createTheme } from '@mui/material/styles';
import { AppPaletteMode } from 'config/enums/app.enum';
import ComponentsOverrides from './overrides';
import darkTheme from './theme.dark';
import lightTheme from './theme.light';

const generateTheme = (mode: AppPaletteMode) => {
  switch (mode) {
    case AppPaletteMode.Light: {
      const theme = createTheme(lightTheme);
      theme.components = ComponentsOverrides(theme);
      return theme;
    }
    case AppPaletteMode.Dark: {
      const theme = createTheme(darkTheme);
      theme.components = ComponentsOverrides(theme);
      return theme;
    }
    default: {
      const defaultTheme = createTheme(lightTheme);
      defaultTheme.components = ComponentsOverrides(defaultTheme);
      return defaultTheme;
    }
  }
};

export default generateTheme;
