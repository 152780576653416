import { client } from './client';
import { auth } from './auth';
import { common } from './common';
import { page } from './page';
import { product } from './product';
import { user } from './user';
import { dashboard } from './dashboard';
import { error } from './error';
import { table } from './table';
import { inputs } from './inputs';
import { snack } from './snack';
import { article } from './article';
import { tabs } from './tabs';
import { clientclass } from './clientclass';
import { clientcategory } from './clientcategory';
import { category } from './category';
import { warehouse } from './warehouse';
import { profileAccess } from './profileAccess';
import { ads } from './ads';
import { Schedule } from './Schedule';
import { errorApi } from 'locales/fr/errorApi';
import { notification } from './notification';

const fr = {
  lng: 'fr',
  common,
  error,
  user,
  page,
  ads,
  client,
  auth,
  product,
  dashboard,
  table,
  inputs,
  snack,
  article,
  tabs,
  clientclass,
  clientcategory,
  category,
  warehouse,
  profileAccess,
  Schedule,
  errorApi,
  notification,
};

export default fr;
export type TranslationsType = typeof fr;
