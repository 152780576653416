import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import {
  injectExpand,
  injectFilterStatus,
  injectFilterWarehouseId,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import {
  transformCategoryByIDResponse,
  transformCategoryResponse,
} from 'redux/api/category/category.transform';
import {
  ICategoryApiResponse,
  ICategoryResultsApiResponse,
} from 'types/models/Category/Category.schema';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['categoriesApi'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        warehouseId?: number | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.CATEGORIES.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectFilterWarehouseId(params.warehouseId),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: ICategoryApiResponse) => {
        return transformCategoryResponse(response);
      },
      providesTags: ['categoriesApi'],
    }),
    getCategoriesById: builder.query({
      query: (id: number) => ENDPOINTS.CATEGORIES.GET_BY_ID(id),
      transformResponse: (response: ICategoryResultsApiResponse) => {
        return transformCategoryByIDResponse(response);
      },
      providesTags: ['categoriesApi'],
    }),
    createCategories: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.CATEGORIES.ADD,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['categoriesApi'],
    }),
    addCategoriesMedia: builder.mutation({
      query: ({ id_category, data }) => ({
        url: ENDPOINTS.CATEGORIES.ADD_MEDIA(id_category),
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['categoriesApi'],
    }),
    deleteCategories: builder.mutation({
      query: ({ id_category }) => ({
        url: ENDPOINTS.CATEGORIES.DELETE(id_category),
        method: 'DELETE',
      }),
      invalidatesTags: ['categoriesApi'],
    }),
    updateCategories: builder.mutation({
      query: ({ id_category, data }) => ({
        url: ENDPOINTS.CATEGORIES.UPDATE(id_category),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['categoriesApi'],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useCreateCategoriesMutation,
  useAddCategoriesMediaMutation,
  useDeleteCategoriesMutation,
  useGetCategoriesByIdQuery,
  useUpdateCategoriesMutation,
  useLazyGetCategoriesByIdQuery,
  useLazyGetCategoriesQuery,
} = categoriesApi;
