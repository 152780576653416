import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { LoginData, LoginResponse, LoginResponseData } from 'types/models/Login/login.schema';
import { transformLoginResponse } from './loginApi.transform';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryConfig,
  endpoints: (builder) => ({
    // login api
    login: builder.mutation<LoginResponse, LoginData>({
      query: (loginData) => ({
        url: ENDPOINTS.LOGIN,
        method: 'POST',
        body: loginData,
      }),
      transformResponse: (response: LoginResponseData) => transformLoginResponse(response),
    }),
    // logout api
    logout: builder.mutation({
      query: () => ({
        url: ENDPOINTS.LOGOUT,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
