import {
  Description,
  EmojiIconWrapper,
  EmojiImage,
  NotFoundContainer,
  NotFoundContent,
  NotFoundWrapper,
  StyledLink,
  Subtitle,
  Title,
} from 'components/FallbackErrorBoundary/FallbackErrorBoundary.style';
import EmojiFallBackError from './public/emojiFallBackError.png';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_BUTTON_TEXT,
  DEFAULT_DESCRIPTION,
  DEFAULT_ERROR_CODE,
  DEFAULT_SUBTITLE,
  isKeyExistInI18n,
} from 'components/FallbackErrorBoundary/FallbackErrorBoundary.constant';
import { ReactNode } from 'react';

export default function FallbackErrorBoundary({ customImage }: { customImage?: ReactNode }) {
  const { t } = useTranslation();

  const extractStringFromI18nKey = (key: string, defaultValue: string) =>
    isKeyExistInI18n(key) ? t(key) : defaultValue;
  return (
    <NotFoundContainer>
      <NotFoundContent gap={3}>
        {customImage ?? (
          <EmojiIconWrapper>
            <EmojiImage src={EmojiFallBackError} />
          </EmojiIconWrapper>
        )}

        <NotFoundWrapper spacing={2}>
          <Title variant="h1">
            {extractStringFromI18nKey('error.error_code', DEFAULT_ERROR_CODE)}
          </Title>
          <Subtitle variant="h2">
            {extractStringFromI18nKey('error.swh', DEFAULT_SUBTITLE)}
          </Subtitle>
          <Description>
            {extractStringFromI18nKey('error.error_description', DEFAULT_DESCRIPTION)}
          </Description>
          <StyledLink href="/">
            {extractStringFromI18nKey('error.go_back', DEFAULT_BUTTON_TEXT)}
          </StyledLink>
        </NotFoundWrapper>
      </NotFoundContent>
    </NotFoundContainer>
  );
}
