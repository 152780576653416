export const clientclass = {
  nameFr: 'Désignation',
  status: 'status',
  all: ' Tous',
  active: 'active',
  inactive: 'inactive',
  search: 'Chercher la classe client',
  no_client_classe_yet: "Vous n'avez pas de classes clients",
  designation_invalid: 'La désignation ne peut pas contenir que des espaces',
  delete_warning_message: 'Êtes-vous sûr(e) de vouloir supprimer cette classe de client ?',
};
