import { styled, TextField, Typography } from '@mui/material';

export const TypographyStyle = styled(Typography)({
  marginBottom: 10,
});

export const TextFieldStyle = styled(TextField)({
  div: {
    height: 170,
    '& textarea': {
      height: '80% !important',
      padding: '7px 11px',
    },
  },
});
