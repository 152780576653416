import {
  IClientClassAPIResponse,
  IClientClassResponse,
} from 'types/models/ClientClass/ClientClass';

export const transformClientClassResponse = (
  responseData: IClientClassAPIResponse,
): IClientClassResponse => {
  return {
    data: responseData.results.map((res) => {
      return {
        id: res.id,
        nameAr: res.nameAr,
        nameFr: res.nameFr,
        status: res.status,
        deletedAt: res?.deletedAt,
      };
    }),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
