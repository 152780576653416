import { IAbilities, IAbilitiesApi } from 'types/models/Abilities/Abilities';
import NavItem from 'types/interfaces/NavItem';
import { NavSubjectNameEnum } from 'types/interfaces/sharedType.type';
import { BORouterPaths, GlobalVariables } from 'config/constant';

export const transformNavConfigHelper = (abilitie: IAbilities): NavItem => {
  switch (abilitie.subjectName) {
    case NavSubjectNameEnum.Articles:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.ProductsPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Clients:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.clientsPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Catalogs:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.CategoriesPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Warehouses:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.WarehousePath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.ClientCategories:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.ClientsCategoryPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.ClientClasses:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.ClientsClassPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.AccessProfile:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.ProfileAccessPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Banners:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.AdPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Notifications:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.NotificationPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.PromoCodes:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.PromoPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Orders:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.OrderPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Users:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.teamsPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.HomeSections:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.ManageLandingPagePath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.ShipmentSchedules:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.SchedulePath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.Deals:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.DealsPath,
        action: abilitie.action,
      };
    case NavSubjectNameEnum.SalesLines:
      return {
        title: abilitie.labelFr,
        path: BORouterPaths.CategoriesLogisticListPath,
        action: abilitie.action,
      };
    default:
      return {
        title: GlobalVariables.DoubleDash,
        path: BORouterPaths.loginPath,
        action: abilitie.action,
      };
  }
};
export const transformNavConfigDependent = ({
  hasZone,
  hasSector,
}: {
  hasZone: IAbilitiesApi | null;
  hasSector: IAbilitiesApi | null;
}) => {
  const newNavBarConfig: NavItem[] = [];

  if (hasZone && hasSector) {
    newNavBarConfig.push({
      title: 'page.area',
      path: BORouterPaths.ZonesPath,
      action: hasZone.action,
    });
    newNavBarConfig.push({
      title: '',
      path: BORouterPaths.SectorPath,
      action: hasSector.action,
      notToShow: true,
    });
  }
  return newNavBarConfig;
};
