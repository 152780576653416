// @mui
import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ResponsiveQuery, { ResponsiveQueryEnum } from 'types/interfaces/ResponsiveQuery';

// ----------------------------------------------------------------------

export default function useResponsive(
  query: ResponsiveQuery,
  key: Breakpoint,
  start?: number | Breakpoint,
  end?: number | Breakpoint,
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key));

  const mediaDown = useMediaQuery(theme.breakpoints.down(key));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start || 'md', end || 'md'));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  if (query === ResponsiveQueryEnum.UP) {
    return mediaUp;
  }

  if (query === ResponsiveQueryEnum.DOWN) {
    return mediaDown;
  }

  if (query === ResponsiveQueryEnum.BETWEEN) {
    return mediaBetween;
  }

  if (query === ResponsiveQueryEnum.ONLY) {
    return mediaOnly;
  }

  return null;
}
