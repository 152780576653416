export const auth = {
  // login
  login: 'LOGIN',
  connection: 'Se connecter',
  pin_code_length: 'Le code PIN doit contenir 4 chiffres',
  logout: 'Déconnexion',
  email: 'Adresse E-mail ou téléphone',
  password: 'Mot de passe',
  invalid_email: 'Email invalide',
  password_must_be: `Votre mot de passe doit contenir au minimum 6 caractères.`,
  stay_connected: 'Rester connecté(e)',
  forget_password: 'Mot de passe oublié ?',
  Privacy_Policy: 'Politique de confidentialité. CGV. Langue',
  you_have_no_account: `Vous n'avez pas de compte ?`,
  register_me: 'Faire mon inscription !',
  password_required: 'Mot de passe est requis.',
  email_required: 'E-mail est requis',
  email_invalid: 'E-mail ou téléphone invalide',
  french: 'Français',
  english: 'Anglais',
  email_too_short: 'E-mail trop court',
  email_too_long: 'E-mail trop long',
  invalid_email_or_password: 'Adresse e-mail ou mot de passe invalide.',
  // reset password
  reset_password: 'Réinitialiser le mot de passe',
  reset_password_description: `Saisissez votre adresse e-mail pour recevoir le lien de réinitialisation du mot de
  passe.`,
};
