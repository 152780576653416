import { Box, List, styled, Divider } from '@mui/material';

export const BorderStyle = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  height: 0,
}));
export const StyledList = styled(List)({
  paddingInline: 15,
  maxHeight: '100%',
  overflowY: 'auto',
});
export const GroupDivider = styled(Divider)({
  marginTop: '2px',
});
