import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StylesConstants } from './DashboardLayout.constants';

export const RootStyled = styled('div')({
  display: StylesConstants.DashboardRootStyleDisplay,
  minHeight: StylesConstants.DashboardRootStyleMinHeight,
  overflow: StylesConstants.DashboardRootStyleOverFlow,
  // TODO : Check with the poo if we need to add this
  // minWidth: StylesConstants.DashboardRootStyleMinWidth,
  flexDirection: 'row',
  maxHeight: '100vh',
  '@media (min-width:1600px)': {
    minWidth: StylesConstants.DashboardRootStyleBigScreenMinWidth,
  },
});

export const MainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '100vh',
  paddingTop: 0,
  paddingInline: 38,
  paddingBottom: 38,
  width: '-webkit-fill-available',
}));
