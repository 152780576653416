import { Button, styled } from '@mui/material';

export const StyledLoadingButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    opacity: 0.8,
  },
  width: '100%',
  color: theme.palette.grey[50],
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%) !important`,
}));
