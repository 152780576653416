import {
  ISectorApiResponse,
  ISectorDetails,
  ISectorDetailsApi,
  ISectorResponse,
} from 'types/models/Sectors/Sector.schema';
import { GlobalVariables } from 'config/constant';

export const transformSectorResponse = (responseData: ISectorApiResponse): ISectorResponse => {
  return {
    data: responseData.results.map((res) => {
      return {
        id: res.id,
        nameFr: res.nameFr,
        nameAr: res.nameAr,
        zone: res.zone,
        delegations: res.delegations,
        attractionAbility: res.attractionAbility,
        sectorProfile: res.sectorProfile,
        status: res.status === GlobalVariables.STATUS.ACTIVE,
      };
    }),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
export const transformSectorDetails = (response: ISectorDetailsApi): ISectorDetails => {
  return {
    nameFr: response?.nameFr,
    nameAr: response?.nameAr,
    zone: response?.zone,
    delegations: response?.delegations,
    attractionAbility: response?.attractionAbility,
    profile: response?.profile,
    status: response?.status === GlobalVariables.STATUS.ACTIVE,
    deletedAt: response?.deletedAt,
  };
};
