import { InputObject, InputSize, InputTypes, OptionType } from 'types/interfaces/Input.type';
import i18n, { translate } from 'locales/i18n';
import { GlobalVariables } from 'config/constant';
import { FieldValues } from 'react-hook-form';
import {
  IAddProductPackagingFormConfig,
  InputStockFieldNameEnum,
  IProductPackageType,
} from 'features/Products/AddProducts/AddProducts.types';
import { checkMaxIsValid } from 'features/Products/AddProducts/AddProductTabs/ProductPrices/ProductPrices.helpers';
import { ValidationMessages } from 'utils/validators/input.validators';

export enum PackingTypeEnum {
  Unit = 'unit',
  Rack = 'display',
  Pack = 'carton',
  Pallet = 'pallet',
}
export enum InfoProductFormEnum {
  NameFr = 'nameFr',
  NameAr = 'nameAr',
  DescriptionFr = 'descriptionFr',
  DescriptionAr = 'descriptionAr',
  Reference = 'reference',
  Ean = 'ean',
  SageLabel = 'sageLabel',
  Support = 'hasSupport',
  SageCatalogs = 'sageCatalogs',
}
export const formValueMapInfoProduct = [
  { formKey: InfoProductFormEnum.NameFr, sourceKey: 'nameFr' },
  { formKey: InfoProductFormEnum.NameAr, sourceKey: 'nameAr' },
  { formKey: InfoProductFormEnum.DescriptionFr, sourceKey: 'descriptionFr' },
  { formKey: InfoProductFormEnum.DescriptionAr, sourceKey: 'descriptionAr' },
  { formKey: InfoProductFormEnum.Reference, sourceKey: 'sageReference' },
  { formKey: InfoProductFormEnum.Ean, sourceKey: 'ean13Code' },
  { formKey: InfoProductFormEnum.SageLabel, sourceKey: 'sageLabel' },
];
export const addProductsFormConfig = [
  {
    label: 'inputs.product_name_fr',

    inputConfig: {
      label: '',
      inputType: InputTypes.TEXT,
      fieldName: InfoProductFormEnum.NameFr,
      config: {
        required: true,
      },
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.product_name_fr'),
    },
  },
  {
    label: 'inputs.product_name_ar',

    inputConfig: {
      label: '',
      inputType: InputTypes.TEXT,
      fieldName: InfoProductFormEnum.NameAr,
      config: {
        required: true,
      },
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.product_name_ar'),
    },
  },
  {
    label: 'inputs.product_description_fr',

    inputConfig: {
      label: '',
      inputType: InputTypes.TEXTAREA,
      fieldName: InfoProductFormEnum.DescriptionFr,
      config: {
        maxLength: {
          value: GlobalVariables.Inputs.longText.maxLength,
          message: 'inputs.description_too_long',
        },
      },
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.product_description_fr'),
    },
  },
  {
    label: 'inputs.product_description_ar',

    inputConfig: {
      label: '',
      inputType: InputTypes.TEXTAREA,
      fieldName: InfoProductFormEnum.DescriptionAr,
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.product_description_ar'),
    },
  },
  {
    label: 'inputs.product_reference',

    inputConfig: {
      label: '',
      inputType: InputTypes.TEXT,
      fieldName: InfoProductFormEnum.Reference,
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.product_reference'),
    },
  },
  {
    label: 'inputs.product_EAN',

    inputConfig: {
      label: '',
      inputType: InputTypes.NUMBER,
      fieldName: InfoProductFormEnum.Ean,
      config: {
        minLength: {
          value: GlobalVariables.Inputs.siretNumber.minLength,
          message: 'article.ean_too_short',
        },
      },
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.product_EAN'),
    },
  },
  {
    label: 'inputs.sage_designation',

    inputConfig: {
      label: '',
      inputType: InputTypes.TEXT,
      fieldName: InfoProductFormEnum.SageLabel,
      size: InputSize.MEDIUM,
      placeholder: i18n.t('inputs.placeholder.sage_designationPlaceholder'),
    },
  },
];
export const sageCatalogsFormConfig: InputObject = {
  id: InfoProductFormEnum.SageCatalogs,
  label: 'inputs.sage_catalogs',
  inputType: InputTypes.AUTOCOMPLETE,
  fieldName: InfoProductFormEnum.SageCatalogs,
  defaultValue: [],
  size: InputSize.MEDIUM,
  placeholder: i18n.t('inputs.placeholder.sage_catalogs'),
  autoComplete: {
    options: [],
    multiple: true,
  },
  disabled: true,
};
export const SupportInputConfig: InputObject = {
  id: InfoProductFormEnum.Support,
  label: 'inputs.support_article',
  inputType: InputTypes.RADIO,
  options: [
    { label: 'common.yes', value: 1 },
    { label: 'common.no', value: 0 },
  ],
  fieldName: InfoProductFormEnum.Support,
  defaultValue: 0,
  size: InputSize.MEDIUM,
  isDirectionRadioRow: true,
  radioSpacing: 5,
};

export const productsPricesFormConfig = (
  fieldName: string,
): { label: string; inputConfig: InputObject }[] => {
  return [
    {
      label: 'inputs.unit_Price',

      inputConfig: {
        id: PackingTypeEnum.Unit,
        label: '',
        inputType: InputTypes.PRICE,
        fieldName: `unitPrice_${fieldName}`,
        config: {
          required: ValidationMessages.required,
          min: 0,
        },
        size: InputSize.SMALL,
        placeholder: i18n.t('inputs.placeholder.unit_Price'),
      },
    },
    {
      label: 'inputs.rack_Price',

      inputConfig: {
        id: PackingTypeEnum.Rack,
        label: '',
        inputType: InputTypes.PRICE,
        fieldName: `rackPrice_${fieldName}`,
        config: {
          required: ValidationMessages.required,
          min: 0,
        },
        size: InputSize.SMALL,
        placeholder: i18n.t('inputs.placeholder.rack_Price'),
      },
    },
    {
      label: 'inputs.pack_Price',

      inputConfig: {
        id: PackingTypeEnum.Pack,
        label: '',
        inputType: InputTypes.PRICE,
        fieldName: `packPrice_${fieldName}`,
        config: {
          required: ValidationMessages.required,
          min: 0,
        },
        size: InputSize.SMALL,
        placeholder: i18n.t('inputs.placeholder.pack_Price'),
      },
    },

    {
      label: 'inputs.pallet_Price',

      inputConfig: {
        id: PackingTypeEnum.Pallet,
        label: '',
        inputType: InputTypes.PRICE,
        fieldName: `palletPrice_${fieldName}`,
        config: {
          required: ValidationMessages.required,
          min: 0,
        },
        size: InputSize.SMALL,
        placeholder: i18n.t('inputs.placeholder.pallet_Price'),
      },
    },
  ];
};

export const ProductMaxMinValueFormConfig = (
  fieldName: string,
  convertValue?: (
    key: string,
    value: string,
    max: boolean,
  ) => { fieldName: string; value: number } | undefined,
): { label: string; inputConfig: InputObject }[] => {
  return [
    {
      label: 'inputs.moq',

      inputConfig: {
        label: '',
        inputType: InputTypes.NUMBER,
        fieldName: `${InputStockFieldNameEnum.Moq}_${fieldName}`,
        defaultValue: '',
        config: {
          min: {
            value: 0,
            message: translate('common.min_value', { value: 0 }),
          },
        },
        size: InputSize.MEDIUM,
        placeholder: i18n.t('inputs.placeholder.product_moq'),
      },
    },
    {
      label: 'inputs.maxStock',

      inputConfig: {
        label: '',
        inputType: InputTypes.NUMBER,
        fieldName: `${InputStockFieldNameEnum.MaxStock}_${fieldName}`,
        defaultValue: '',
        config: {
          valueAsNumber: true,

          validate: {
            isValid: (value, formValues) =>
              checkMaxIsValid(
                `${InputStockFieldNameEnum.MaxStock}_${fieldName}`,
                value,
                `${InputStockFieldNameEnum.Moq}_${fieldName}`,
                formValues[`${InputStockFieldNameEnum.Moq}_${fieldName}`],
                convertValue,
              ) || translate('product.verif_max'),
          },
        },
        size: InputSize.MEDIUM,
        placeholder: i18n.t('inputs.placeholder.product_max_stock'),
      },
    },
  ];
};
export enum PRODUCT_PACKAGE_ROWS_INPUT_ENUM {
  PcbUnit = 'pcbUnit',
  PcbRack = 'pcbRack',
  PcbPack = 'pcbPack',
  PcbPallet = 'pcbPallet',
}
const Validation_Min_Pcb_Message = 'inputs.valid_min_message_pcb';
export const ProductPCBFormConfig = (
  onChangeCheckBoxLabelWhenPcbUnitChange: (value: number, packingType: PackingTypeEnum) => void,
): IAddProductPackagingFormConfig[] => [
  {
    label: 'inputs.product_pcb_unit',
    inputConfig: {
      label: '',
      inputType: InputTypes.NUMBER,
      fieldName: PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbUnit,
      defaultValue: '',
      config: {
        min: {
          value: 1,
          message: Validation_Min_Pcb_Message,
        },
      },
      customOnChange: (value) =>
        onChangeCheckBoxLabelWhenPcbUnitChange(Number(value), PackingTypeEnum.Unit),
      size: InputSize.SMALL,
      placeholder: i18n.t('inputs.placeholder.product_pcb_unit'),
    },
  },
  {
    label: 'inputs.product_pcb_rack',

    inputConfig: {
      label: '',
      inputType: InputTypes.NUMBER,
      fieldName: PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbRack,
      defaultValue: '',
      config: {
        min: {
          value: 1,
          message: Validation_Min_Pcb_Message,
        },
      },
      customOnChange: (value) =>
        onChangeCheckBoxLabelWhenPcbUnitChange(Number(value), PackingTypeEnum.Rack),
      size: InputSize.SMALL,
      placeholder: i18n.t('inputs.placeholder.product_pcbRack'),
    },
  },
  {
    label: 'inputs.product_pcb_package',

    inputConfig: {
      label: '',
      inputType: InputTypes.NUMBER,
      fieldName: PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbPack,
      defaultValue: '',
      config: {
        min: {
          value: 1,
          message: Validation_Min_Pcb_Message,
        },
      },
      size: InputSize.SMALL,
      customOnChange: (value) =>
        onChangeCheckBoxLabelWhenPcbUnitChange(Number(value), PackingTypeEnum.Pack),
      placeholder: i18n.t('inputs.placeholder.product_pcb_package'),
    },
  },
  {
    label: 'inputs.product_pcb_pallet',

    inputConfig: {
      label: '',
      inputType: InputTypes.NUMBER,
      fieldName: PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbPallet,
      defaultValue: '',
      config: {
        min: {
          value: 1,
          message: Validation_Min_Pcb_Message,
        },
      },
      customOnChange: (value) =>
        onChangeCheckBoxLabelWhenPcbUnitChange(Number(value), PackingTypeEnum.Pallet),
      size: InputSize.SMALL,
      placeholder: i18n.t('inputs.placeholder.product_pcb_pallet'),
    },
  },
];
export enum SelectorAddProductsPackingOptionsValue {
  Unit = 1,
  Rack = 3,
  Pack = 4,
}

export const CheckBoxProductsLabel: OptionType<string>[] = [
  {
    label: translate('inputs.unit'),
    value: PackingTypeEnum.Unit,
  },
  {
    label: translate('inputs.rack'),
    value: PackingTypeEnum.Rack,
  },
  {
    label: translate('inputs.pack'),
    value: PackingTypeEnum.Pack,
  },
  {
    label: translate('inputs.pallet'),
    value: PackingTypeEnum.Pallet,
  },
];
export const PRODUCTPRICESCONSTANTS = {
  minMoq: 'MIN MOQ',
  MOQPackage: 'MIN MOQ/Colisage',
  MaxStock: 'Max MOQ',
  MaxStockPackage: 'Max MOQ/Colisage',
  Brand: 'Marque',
  Category: 'Catégorie',
  SubCategory: 'Sous Catégorie',
};

export enum ProductPackageTypeEnum {
  UnitPackaging = 'unitPackaging',
  FromUnitPackaging = 'fromUnitPackaging',
  RackPackaging = 'rackPackaging',
  FromRackPackaging = 'fromRackPackaging',
  PackPackaging = 'packPackaging',
  FromPackPackaging = 'fromPackPackaging',
  PalletPackaging = 'palletPackaging',
  FromPalletPackaging = 'fromPalletPackaging',
}
export function createPcbLine(
  packingType: PackingTypeEnum,
  pcbKey: string,
  packageTypeKey: ProductPackageTypeEnum,
  ProductPackageType: IProductPackageType,
  values: FieldValues,
) {
  const pcbValue = Number(values?.[pcbKey]);
  if (!pcbValue || pcbValue === 0 || isNaN(pcbValue)) return;
  return {
    packingType,
    pcb: Number(values?.[pcbKey]),
    packingUnitId: Number(ProductPackageType[packageTypeKey]),
  };
}
