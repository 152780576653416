export const GlobalFonts = {
  FONT_PRIMARY: 'Poppins',
  FONT_PRIMARY_100: 'Poppins100',
  FONT_PRIMARY_200: 'Poppins200',
  FONT_PRIMARY_300: 'Poppins300',
  FONT_PRIMARY_500: 'Poppins500',
  FONT_PRIMARY_600: 'Poppins600',
  FONT_PRIMARY_700: 'Poppins700',
  FONT_PRIMARY_800: 'Poppins800',
  FONT_PRIMARY_900: 'Poppins900',

  FONT_PRIMARY_ITALIC: 'PoppinsItalic',

  FONT_SECONDARY: 'IBMPlex',
};
