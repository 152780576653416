import { Theme } from '@mui/material/styles';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          input: {
            marginLeft: 10,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
            '& :hover': {
              borderWidth: 0,
            },
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '& .MuiInputBase-root.Mui-disabled': {
            border: `1px solid ${theme.palette.grey[300]}`,
          },
        },

        paper: {
          boxShadow: `0px 13px 45px ${theme.palette.grey[300]}`,
          borderRadius: '0px 0px 8px 8px !important',
        },
        listbox: {
          minHeight: '10vh',
          maxHeight: '26vh',
          padding: 0,
        },
        tag: {
          backgroundColor: theme.palette.action.selected,
          borderRadius: '16px',
        },
      },
    },
  };
}
