export default function Dialog() {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: 'unset',
        },
      },
    },
  };
}
