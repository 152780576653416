import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import { transformAbilitiesResponse } from 'redux/api/Abilities/Abilities.decoders';
import { IAbilitiesApi } from 'types/models/Abilities/Abilities';
export const abilitiesApi = createApi({
  reducerPath: 'abilitiesApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['Abilities'],
  endpoints: (builder) => ({
    // Get all ABILITIES list
    getAbilities: builder.query({
      query: () => injectParams(ENDPOINTS.ABILITIES, {}),
      transformResponse: (response: IAbilitiesApi[]) => {
        return transformAbilitiesResponse(response);
      },
      providesTags: ['Abilities'],
    }),
  }),
});

export const { useGetAbilitiesQuery } = abilitiesApi;
