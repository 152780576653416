import { Alert, AlertTitle, Snackbar, Typography, Zoom } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearAlert, closeAlert } from 'redux/slices/alerts/alerts';

function AlertDialog() {
  const { t } = useTranslation();
  const alert = useAppSelector((state) => state.alertReducer.alert);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (alert?.active) {
      setTimeout(() => {
        dispatch(clearAlert());
      }, GlobalVariables.Alerts.ClearStateDuration);
    }
  }, [alert]);

  const onClose = () => {
    dispatch(closeAlert());
  };

  if (!alert) {
    return null;
  }

  return (
    <Snackbar
      open={alert?.active}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={GlobalVariables.Alerts.DefaultDuration}
      TransitionComponent={Zoom}
    >
      <Alert variant="filled" severity={alert?.type} color={alert?.color} sx={{ width: '100%' }}>
        <AlertTitle>{t(alert?.title ?? '')}</AlertTitle>
        <Typography variant="body2">{t(alert?.message ?? '')}</Typography>
      </Alert>
    </Snackbar>
  );
}

export default AlertDialog;
