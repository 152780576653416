import { STORAGE_KEYS } from 'config/constant/storage.config';
import { getPersistData, persistData } from 'utils/helpers/storage.helpers';
import { GlobalVariables } from 'config/constant';
import { IUser } from 'types/models/User/Team/User';
export const clearLocalStorage = () => {
  localStorage.clear();
};
export const getUserFromLocalStorage = (): IUser | null | undefined => {
  try {
    return (getPersistData(STORAGE_KEYS.USER, true) as unknown as IUser) ?? null;
  } catch (e) {
    clearLocalStorage();
  }
};

// return the token from local storage if exist
export const getTokenFromLocalStorage = (): string | null => {
  try {
    const token = getPersistData(STORAGE_KEYS.TOKEN, false) as string;
    if (token) {
      return token;
    } else {
      clearLocalStorage();
      return null;
    }
  } catch (error) {
    clearLocalStorage();
    return null;
  }
};
// return the token from local storage if exist
export const getRefreshTokenFromLocalStorage = (): string | null => {
  try {
    const refreshToken = getPersistData(STORAGE_KEYS.REFRESHTOKEN, false) as string;
    if (refreshToken) {
      return refreshToken;
    } else {
      clearLocalStorage();
      return null;
    }
  } catch (error) {
    clearLocalStorage();
    return null;
  }
};
export const getRememberMeFromLocalStorage = (): boolean => {
  try {
    const rememberMe = getPersistData(STORAGE_KEYS.REMEMBER_ME, false) as string;
    return rememberMe === GlobalVariables.TRUE;
  } catch (error) {
    return false;
  }
};
// return the user from local storage if exist
/**
 * Confirms if there is a connected user or not
 * @returns {boolean}
 */
export const checkIsLoggedIn = (): boolean =>
  !!(getTokenFromLocalStorage() && getUserFromLocalStorage());

// save a user in the local storage
export const saveUserToLocalStorage = (user: IUser) => {
  persistData(STORAGE_KEYS.USER, user);
};

// save a token in the local storage
export const saveTokenToLocalStorage = (token: string) => {
  persistData(STORAGE_KEYS.TOKEN, token);
};
// save a token in the local storage
export const saveRefreshTokenToLocalStorage = (refreshToken: string) => {
  persistData(STORAGE_KEYS.REFRESHTOKEN, refreshToken);
};
export const saveRememberMeToLocalStorage = (rememberMe: boolean) => {
  persistData(STORAGE_KEYS.REMEMBER_ME, rememberMe.toString());
};
