import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <Grid container>
      <Outlet />
    </Grid>
  );
}
