import {
  IResponsibilityMappings,
  IResponsibilityMappingsApi,
  IUser,
  IUserAPI,
  IUserAPIResponse,
  IUserResponse,
} from 'types/models/User/Team/User';
import { transformSectorResponse } from 'redux/api/zones/zone.transform';
import { GlobalVariables } from 'config/constant';
import { transformCategoriesLogisticsDataResponse } from 'redux/api/CategoriesLogistics/categoriesLogistics.transform';
import { transformProfileAccessByIdResponse } from 'redux/api/profileAccess/profileAccess.transform';

export const transformUserResponse = (responseData: IUserAPIResponse): IUserResponse => {
  return {
    data: responseData.results.map((res) => transformItemUserResponse(res)),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
export const transformItemUserResponse = (res: IUserAPI): IUser => {
  return {
    id: res.id,
    email: res.email,
    firstName: res.firstName,
    lastName: res.lastName,
    status: res.status === GlobalVariables.STATUS.ACTIVE,
    deletedAt: res.deletedAt,
    createdAt: res.createdAt,
    isSuper: res.isSuper,
    updatedAt: res.updatedAt,
    sageCode: res.sageCode,
    pinCode: res.pinCode,
    phoneNumber: res.phoneNumber,
    profile: res?.profile && transformProfileAccessByIdResponse(res.profile),
    responsibilityMappings:
      res.responsibilityMappings && transformResponsibilityMappings(res.responsibilityMappings),
  };
};
const transformResponsibilityMappings = (
  responsibilityMappings: IResponsibilityMappingsApi[],
): IResponsibilityMappings[] => {
  return responsibilityMappings?.map((responsibilityMapping) => {
    return {
      salesLines:
        responsibilityMapping.salesLines?.length > 0
          ? responsibilityMapping.salesLines.map((item) =>
              transformCategoriesLogisticsDataResponse(item),
            )
          : [],
      clientCategories: responsibilityMapping.clientCategories,
      clientClasses: responsibilityMapping.clientClasses,
      sectors: responsibilityMapping.sectors
        ? transformSectorResponse(responsibilityMapping.sectors)
        : [],
    };
  });
};
