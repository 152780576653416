import { Theme } from '@mui/material';

const generateTooltipPlacementOverrides = (
  placement: 'marginBottom' | 'marginTop' | 'marginRight' | 'marginLeft',
) => ({
  '& .MuiTooltip-arrow': {
    [placement]: '-0.6rem !important',
  },
});

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          margin: 0,
          borderRadius: '5px',
        },
        tooltip: {
          backgroundColor: theme.palette.grey[50],
          border: 'none',
          borderRadius: '5px',
          padding: 0,
          fontSize: '0.9rem',
          margin: 0,
          width: 'inherit',
          color: theme.palette.text.primary,
          boxShadow: `0px 13px 45px ${theme.palette.grey[300]}`,
          span: { color: theme.palette.grey[50] },
        },
        tooltipPlacementTop: generateTooltipPlacementOverrides('marginBottom'),
        tooltipPlacementBottom: generateTooltipPlacementOverrides('marginTop'),
        tooltipPlacementLeft: generateTooltipPlacementOverrides('marginRight'),
        tooltipPlacementRight: generateTooltipPlacementOverrides('marginLeft'),
      },
    },
  };
}
