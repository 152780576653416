import { styled } from '@mui/material';

export const RootStyle = styled('form')(({ theme }) => ({
  margin: '2% auto',
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  minWidth: '250px',
  minHeight: '250px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '90%',
    minHeight: '90%',
  },
}));
export const StyledLogo = styled('img')`
  display: block;
  margin: 0 auto;
  width: 50%;
`;
