import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import useDebounce from './useDebounce';
import { useLogoutMutation } from 'redux/api/auth/login/loginApi';
import { GlobalVariables } from 'config/constant';
import { getPersistData } from 'utils/helpers/storage.helpers';
import { STORAGE_KEYS } from 'config/constant/storage.config';

export default function useLoggedIn() {
  const [logout] = useLogoutMutation();
  const location = useLocation();
  const debouncedLocation = useDebounce(location, GlobalVariables.DEBOUNCE_TIME_LONG);
  useEffect(() => {
    const token = getPersistData(STORAGE_KEYS.TOKEN, false) as string;

    try {
      if ((!token || !token.length) && isLoggedIn) {
        logout(null);
      } else {
        return;
      }
    } catch (e) {
      logout(null);
    }
  }, [debouncedLocation]);

  const isLoggedIn = useAppSelector((state) => {
    return state.authReducer.isLoggedIn;
  });

  return isLoggedIn;
}
