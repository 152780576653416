export const clientcategory = {
  nameFr: 'Désignation',
  status: 'status',
  all: ' Tous',
  active: 'active',
  inactive: 'inactive',
  search: 'Chercher la categorie client',
  no_client_category_yet: "Vous n'avez pas de catégories clients",
  delete_warning_message: 'Êtes-vous sûr de vouloir supprimer cette catégorie de client',
};
