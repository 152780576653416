import { NumericFormat } from 'react-number-format';
import { Stack, TextField, Typography, useTheme } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import { CustomCurrencyTextFieldType } from 'components/CustomCurrencyTextField/CustomCurrencyTextField.type';
import { translate } from 'locales/i18n';
import { useCallback, useState } from 'react';
import CustomTooltip from 'components/Tooltips/CustomTooltip/CustomTooltip';

export default function CustomCurrencyTextField({
  fieldName,
  label,
  size,
  value,
  placeholder,
  required,
  disabled,
  onChange,
  error,
  useTooltip,
}: CustomCurrencyTextFieldType) {
  const theme = useTheme();
  const [openTooltip, setOpenTooltip] = useState(false);
  const onOpenTooltip = useCallback(
    (valueToDisplay: string) => {
      if (useTooltip && valueToDisplay.length > 4) {
        setOpenTooltip(true);
      }
    },
    [useTooltip],
  );
  const onCloseTooltip = useCallback(() => {
    if (openTooltip) setOpenTooltip(false);
  }, [openTooltip]);
  return (
    <CustomTooltip open={openTooltip} title={value?.toString() || ''}>
      <Stack spacing={1.5}>
        {label && <Typography variant="h5">{translate(label)}</Typography>}

        <NumericFormat
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .Mui-disabled': {
              WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: '8px',
            },
          }}
          onMouseEnter={() => onOpenTooltip(value?.toString() || '')}
          onMouseLeave={onCloseTooltip}
          fullWidth
          value={value}
          prefix={GlobalVariables.CurrencySymbol}
          customInput={TextField}
          size={size}
          placeholder={placeholder && translate(placeholder)}
          required={required}
          disabled={disabled}
          readOnly={disabled}
          decimalScale={3}
          fixedDecimalScale
          thousandSeparator
          onValueChange={(values) => {
            if (values && values.floatValue) onChange(values?.floatValue);
          }}
          name={fieldName}
          helperText={error?.message && translate(error?.message)}
          error={!!error}
          defaultValue={0}
          autoComplete="off"
        />
      </Stack>
    </CustomTooltip>
  );
}
