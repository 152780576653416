import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { injectExpand } from 'utils/services/api.service';
import { IGovernoratesResults } from 'types/models/Governorate/Governorate.schema';
import { transformGovernoratesResponse } from 'redux/api/governorate/governorates.transform';

import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const governoratesApi = createApi({
  reducerPath: 'governoratesApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['governoratesApi'],
  endpoints: (builder) => ({
    getGovernorates: builder.query({
      query: (params: { expand?: ExpandEnum[] | null }) =>
        injectParams(ENDPOINTS.GOVERNORATES.GET, {
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: IGovernoratesResults[]) => {
        return transformGovernoratesResponse(response);
      },
      providesTags: ['governoratesApi'],
    }),
  }),
});
export const { useGetGovernoratesQuery } = governoratesApi;
