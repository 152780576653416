import { Box, styled } from '@mui/material';

export const RootStyle = styled(Box)(({ theme }) => ({
  minHeight: `100vh`,
  minWidth: '100%',
  backgroundColor: theme.palette.grey[50],
  [theme.breakpoints.down('lg')]: {
    minWidth: '100%',
    minHeight: `100vh`,
  },
}));

export const LogoStyle = styled(Box)(() => ({
  backgroundColor: 'primary.main',
  fontSize: 25,
  padding: 2.5,
  borderRadius: 2,
  width: '10%',
  height: '10%',
  justifyContent: 'center',
}));
