import { IGovernorates, IGovernoratesResults } from 'types/models/Governorate/Governorate.schema';

export const transformGovernoratesResponse = (
  response: IGovernoratesResults[],
): IGovernorates[] => {
  return response.map((item) => ({
    name: item?.name,
    code: item?.code,
    delegations: item?.delegations
      ? item.delegations.map((delegation) => ({
          name: delegation?.name,
          code: delegation?.code,
          governorateCode: delegation?.governorateCode,
        }))
      : [],
  }));
};
