export const snack = {
  success: 'Succès!',
  error: 'Une erreur est survenue',
  warning: 'Attention!',
  info: 'Information',
  user_delete_error: "L'utilisateur ne peut pas supprimer son compte",
  user_disable_error: "L'utilisateur ne peut pas désactiver son compte",
  error_email_already_exists: 'Cet E-mail existe déjà',
  error_phone_already_exists: 'Ce numéro de téléphone existe déjà',
  autorisation_path_error: "Vous n'avez pas l'autorisation d'accéder à cette page",
  update_alert:
    " Les fonctionnalité et les modules liées seront automatiquement mis à jour suite à l'action",
  tooltip: {
    copy: 'Copier!',
  },
};
