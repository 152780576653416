export const inscription = {
  first_name: 'First Name',
  last_name: 'Last Name',
  mail_address: 'E-mail address',
  phone_number: 'Phone',
  question: 'A coach you to sponsor ?',
  promo_code: 'Add your promo code',
  see_FAQ: 'see our FAQ',
  or_contact: 'or contact',
  via_chat: 'we directly via the chat',

  file: {
    drag_drop: 'Drag and drop here or',
    browse_file: 'Browse file',
    upload_file: 'Upload file',
    accepted_file_types: 'Accepted file types',
    max_size: 'Max size',
    mb: 'Mb',
    invalid: 'Invalid file, try again',
  },
  // Your information
  about_you: 'Let’s talk about you !',
  phrase: 'We need these elements to contact you and discover you',
  need_help: `Need some help?`,
};
