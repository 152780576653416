import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectFilterStatus, paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import {
  IClientCategoryAPIResponse,
  IClientCategoryResponse,
} from 'types/models/ClientCategory/ClientCategory';
import { transformClientCategoryResponse } from './clientCategory.transform';
export const clientCategoryApi = createApi({
  reducerPath: 'clientCategoryApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['ClientsCategory'],
  endpoints: (builder) => ({
    getClientCategory: builder.query({
      query: (params: { paginator?: Paginator; status: boolean | null }) =>
        injectParams(ENDPOINTS.CLIENTCATEGORY.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
        }),
      transformResponse: (response: IClientCategoryAPIResponse): IClientCategoryResponse => {
        return transformClientCategoryResponse(response);
      },
      providesTags: ['ClientsCategory'],
    }),
    createClientCategory: builder.mutation({
      query: ({ nameAr, nameFr, minimumOrderPrice, status }) => ({
        url: ENDPOINTS.CLIENTCATEGORY.GET,
        method: 'POST',
        body: { nameAr, nameFr, minimumOrderPrice, status },
      }),
      invalidatesTags: ['ClientsCategory'],
    }),
    getClientCategoryById: builder.query({
      query: (id: number) => ENDPOINTS.CLIENTCATEGORY.GETBYID(id),
      providesTags: ['ClientsCategory'],
    }),
    deleteClientCategory: builder.mutation({
      query: ({ id }) => ({
        url: ENDPOINTS.CLIENTCATEGORY.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['ClientsCategory'],
    }),
    updateClientCategory: builder.mutation({
      query: ({ id, nameAr, nameFr, minimumOrderPrice, status }) => ({
        url: ENDPOINTS.CLIENTCATEGORY.GETBYID(id),
        method: 'PATCH',
        body: { nameAr, nameFr, minimumOrderPrice, status },
      }),
      invalidatesTags: ['ClientsCategory'],
    }),
  }),
});

export const {
  useCreateClientCategoryMutation,
  useUpdateClientCategoryMutation,
  useGetClientCategoryQuery,
  useGetClientCategoryByIdQuery,
  useDeleteClientCategoryMutation,
} = clientCategoryApi;
