import {
  INotification,
  INotificationApi,
  INotificationAPIResponse,
  INotificationResponse,
} from 'types/models/Notification/Notification';
import { GlobalVariables } from 'config/constant';
export const transformItemNotification = (notification: INotificationApi): INotification => {
  return {
    id: notification.id,
    title: notification.title,
    content: notification.content,
    fireDate: notification.fireDate,
    link: notification.link,
    status: notification.status === GlobalVariables.STATUS.ACTIVE,
    deletedAt: notification.deletedAt,
    media: notification.media,
    target: notification.target,
    createdAt: notification.createdAt,
  };
};
export function transformNotificationResponse(
  response: INotificationAPIResponse,
): INotificationResponse {
  return {
    totalPerPage: response.totalPerPage,
    total: response.total,
    lastPage: response.lastPage,
    nextPage: response.nextPage,
    previousPage: response.previousPage,
    data: response?.results?.map(transformItemNotification) || [],
  };
}
