import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubTitleStyle, TitleStyle } from './PageTitle.style';
import { PageTitleProps } from './PageTitle.type';

function PageTitle({ title, subtitle }: PageTitleProps) {
  const { t } = useTranslation();
  return (
    <Box>
      {title && <TitleStyle variant="h2">{t(title)}</TitleStyle>}
      {subtitle && <SubTitleStyle variant="subtitle1">{t(subtitle)}</SubTitleStyle>}
    </Box>
  );
}

export default PageTitle;
