import { Stack, useTheme } from '@mui/material';
import CustomImage from 'components/Common/CustomImage/CustomImage';
import { useTranslation } from 'react-i18next';
import { CertificateIconStyle, RootStyle } from './ProfileBox.style';
import { ProfileBoxProps } from './ProfileBox.type';
import ProfileIcon from 'assets/icons/user-icon.svg';

function ProfileBox({ profilePicture, isCertificated }: ProfileBoxProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <RootStyle>
        <CustomImage
          src={profilePicture ? profilePicture : ProfileIcon}
          alt={t('user.profile_image')}
          width={63}
          height={63}
          onViewImage={() => {
            // TODO: Navigate to profile page
          }}
          sx={{
            border: `1px solid ${theme.palette.grey[100]}`,
            borderRadius: '11px',
            overflow: 'hidden',
          }}
          imgSx={{
            cursor: 'pointer',
            objectFit: 'cover',
            borderRadius: '11px',
            transition: 'transform 1s ease',
            '&:hover': {
              transform: 'scale(1.3)',
            },
          }}
        />
        {isCertificated && <CertificateIconStyle />}
      </RootStyle>
    </Stack>
  );
}

export default ProfileBox;
