import { MenuList, Popper, styled, Theme, Typography } from '@mui/material';

export const StyledMenuList = styled(MenuList)({
  overflow: 'auto',
  maxHeight: 300,
  borderRadius: 10,
  borderBottomWidth: 0,
});
export const StyledPopper = styled(Popper)(
  ({ theme, width }: { theme: Theme; width: number | undefined }) => ({
    width: width ?? 'auto',
    height: 'auto',
    position: 'relative',
    left: '-25px',
    border: '1px solid',
    borderColor: theme.palette.grey[50],
    zIndex: theme.zIndex.modal + 1,
    '& .MuiPaper-root': {
      borderRadius: '7px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottom: '0px !important',
      borderTop: '0px !important',
    },
  }),
);

export const StyledTypographyLabel = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  '&:hover': {
    maxWidth: 'none',
  },
});
