import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import {
  paginatorToApiPagination,
  injectFilterStatus,
  injectExpand,
} from 'utils/services/api.service';
import {
  ISectorApiResponse,
  ISectorDetailsApi,
  ISectorResponse,
} from 'types/models/Sectors/Sector.schema';
import { transformSectorDetails, transformSectorResponse } from './sectors.transform';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const sectorApi = createApi({
  reducerPath: 'sectorApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['sectorApi', 'zonesApi'],
  endpoints: (builder) => ({
    getSectors: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.SECTORS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: ISectorApiResponse): ISectorResponse => {
        return transformSectorResponse(response);
      },
      providesTags: ['sectorApi'],
    }),
    deleteSector: builder.mutation({
      query: ({ id_sector }) => ({
        url: ENDPOINTS.SECTORS.DELETE(id_sector),
        method: 'DELETE',
      }),
      invalidatesTags: ['sectorApi'],
    }),
    createSector: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.SECTORS.ADD,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['sectorApi', 'zonesApi'],
    }),
    updateSector: builder.mutation({
      query: ({ id_sector, data }) => ({
        url: ENDPOINTS.SECTORS.UPDATE(id_sector),
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['sectorApi'],
    }),
    mutateSectorShipmentSchedule: builder.mutation({
      query: ({ warehouseId, sectorId, data }) => ({
        url: ENDPOINTS.SECTORS.SHIPEMENTSCHEDULE(warehouseId, sectorId),
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['sectorApi'],
    }),
    getSectorShipmentSchedule: builder.query({
      query: ({ warehouseId, sectorId }) =>
        injectParams(ENDPOINTS.SECTORS.SHIPEMENTSCHEDULE(warehouseId, sectorId), {}),
      providesTags: ['sectorApi'],
    }),
    getSectorDetails: builder.query({
      query: (id_sector) => ENDPOINTS.SECTORS.DETAILS(id_sector),
      transformResponse: (response: ISectorDetailsApi) => transformSectorDetails(response),
      providesTags: ['sectorApi'],
    }),
  }),
});
export const {
  useGetSectorsQuery,
  useUpdateSectorMutation,
  useDeleteSectorMutation,
  useCreateSectorMutation,
  useMutateSectorShipmentScheduleMutation,
  useGetSectorShipmentScheduleQuery,
  useGetSectorDetailsQuery,
} = sectorApi;
