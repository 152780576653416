import useNetwork from 'hooks/useNetwork';
import { useEffect, useState } from 'react';
import { AlertObject } from 'types/interfaces/AlertObject';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';
import { OFFLINE_DIALOG, ONLINE_DIALOG } from './OfflineDialog.constants';

function OfflineDialog() {
  const isOnline = useNetwork();

  const [alert, setAlert] = useState<AlertObject>();

  useEffect(() => {
    if (alert && isOnline) {
      setAlert({ ...ONLINE_DIALOG });
    } else if (!isOnline) {
      setAlert({ ...OFFLINE_DIALOG });
    } else {
      setAlert(undefined);
    }
  }, [isOnline]);

  const onClose = () => {
    if (alert) {
      setAlert({
        ...alert,
        active: false,
      });
    }
  };

  return alert ? <CustomSnackbar alert={alert} onClose={onClose} /> : null;
}

export default OfflineDialog;
