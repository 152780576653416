import { Typography, styled } from '@mui/material';

export const TitleStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const SubTitleStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
