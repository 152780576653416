import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectFilterStatus, paginatorToApiPagination } from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import {
  IClientClassAPIResponse,
  IClientClassResponse,
} from 'types/models/ClientClass/ClientClass';
import { transformClientClassResponse } from './clientClass.transform';

export const clientClassApi = createApi({
  reducerPath: 'clientClassApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['ClientsClass'],
  endpoints: (builder) => ({
    getClientClass: builder.query({
      query: (params: { paginator?: Paginator; status: boolean | null }) =>
        injectParams(ENDPOINTS.CLIENTCLASS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
        }),
      transformResponse: (response: IClientClassAPIResponse): IClientClassResponse => {
        return transformClientClassResponse(response);
      },
      providesTags: ['ClientsClass'],
    }),
    createClientClass: builder.mutation({
      query: ({ nameAr, nameFr, status }) => ({
        url: ENDPOINTS.CLIENTCLASS.GET,
        method: 'POST',
        body: { nameFr, nameAr, status },
      }),
      invalidatesTags: ['ClientsClass'],
    }),
    deleteClientClass: builder.mutation({
      query: ({ id }) => ({
        url: ENDPOINTS.CLIENTCLASS.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['ClientsClass'],
    }),
    getClientClassById: builder.query({
      query: (id: number) => ENDPOINTS.CLIENTCLASS.GETBYID(id),
      providesTags: ['ClientsClass'],
    }),
    updateClientClass: builder.mutation({
      query: ({ id, nameAr, nameFr, status }) => ({
        url: ENDPOINTS.CLIENTCLASS.GETBYID(id),
        method: 'PATCH',
        body: { nameAr, nameFr, status },
      }),
      invalidatesTags: ['ClientsClass'],
    }),
  }),
});

export const {
  useGetClientClassQuery,
  useCreateClientClassMutation,
  useGetClientClassByIdQuery,
  useUpdateClientClassMutation,
  useDeleteClientClassMutation,
} = clientClassApi;
