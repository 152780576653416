import {
  APP_BAR_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_TABLET,
} from 'layouts/DashboardLayout/DashboardLayout.constants';

export const DRAWER_WIDTH = 220;
export const APP_MOBILE = APP_BAR_MOBILE;
export const APP_DESKTOP = APP_BAR_DESKTOP;
export const APP_TABLET = APP_BAR_TABLET;
