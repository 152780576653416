import { auth } from './auth';
import { common } from './common';
import { inscription } from './inscription';
import { page } from './page';
import { pokemon } from './pokemon';
import { user } from './user';
const en = {
  common,
  pokemon,
  user,
  page,
  auth,
  inscription,
};

export default en;
