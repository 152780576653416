import { Alert, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import GenericInput from 'components/CustomInputs/GenericInput/GenericInput';
import { GlobalVariables, RouterPaths } from 'config/constant';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from 'redux/api/auth/login/loginApi';
import { loginFormConfig } from './LoginForm.constants';
import { RootStyle, StyledLogo } from './LoginForm.style';
import { LoadingButton } from 'components/LoadingButton/LoadingButton';
import logo from 'assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { setRememberMeStore } from 'redux/slices/Auth/authSlice';
import { translate } from 'locales/i18n';
import useNetwork from 'hooks/useNetwork';
import useClearApiCache from 'hooks/useClearApiCache';
import { injectErrorApiResponse } from 'utils/helpers/string.helpers';

function LoginForm() {
  const navigate = useNavigate();
  const isOnline = useNetwork();
  const [isError, setIsError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const { onClearApiCachedData } = useClearApiCache();
  const formMethods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  useEffect(() => {
    setTimeout(() => {
      setIsError('');
    }, 5000);
  }, [isError]);
  const dispatch = useDispatch();
  const handleOnLogin = formMethods.handleSubmit(async (values) => {
    const isLoginFieldPhoneNumber = GlobalVariables.REGEX.PHONE_NUMBER.test(values.email);
    const loginData = {
      login: isLoginFieldPhoneNumber
        ? `${GlobalVariables.PHONE_PREFIX}${values.email}`
        : values.email, // if login field is phone number, add +216 to the beginning
      password: values.password,
      rememberMe: rememberMe,
    };
    try {
      onClearApiCachedData();
      await login(loginData).unwrap();
      dispatch(setRememberMeStore({ rememberMe: rememberMe }));
      navigate(RouterPaths.RootPaths.rootPath);
    } catch (error) {
      const errorMessage = injectErrorApiResponse(error, [
        {
          code: GlobalVariables.ErrorCodes.DISABLED_USER,
        },
      ]);
      setIsError(
        translate(
          isOnline ? errorMessage || 'auth.invalid_email_or_password' : 'common.offline.message',
        ),
      );
    }
  });

  return (
    <FormProvider {...formMethods}>
      <RootStyle>
        <StyledLogo src={logo} alt={translate('common.alt.logo')} />
        <Stack spacing={4} justifyContent="center">
          <Typography variant="h1">{translate('auth.login')}</Typography>
          <GenericInput inputObject={loginFormConfig.email} />
          <GenericInput inputObject={loginFormConfig.password} />
        </Stack>

        <Stack spacing={4}>
          <FormControlLabel
            sx={{ width: 'max-content' }}
            label={translate('auth.stay_connected')}
            control={<Checkbox size="small" />}
            value={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <LoadingButton isLoading={isLoading} onClick={handleOnLogin}>
            {translate('auth.connection')}
          </LoadingButton>
          <Stack alignItems="center">
            {isError.length > GlobalVariables.NoDataLength && (
              <Alert severity="error" sx={{ color: 'red' }}>
                {isError}
              </Alert>
            )}
          </Stack>
        </Stack>
      </RootStyle>
    </FormProvider>
  );
}

export default LoginForm;
