import { useCallback, useRef, useState } from 'react';
import { IconButton, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import { TextFieldStyle } from './CustomTextField.style';
import { CustomTextFieldProps } from './CustomTextField.type';
import { InputTypes } from 'types/interfaces/Input.type';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CustomTooltip from 'components/Tooltips/CustomTooltip/CustomTooltip';
import { translate } from 'locales/i18n';
function CustomTextField({
  name,
  label,
  inputType,
  value,
  error,
  required,
  valueOptions,
  placeholder,
  size,
  textAlign,
  disabled,
  onChange,
  isPassword,
  copy,
  useTooltip,
}: CustomTextFieldProps) {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCopyClick = () => {
    if (inputRef.current?.value) {
      inputRef.current.select();
      navigator.clipboard.writeText(inputRef.current.value);
      window.getSelection()?.removeAllRanges();
      setOpenTooltip(true);
      setTimeout(() => {
        setOpenTooltip(false);
      }, 2000);
    }
  };
  const onOpenTooltip = useCallback(
    (valueToDisplay: string) => {
      if (useTooltip && valueToDisplay.length > 6) {
        setOpenTooltip(true);
      }
    },
    [useTooltip],
  );
  const onCloseTooltip = useCallback(() => {
    if (openTooltip) setOpenTooltip(false);
  }, [openTooltip]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <Stack spacing={1.5}>
      {label && <Typography variant="h5">{translate(label)}</Typography>}
      <CustomTooltip open={openTooltip} title={value as string}>
        <TextFieldStyle
          style={
            disabled ? { border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px' } : {}
          }
          autoComplete={'off'}
          onMouseEnter={() => onOpenTooltip(value as string)}
          onMouseLeave={onCloseTooltip}
          inputRef={inputRef}
          name={name}
          type={
            isPassword
              ? showPassword
                ? inputType === InputTypes.PASSWORD
                  ? InputTypes.TEXT
                  : inputType
                : InputTypes.PASSWORD
              : inputType
          }
          value={value}
          disabled={disabled}
          onChange={onChange}
          error={!!error}
          helperText={!disabled && translate(error?.message || GlobalVariables.EmptyString)}
          fullWidth
          required={required}
          placeholder={placeholder && translate(placeholder)}
          size={size}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            maxLength: valueOptions?.maxLength,
            max: valueOptions?.max,
            sx: {
              textAlign,
            },
          }}
          InputProps={
            isPassword || copy
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {copy && (
                        <IconButton onClick={handleCopyClick}>
                          <CustomTooltip
                            arrow={false}
                            open={openTooltip}
                            disableHoverListener
                            title={translate('copy!')}
                          >
                            <CopyAllIcon />
                          </CustomTooltip>
                        </IconButton>
                      )}
                      {isPassword && (
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      </CustomTooltip>
    </Stack>
  );
}

export default CustomTextField;
