import { Tooltip, TooltipProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function CustomTooltip({ title, children, placement, ...props }: { title: string } & TooltipProps) {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={<Typography padding={'8px 15px'}>{t(title)}</Typography>}
      arrow={props.arrow || true}
      placement={placement || 'top'}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export default CustomTooltip;
