import { IAdAPIResponse, IAdDetails, IAdDetailsApi, IAdResponse } from 'types/models/Ad/ad';
import { transformSectorResponse, transformZoneItemResponse } from 'redux/api/zones/zone.transform';
import { GlobalVariables } from 'config/constant';

export const transformAdResponse = (responseData: IAdAPIResponse): IAdResponse => {
  return {
    data: responseData.results.map((res) => {
      return {
        id: res.id,
        placement: res.placement,
        titleFr: res.titleFr,
        titleAr: res.titleAr,
        startDate: res.startDate,
        endDate: res.endDate,
        link: res.link,
        status: res.status,
        deletedAt: res.deletedAt,
        title: res.title,
        state: res.state,
      };
    }),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
export const transformAdDetailsResponse = (responseData: IAdDetailsApi): IAdDetails => ({
  titleFr: responseData.titleFr,
  sectors: transformSectorResponse(responseData?.sectors),
  zones: (responseData.zones || []).map(transformZoneItemResponse),
  status: responseData.status === GlobalVariables.STATUS.ACTIVE,
  createdAt: responseData.createdAt,
  deletedAt: responseData.deletedAt,
  titleAr: responseData.titleAr,
  title: responseData.title,
  state: responseData.state,
  endDate: responseData.endDate,
  link: responseData.link,
  id: responseData.id,
  placement: responseData.placement,
  media: responseData.media,
  startDate: responseData.startDate,
  updatedAt: responseData.updatedAt,
});
