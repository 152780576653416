import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import Paginator from 'types/interfaces/Paginator';
import { paginatorToApiPagination } from 'utils/services/api.service';
import { IBrandAPIResponse, IBrandResponse } from 'types/models/Brands/Brands';
import { transformBrandResponse } from './brands.transform';

export const brandsApi = createApi({
  reducerPath: 'brandsApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['BrandsApi'],

  endpoints: (builder) => ({
    getBrands: builder.query({
      query: (params: { paginator?: Paginator }) =>
        injectParams(ENDPOINTS.BRANDS.GET, {
          ...paginatorToApiPagination(params.paginator),
        }),
      transformResponse: (response: IBrandAPIResponse): IBrandResponse => {
        return transformBrandResponse(response);
      },
      providesTags: ['BrandsApi'],
    }),
    getBrandById: builder.query({
      query: (id: number) => ENDPOINTS.BRANDS.GETBYID(id),
      providesTags: ['BrandsApi'],
    }),
  }),
});

export const { useGetBrandsQuery, useGetBrandByIdQuery } = brandsApi;
