import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'redux/api/auth/login/loginApi';
import {
  checkIsLoggedIn,
  clearLocalStorage,
  getTokenFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  getUserFromLocalStorage,
  saveTokenToLocalStorage,
  saveRefreshTokenToLocalStorage,
  saveUserToLocalStorage,
  getRememberMeFromLocalStorage,
  saveRememberMeToLocalStorage,
} from 'utils/services/storage.service';
import { RootState } from 'redux/store';
import { IUser, IUserAPI } from 'types/models/User/Team/User';
import { transformItemUserResponse } from 'redux/api/users/users.transform';

interface IUserState {
  user: IUser | null;
  token: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  rememberMe: boolean;
  isLoadingUser: boolean;
}

const INITIAL_STATE: IUserState = {
  user: null,
  token: null,
  refreshToken: null,
  isLoggedIn: false,
  rememberMe: false,
  isLoadingUser: false,
};

const initialState: IUserState = {
  user: getUserFromLocalStorage() as IUser,
  token: getTokenFromLocalStorage(),
  refreshToken: getRefreshTokenFromLocalStorage(),
  isLoggedIn: checkIsLoggedIn(),
  rememberMe: getRememberMeFromLocalStorage(),
  isLoadingUser: false,
};

export const authSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ user: IUserAPI; token: string; refreshToken: string }>,
    ) => {
      const { user, token, refreshToken } = action.payload;
      state.user = transformItemUserResponse(user);
      state.token = token;
      state.refreshToken = refreshToken;
      state.isLoggedIn = true;
    },
    updateIsLoadingUserStore: (state, action: PayloadAction<{ isLoadingUser: boolean }>) => {
      const { isLoadingUser } = action.payload;
      state.isLoadingUser = isLoadingUser;
    },
    updateUserStore: (state, action: PayloadAction<{ user: IUser }>) => {
      const { user } = action.payload;
      state.user = user;
      saveUserToLocalStorage(user);
    },
    setRefreshToken: (state, action: PayloadAction<{ token: string }>) => {
      const { token } = action.payload;
      state.token = token;
      state.isLoggedIn = true;
      saveTokenToLocalStorage(token);
    },
    setLogOut: () => {
      clearLocalStorage();
      return INITIAL_STATE;
    },
    setRememberMeStore: (state, action: PayloadAction<{ rememberMe: boolean }>) => {
      const { rememberMe } = action.payload;
      state.rememberMe = rememberMe;
      saveRememberMeToLocalStorage(rememberMe);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      const { user, token, refreshToken } = payload;
      const decodedUser = transformItemUserResponse(user);
      state.user = decodedUser;
      state.token = token;
      state.refreshToken = refreshToken;
      state.isLoggedIn = true;
      saveUserToLocalStorage(decodedUser);
      saveTokenToLocalStorage(token);
      saveRefreshTokenToLocalStorage(refreshToken);
      saveRememberMeToLocalStorage(true);
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      clearLocalStorage();
      return INITIAL_STATE;
    });
    builder.addMatcher(authApi.endpoints.logout.matchRejected, () => {
      clearLocalStorage();
      return INITIAL_STATE;
    });
  },
});

export default authSlice.reducer;

export const {
  setUser,
  setRefreshToken,
  setLogOut,
  setRememberMeStore,
  updateUserStore,
  updateIsLoadingUserStore,
} = authSlice.actions;
export const GetRefreshTokenStore = (state: RootState) => state.authReducer.refreshToken;
export const GetUserStore = (state: RootState) => state.authReducer.user;
export const GetRememberMeStore = (state: RootState) => state.authReducer.rememberMe;
export const GetIsLoadingUserStore = (state: RootState) => state.authReducer.isLoadingUser;
