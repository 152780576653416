import useDialog from 'hooks/useDialog';
import { Outlet } from 'react-router-dom';
import { MainStyle, RootStyled } from './DashboardLayout.style';
import DashboardNavbar from './Navbar/Navbar';
import DashboardSidebar from './SideBar/SideBar';
import CustomBackDrop from 'components/CustomBackDrop/CustomBackDrop';
import React, { Suspense } from 'react';
import useResponsive from 'hooks/useResponsive';
import { ResponsiveQueryEnum } from 'types/interfaces/ResponsiveQuery';

export default function DashboardLayout() {
  const { isOpen, closeDialog, toggleDialog, openDialog } = useDialog(true);
  const isSmallScreen = useResponsive(ResponsiveQueryEnum.DOWN, 'xl');

  return (
    <RootStyled>
      {/* Header */}
      <DashboardSidebar
        isOpenSidebar={isOpen}
        onCloseSidebar={closeDialog}
        onOpenSidebar={openDialog}
        isSmallScreen={isSmallScreen}
      />

      <MainStyle>
        <DashboardNavbar
          onOpenSidebar={toggleDialog}
          isOpenSidebar={isOpen}
          isSmallScreen={isSmallScreen}
        />
        <Suspense fallback={<CustomBackDrop />}>
          <Outlet />
        </Suspense>
      </MainStyle>
    </RootStyled>
  );
}
