import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import {
  injectActivationStatusClientFilter,
  injectClientStatusFilter,
  injectExpand,
  injectFilterClientCategoryIdArray,
  injectFilterClientClassArray,
  injectFilterSectorId,
  injectFilterZoneId,
  injectWarehouseScopeIds,
  injectZoneChiefId,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import { transformClientsResponse, transformGetClientByIdResponse } from './clientApi.transform';
import { injectParams } from 'utils/helpers/api-url';
import { IClientAPIResponse, IClientResponse } from 'types/models/Client/client';
import { IClientDetailsApi } from 'types/models/Clients/Client';
import { ClientStatusEnum } from 'config/enums/common.enums';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['Clients'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    // Get all demands list
    getClients: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        classId?: number[];
        sectorId?: number[];
        zoneId?: number | number[] | null;
        categoryId?: number[];
        zoneChiefId?: number;
        clientStatus?: ClientStatusEnum;
        warehouseScopeIds?: number[];
        expand?: ExpandEnum[];
      }) =>
        injectParams(ENDPOINTS.CLIENTS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectActivationStatusClientFilter(params.status),
          ...injectFilterClientClassArray(params?.classId),
          ...injectFilterClientCategoryIdArray(params?.categoryId),
          ...injectFilterSectorId(params?.sectorId),
          ...injectFilterZoneId(params?.zoneId),
          ...injectZoneChiefId(params?.zoneChiefId),
          ...injectClientStatusFilter(params?.clientStatus),
          ...injectWarehouseScopeIds(params?.warehouseScopeIds),
          ...injectExpand(params?.expand),
        }),
      transformResponse: (response: IClientAPIResponse): IClientResponse =>
        transformClientsResponse(response),
      providesTags: ['Clients'],
    }),

    getClientById: builder.query({
      query: (id: string) => ENDPOINTS.CLIENTS.GET_BY_ID(id),
      transformResponse: (response: IClientDetailsApi) => transformGetClientByIdResponse(response),
      providesTags: ['Clients'],
    }),
    updateClient: builder.mutation({
      query: ({ id_client, body }) => ({
        url: ENDPOINTS.CLIENTS.UPDATE(id_client),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Clients'],
    }),
    createClient: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.CLIENTS.ADD,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Clients'],
    }),
    deleteClient: builder.mutation({
      query: ({ id_client }) => ({
        url: ENDPOINTS.CLIENTS.DELETE(id_client),
        method: 'DELETE',
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
  useGetClientByIdQuery,
  useUpdateClientMutation,
  useLazyGetClientsQuery,
  useLazyGetClientByIdQuery,
} = clientApi;
