import { Box, RadioGroup, styled } from '@mui/material';
import { ISpacedRadioGroup } from 'components/CustomButtons/RadioButtonsGroup/RadioButtonsGroup.type';
import { BooleanToDom } from 'config/enums/common.enums';

export const RadioBoxStyle = styled(Box)(({ error }: { error: BooleanToDom }) => ({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  border: `1px solid ${
    error === BooleanToDom.TRUE ? theme.palette.error.main : theme.palette.grey[300]
  }`,
  outline: '1px solid transparent',
  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

export const CheckedRadioBoxStyle = styled(Box)(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  border: `1px solid ${theme.palette.primary.main}`,
  outline: '1px solid transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CheckedRadioStyle = styled(Box)(({ theme }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
}));
export const SpacedRadioGroup = styled(RadioGroup)(
  ({ spacing, radio_direction }: ISpacedRadioGroup) =>
    ({ theme }) => ({
      gap: spacing && theme.spacing(spacing),
      flexDirection: radio_direction ?? 'column',
    }),
);
