// material
import { alpha, useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles } from '@mui/material';

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        html: {
          '*::-webkit-scrollbar': {
            width: 6,
            height: 7,
            borderRadius: 4,
          },
          '*::-webkit-scrollbar-thumb': {
            borderRadius: '5px',
            background: `transparent linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
          },
          '*::-webkit-scrollbar-thumb:vertical': {
            borderRadius: '53px',
            background: `transparent linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: alpha(theme.palette.primary.light, 0.07),
            borderRadius: '0px 0px 5px 5px',
          },
        },
      }}
    />
  );
}
