import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RadioButtonsGroupProps } from './RadioButtonsGroup.type';
import {
  CheckedRadioBoxStyle,
  CheckedRadioStyle,
  RadioBoxStyle,
  SpacedRadioGroup,
} from './RadioButtonsGroup.style';
import { IRadioOptionsType } from 'types/interfaces/Input.type';
import { BooleanToDom } from 'config/enums/common.enums';

export function RadioButtonsGroup({
  label,
  placeholder,
  options,
  value,
  error,
  onChange,
  spacing,
  radio_direction,
  disabled,
  useGridLayoutRadio,
}: RadioButtonsGroupProps) {
  const { t } = useTranslation();
  const renderRadioButtons = (option: IRadioOptionsType, index: number) => (
    <FormControlLabel
      key={`${option.label}${index}`}
      value={option.value}
      control={
        <Radio
          size="small"
          icon={<RadioBoxStyle error={error ? BooleanToDom.TRUE : BooleanToDom.FALSE} />}
          checkedIcon={
            <CheckedRadioBoxStyle>
              <CheckedRadioStyle />
            </CheckedRadioBoxStyle>
          }
          disabled={option.disabled || disabled}
        />
      }
      label={t(option.label)}
    />
  );
  return (
    <FormControl error={!!error}>
      <Stack spacing={1.5}>
        {label && <Typography variant="h5">{t(label)}</Typography>}
        {placeholder && <Typography>{t(placeholder)}</Typography>}
        <SpacedRadioGroup
          value={value}
          row
          onChange={onChange}
          spacing={spacing ?? 1}
          radio_direction={radio_direction}
        >
          {useGridLayoutRadio ? (
            <Grid container spacing={useGridLayoutRadio.spacingContainer} padding={2}>
              {options.map((option, i) => (
                <Grid item xs={useGridLayoutRadio.xsItem} key={option.value}>
                  {renderRadioButtons(option, i)}
                </Grid>
              ))}
            </Grid>
          ) : (
            options.map((option, i) => renderRadioButtons(option, i))
          )}
        </SpacedRadioGroup>
        {error && <FormHelperText>{t(error.message as string)}</FormHelperText>}
      </Stack>
    </FormControl>
  );
}
