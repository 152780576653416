export const ads = {
  title: 'Titre',
  startDate: 'Date de début',
  endDate: ' Date de fin',
  status: 'Status',
  add: 'Ajouter une nouvelle publicité',
  search: 'Chercher la publicité',
  delete_warning_message: 'Êtes-vous sûr de vouloir supprimer cette publicité',
  no_data_yet: "Vous n'avez pas encore de publicités",
  redirection: 'Redirection',
  placement: "Emplacement dans l'application",
  startDateTime: 'Date et heure de debut de publicité',
  endDateTime: 'Date et heure de fin de publicité',
  image: 'Choisir une image',
  error_required_Zone_Sector: 'Les champs zones et secteur sont requis',
  type_article: 'Article',
  type_catalog: 'Catégorie',
  type_brand: 'Marque',
  top: 'Top',
  catalog: 'Catalogue',
};
