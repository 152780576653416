import { GlobalVariables } from 'config/constant';

// Check if a value type is a string
export const isString = (word: unknown) => {
  return typeof word === GlobalVariables.STRING || word instanceof String;
};

export const ValidationMessages = {
  required: 'common.required',
};
