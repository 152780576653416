import NavItem from 'types/interfaces/NavItem';
import { IAbilitiesApi } from 'types/models/Abilities/Abilities';
import { NavSubjectNameEnum } from 'types/interfaces/sharedType.type';
import {
  transformNavConfigDependent,
  transformNavConfigHelper,
} from 'layouts/DashboardLayout/SideBar/NavSection/NavSection.helper';
import { GlobalVariables } from 'config/constant';

export const joinPaths = (paths: string[]): string => {
  return paths.join('/');
};

export const createPath = (path: string): string => {
  return `/${path}`;
};
export function extractRouteConfig(abilities: IAbilitiesApi[]): NavItem[] {
  // should return zone et secteur if both exist

  let hasZone: IAbilitiesApi | null = null;
  let hasSector: IAbilitiesApi | null = null;
  const newNavBarConfig = abilities
    ? abilities.map((ability) => {
        if (ability?.subjectName === NavSubjectNameEnum.Zones) hasZone = ability;
        if (ability?.subjectName === NavSubjectNameEnum.Sectors) hasSector = ability;

        return transformNavConfigHelper(ability);
      })
    : [];
  // filter to remove double dash from nav bar

  return [
    ...newNavBarConfig,
    ...transformNavConfigDependent({
      hasZone,
      hasSector,
    }),
  ].filter((item) => item.title !== GlobalVariables.DoubleDash);
}
