export const error = {
  error: 'Erreur',
  swh: 'Oups ! Quelque chose s’est mal passé',
  select_warehouse: 'Veuillez sélectionner un dépôt',
  select_category: 'Veuillez sélectionner une catégorie',
  error_code: '500',
  error_description:
    "Désolé, cela peut être dû au fait que la page est encore en développement ou qu'il y a un problème avec le serveur. Veuillez réessayer plus tard.",
  go_back: 'Retourner',
};
