import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import Paginator from 'types/interfaces/Paginator';
import {
  INotificationApi,
  INotificationAPIResponse,
  INotificationResponse,
} from 'types/models/Notification/Notification';
import {
  paginatorToApiPagination,
  injectFilterStatus,
  injectExpand,
} from 'utils/services/api.service';
import { transformItemNotification, transformNotificationResponse } from './notification.transform';
import { ExpandEnum } from 'types/interfaces/sharedType.type';
export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['notifications'],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.NOTIFICATIONS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: INotificationAPIResponse): INotificationResponse => {
        return transformNotificationResponse(response);
      },
      providesTags: ['notifications'],
    }),
    createNotification: builder.mutation({
      query: ({ notificationsData }) => ({
        url: ENDPOINTS.NOTIFICATIONS.GET,
        method: 'POST',
        body: { ...notificationsData },
      }),
      invalidatesTags: ['notifications'],
    }),
    getNotificationById: builder.query({
      query: (id: number) => ENDPOINTS.NOTIFICATIONS.GETBYID(id),
      transformResponse: (response: INotificationApi) => transformItemNotification(response),
      providesTags: ['notifications'],
    }),
    updateNotification: builder.mutation({
      query: ({ idNotification, data, status }) => ({
        url: ENDPOINTS.NOTIFICATIONS.GETBYID(idNotification),
        method: 'PATCH',
        body: { ...data, status: status },
      }),
      invalidatesTags: ['notifications'],
    }),

    addNotificationMedia: builder.mutation({
      query: ({ idNotification, data }) => ({
        url: ENDPOINTS.NOTIFICATIONS.ADD_MEDIA(idNotification),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['notifications'],
    }),
    deleteNotification: builder.mutation({
      query: (id) => ({
        url: ENDPOINTS.NOTIFICATIONS.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});
export const {
  useCreateNotificationMutation,
  useAddNotificationMediaMutation,
  useGetNotificationsQuery,
  useDeleteNotificationMutation,
  useUpdateNotificationMutation,
  useGetNotificationByIdQuery,
} = notificationsApi;
