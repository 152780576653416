import { useRef } from 'react';
import { Backdrop } from '@mui/material';
import LogoLoader from 'assets/images/logoNoBg.png';
import {
  SlideContainer,
  StyledBackDropContainer,
} from 'components/CustomBackDrop/CustomBackDrop.styled';
import { GlobalVariables } from 'config/constant';

export default function CustomBackDrop() {
  const containerRef = useRef(null);
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 20 }} open={true}>
      <StyledBackDropContainer ref={containerRef}>
        <SlideContainer>
          <img
            src={LogoLoader}
            alt={GlobalVariables.File.Image.DefaultAlt}
            height={100}
            width={100}
          />
        </SlideContainer>
      </StyledBackDropContainer>
    </Backdrop>
  );
}
