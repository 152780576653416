import { styled, TextField } from '@mui/material';

export const TextFieldStyle = styled(TextField)({
  // Remove arrows from the number input type
  'input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  /* Firefox */
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: '0px',
  },
  '& .Mui-disabled input': {
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
  },

  width: '100%',
});
