import { Box, Divider, Drawer, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavSection from './NavSection/NavSection';
import { RootStyle } from './SideBar.style';
import { SidebarProps } from './SideBar.type';
import { DRAWER_WIDTH } from '../Navbar/Navbar.constants';
import logo from 'assets/images/logo.png';
import { StyledLogo } from 'features/Auth/LoginForm/LoginForm.style';
import SwitchPaletteMode from 'layouts/DashboardLayout/SideBar/SwitchPaletteMode/SwitchPaletteMode';
import { translate } from 'locales/i18n';
import { convertBooleanToBooleanToDom } from 'utils/helpers/string.helpers';
import useFirstRender from 'hooks/useFirstRender';

export default function SideBar({
  isOpenSidebar,
  onCloseSidebar,
  isSmallScreen,
  onOpenSidebar,
}: SidebarProps) {
  const { pathname } = useLocation();
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (isOpenSidebar && isSmallScreen && !isFirstRender) {
      onCloseSidebar();
    }
    if (!isSmallScreen) {
      onOpenSidebar();
    }
  }, [pathname, isSmallScreen]);

  const renderContent = (
    <Stack justifyContent="space-between" height={'100vh'}>
      <Box maxHeight={'calc(100vh - 220px)'}>
        <StyledLogo src={logo} alt={translate('common.alt.logo')} />
        <Box overflow={'auto'} height={'100%'}>
          <NavSection />
        </Box>

        <Box justifyContent={'center'} alignContent={'center'} width="100%">
          <Divider variant={'middle'} orientation={'horizontal'} />
          <SwitchPaletteMode />
        </Box>
      </Box>
    </Stack>
  );

  return (
    <RootStyle isopendrawer={convertBooleanToBooleanToDom(isOpenSidebar)}>
      <Drawer
        open={isOpenSidebar}
        variant="persistent"
        PaperProps={{
          sx: { width: DRAWER_WIDTH },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
