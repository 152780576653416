import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { injectParams } from 'utils/helpers/api-url';
import {
  injectExpand,
  injectFilterStatus,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import {
  ICodePromoApiResponse,
  ICodePromoApiResult,
  ICodePromoResponse,
  ICodePromoResult,
} from 'types/models/CodePromo/codePromo.schema';
import {
  transformPromoListResponse,
  transformResponseItemCodePromo,
} from 'redux/api/promo/promo.decoders';
import Paginator from 'types/interfaces/Paginator';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const promoApi = createApi({
  reducerPath: 'promoApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['promoApi'],
  endpoints: (builder) => ({
    getListCodePromo: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;

        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.PROMO.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: ICodePromoApiResponse): ICodePromoResponse => {
        return transformPromoListResponse(response);
      },
      providesTags: ['promoApi'],
    }),
    getCodePromoDetails: builder.query({
      query: (id: string) => ENDPOINTS.PROMO.DETAILS(id),
      transformResponse: (response: ICodePromoApiResult): ICodePromoResult => {
        return transformResponseItemCodePromo(response);
      },
      providesTags: ['promoApi'],
    }),
    generateCode: builder.mutation({
      query: () => ({
        url: ENDPOINTS.PROMO.GENERATE_CODE,
        method: 'POST',
      }),
      invalidatesTags: ['promoApi'],
    }),
    createCodePromo: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.PROMO.CREATE_CODE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['promoApi'],
    }),
    deleteCodePromo: builder.mutation({
      query: (id: number) => ({
        url: ENDPOINTS.PROMO.DELETE(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['promoApi'],
    }),
    updateCodePromo: builder.mutation({
      query: ({ id, body }) => ({
        url: ENDPOINTS.PROMO.UPDATE(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['promoApi'],
    }),
  }),
});
export const {
  useGenerateCodeMutation,
  useCreateCodePromoMutation,
  useGetListCodePromoQuery,
  useDeleteCodePromoMutation,
  useGetCodePromoDetailsQuery,
  useUpdateCodePromoMutation,
} = promoApi;
