import React, { MouseEvent, useRef, useState, Ref, UIEvent } from 'react';
import { Box, Stack, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { useTheme } from '@mui/material/styles';
import { CustomAsyncSelectorProps } from 'components/CustomInputs/CustomAsyncSelector/CustomAsyncSelector.type';
import {
  DividerStyle,
  MenuItemStyle,
  MenuStyle,
  RootStyle,
  SelectIconStyle,
  SelectStyle,
} from '../CustomSelector/CustomSelector.style';
import { generateOptionsSkeleton } from 'components/CustomLoader/CustomLoader';
import { translate } from 'locales/i18n';

const CustomAsyncSelector = <T,>(props: CustomAsyncSelectorProps<T>) => {
  const {
    label,
    options,
    value,
    onChange,
    register,
    isLoading,
    onScrollEnd,
    disabled,
    allowUnselect,
    disableWithMessage,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [messageHelper, setMessageHelper] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const theme = useTheme();

  const onOpen = (event: MouseEvent<HTMLDivElement>) => {
    setMessageHelper(disableWithMessage);
    if (isOpen || disabled || disableWithMessage) {
      return setIsOpen(false);
    }

    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };
  const refSelector = useRef<HTMLDivElement>(null);
  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    // checking if the scroll is at the bottom of the div
    if (element.scrollHeight - element.scrollTop - element.clientHeight < 50) {
      onScrollEnd?.();
    }
  };
  const onClearSelection = () => {
    onChange?.(null); // Call the onChange function with undefined to reset the value.
    setIsOpen(false); // Close the menu.
  };
  return (
    <Box ref={refSelector}>
      <RootStyle ref={refSelector as Ref<HTMLDivElement>}>
        <SelectStyle
          {...register}
          open={isOpen && !disabled}
          disabled={disabled}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={onOpen}
          error={props?.error ? true : undefined}
          autowidth={props.autoWidth ? 'true' : 'false'}
        >
          <Typography variant="h4" color="grey.800">
            {t(value?.label || label)}
          </Typography>
          {allowUnselect && value && (
            <IconButton onClick={onClearSelection}>
              <DeleteIcon fill={theme.palette.error.main} title={t('common.delete')} />
            </IconButton>
          )}
          <SelectIconStyle open={isOpen && !disabled} />
        </SelectStyle>

        {isOpen && !disabled && (
          <Box>
            <MenuStyle
              onScrollCapture={onScroll}
              sx={{ overflow: 'auto' }}
              anchorEl={anchorEl}
              open={isOpen && !disabled}
              onClose={() => setIsOpen(false)}
              PaperProps={{
                sx: {
                  width: refSelector?.current?.offsetWidth,
                  position: 'relative',
                  left: '-25px',
                },
              }}
            >
              {options &&
                !isLoading &&
                options.map(
                  (currentOption, index) =>
                    value?.value !== currentOption.value && (
                      <Stack key={index} spacing={0} alignItems="center">
                        <MenuItemStyle
                          selected={value?.value === currentOption.value}
                          disabled={disabled}
                          onClick={() => {
                            onChange?.(currentOption);
                            setIsOpen(false);
                          }}
                        >
                          <Typography color={theme.palette.grey[700]}>
                            {t(currentOption.label)}
                          </Typography>
                        </MenuItemStyle>
                        {options.length !== index + 1 && <DividerStyle />}
                      </Stack>
                    ),
                )}
              {options?.length === 0 && !isLoading && (
                <Stack spacing={3} alignItems="center">
                  <MenuItemStyle>{t('common.noData')}</MenuItemStyle>
                </Stack>
              )}
              {isLoading && generateOptionsSkeleton({})}
            </MenuStyle>
          </Box>
        )}
        {(props.error || messageHelper) && (
          <Typography variant={'caption'} color={'error'}>
            {translate((props.error || messageHelper) ?? '')}
          </Typography>
        )}
      </RootStyle>
    </Box>
  );
};

export default CustomAsyncSelector;
