export const table = {
  delete: 'Supprimer',
  name: 'Nom',
  identifier: 'Identifiant',
  email: 'Email',
  role: 'Rôle',
  action: 'Action',
  add: 'Ajouter',
  add_client: 'Ajouter un nouveau client',
  add_client_classe: 'Ajouter une nouvelle classe client',
  add_client_category: 'Ajouter une nouvelle catégorie client',
  add_warehouse: 'Ajouter un nouveau dépôt',
  add_admin: 'Ajouter un nouveau Membre',
  groupe_actions: 'Actions groupées',
  zone: {
    name: 'Zone',
    status: 'Status',
    action: 'Action',
  },
};
