import { AppPaletteMode } from 'config/enums/app.enum';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { LogoutButtonStyle, RootStyle } from './SwitchPaletteMode.style';
import { useLogoutMutation } from 'redux/api/auth/login/loginApi';
import { translate } from 'locales/i18n';
import useClearApiCache from 'hooks/useClearApiCache';

function SwitchPaletteMode() {
  const paletteMode = useAppSelector((state) => state.appReducer.mode);
  const _dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const { onClearApiCachedData } = useClearApiCache();
  const _isLight = paletteMode === AppPaletteMode.Light;
  const handleLogout = async () => {
    onClearApiCachedData();
    await logout({}).unwrap();
  };
  const _onSwitchMode = () => {
    // TODO: Activate dark mode switching
    // dispatch(switchMode());
  };

  return (
    <RootStyle justifyContent="center" alignItems="center" spacing={2}>
      {/*/ / TODO: Restore this when working on dark mode*/}
      {/* <MaterialUISwitch onChange={onSwitchMode} checked={!isLight} />*/}
      <LogoutButtonStyle onClick={handleLogout}>
        {translate('common.auth.logout')}
      </LogoutButtonStyle>
    </RootStyle>
  );
}

export default SwitchPaletteMode;
