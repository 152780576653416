import { ContentStyle, RootStyle } from './FormCard.style';
import { FormCardProps } from './FormCard.types';

function FormCard({ children, sx }: FormCardProps) {
  return (
    <RootStyle sx={{ ...sx }}>
      <ContentStyle>{children}</ContentStyle>
    </RootStyle>
  );
}

export default FormCard;
