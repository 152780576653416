import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import alertSlice from './slices/alerts/alerts';
import { clientApi } from './api/client/clientApi';
import { authApi } from './api/auth/login/loginApi';
import authSlice from './slices/Auth/authSlice';
import appSlice from './slices/app/appSlice';
import { articlesApi } from './api/article/articlesApi';
import { clientClassApi } from './api/clientClass/clientClassApi';
import { categoriesApi } from './api/category/category.api';
import categorySlice from './slices/Category/categorySlice';
import { clientCategoryApi } from './api/clientCategory/clientCategoryApi';
import { zonesApi } from 'redux/api/zones/zones.api';
import { sectorApi } from 'redux/api/sectors/sectors.api';
import { warehouseApi } from './api/warehouse/warehouseApi';
import { governoratesApi } from 'redux/api/governorate/governorates.api';
import { usersApi } from './api/users/users.api';
import { profileAccessApi } from './api/profileAccess/profileAccess';
import { brandsApi } from './api/Brands/brands.api';
import { promoApi } from './api/promo/promo.api';
import { adsApi } from './api/ads/ads.api';
import { abilitiesApi } from './api/Abilities/Abilities.api';
import { notificationsApi } from './api/Notifications/notification.api';
import { dealsApi } from 'redux/api/deals/dealsApi';
import { ordersApi } from 'redux/api/ordres/orders.api';
import { homeSectionApi } from 'redux/api/homeSections/homeSection.api';
import { categoriesLogisticsApi } from 'redux/api/CategoriesLogistics/categoriesLogistics.api';

const rootReducer = combineReducers({
  appReducer: appSlice,
  authReducer: authSlice,
  alertReducer: alertSlice,
  categoryReducer: categorySlice,

  [articlesApi.reducerPath]: articlesApi.reducer,
  [clientClassApi.reducerPath]: clientClassApi.reducer,
  [clientCategoryApi.reducerPath]: clientCategoryApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [warehouseApi.reducerPath]: warehouseApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [profileAccessApi.reducerPath]: profileAccessApi.reducer,
  [zonesApi.reducerPath]: zonesApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [sectorApi.reducerPath]: sectorApi.reducer,
  [governoratesApi.reducerPath]: governoratesApi.reducer,
  [adsApi.reducerPath]: adsApi.reducer,
  [abilitiesApi.reducerPath]: abilitiesApi.reducer,
  [brandsApi.reducerPath]: brandsApi.reducer,
  [promoApi.reducerPath]: promoApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [dealsApi.reducerPath]: dealsApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [homeSectionApi.reducerPath]: homeSectionApi.reducer,
  [categoriesLogisticsApi.reducerPath]: categoriesLogisticsApi.reducer,
});
export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      categoriesApi.middleware,
      articlesApi.middleware,
      clientClassApi.middleware,
      clientCategoryApi.middleware,
      clientApi.middleware,
      usersApi.middleware,
      profileAccessApi.middleware,
      warehouseApi.middleware,
      zonesApi.middleware,
      sectorApi.middleware,
      governoratesApi.middleware,
      abilitiesApi.middleware,
      adsApi.middleware,
      brandsApi.middleware,
      notificationsApi.middleware,
      promoApi.middleware,
      dealsApi.middleware,
      ordersApi.middleware,
      authApi.middleware,
      homeSectionApi.middleware,
      categoriesLogisticsApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
