import { Theme, alpha } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

export default function Tabs(theme: Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 30,
          minWidth: 120,
          marginRight: 20,
          textTransform: 'none',
          fontSize: '1rem',
          fontFamily: GlobalFonts.FONT_PRIMARY,
          fontWeight: 400,
          color: theme.palette.grey[500],
          alignItems: 'center',
          borderRadius: 40,
          padding: '5px 14px',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTab-root.Mui-selected': {
            fontSize: '1rem',
            fontFamily: GlobalFonts.FONT_PRIMARY_500,
            fontWeight: 500,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 40,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          minHeight: 0,
        },
        indicator: {
          display: 'none',
        },
      },
    },
  };
}
