import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface categoryState {
  openState: boolean | null;
  categoryId: string | number | null;
}

const initialState: categoryState = {
  openState: null,
  categoryId: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryState: (state, action: PayloadAction<categoryState>) => {
      const { openState, categoryId } = action.payload;
      state.openState = openState;
      state.categoryId = categoryId;
    },
    resetCategoryState: () => {
      return initialState;
    },
  },
});

export const { setCategoryState, resetCategoryState } = categorySlice.actions;
export default categorySlice.reducer;
export const GetStoredCategoryState = (state: RootState) => state.categoryReducer;
