import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import {
  injectActivationStatusFilter,
  injectDiffs,
  injectExpand,
  injectFilterAgentId,
  injectFilterClientId,
  injectFilterDate,
  injectFilterOriginOrderId,
  injectFilterUserId,
  injectFilterZoneId,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import {
  transformCartLogsResponse,
  transformOrderDatesResponse,
  transformOrderLogsResponse,
  transformOrdreDetailsResponse,
  transformOrdreResponse,
} from 'redux/api/ordres/orders.decoders';
import {
  IOrderDatesApi,
  IOrderDetails,
  IOrderDetailsApi,
  IOrderLogsApiResponse,
  IOrderResponse,
  IOrderResponseApi,
} from 'types/models/Ordre/Ordre.type';
import { ExpandEnum } from 'types/interfaces/sharedType.type';
import { ICartLogs, ICartLogsApiResponse } from 'types/models/Cart/Cart.schema';
import { BooleanToDom } from 'config/enums/common.enums';
export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['ordersApi'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    listOrdres: builder.query({
      query: (params: {
        paginator?: Paginator;
        activationStatus?: string | null;
        dateFilter?: number | null;
        clientId?: number | null;
        zoneId?: number | null;
        agentId?: number | null;
        originOrderId?: number | null;
        userId?: number | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.ORDRES.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectActivationStatusFilter(params.activationStatus),
          ...injectFilterDate(params.dateFilter),
          ...injectFilterClientId(params?.clientId),
          ...injectFilterZoneId(params?.zoneId),
          ...injectFilterAgentId(params?.agentId),
          ...injectFilterOriginOrderId(params?.originOrderId),
          ...injectFilterUserId(params?.userId),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: IOrderResponseApi): IOrderResponse => {
        return transformOrdreResponse(response);
      },

      providesTags: ['ordersApi'],
    }),
    getOrdersDetails: builder.query({
      query: (id: number) => ENDPOINTS.ORDRES.DETAILS(id),
      transformResponse: (response: IOrderDetailsApi): IOrderDetails => {
        return transformOrdreDetailsResponse(response);
      },
      providesTags: ['ordersApi'],
    }),
    getOrdersDates: builder.query({
      query: (id: number) => ENDPOINTS.ORDRES.DATES(id),
      transformResponse: (response: IOrderDatesApi[]) => {
        return transformOrderDatesResponse(response);
      },
      providesTags: ['ordersApi'],
    }),

    createOrdre: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ORDRES.CREATE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ordersApi'],
    }),
    synchronizeOrder: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ORDRES.SYNCHRONIZE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ordersApi'],
    }),
    invoiceOrder: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ORDRES.INVOICE,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ordersApi'],
    }),
    updateOrders: builder.mutation({
      query: ({ id_Order, bodyForUpdate }) => ({
        url: ENDPOINTS.ORDRES.UPDATE(id_Order),
        method: 'PATCH',
        body: bodyForUpdate,
      }),
      invalidatesTags: ['ordersApi'],
    }),

    getOrdersLogs: builder.query({
      query: (id: number) => ENDPOINTS.ORDRES.LOGS(id),
      transformResponse: (response: IOrderLogsApiResponse[]) => {
        return transformOrderLogsResponse(response);
      },
    }),
    getCartLogs: builder.query({
      query: (params: { idemKey: string; clientId: number; diffs?: BooleanToDom }) =>
        injectParams(ENDPOINTS.ORDRES.CART_LOGS(params.idemKey), {
          ...injectFilterClientId(params.clientId),
          ...injectDiffs(params.diffs),
        }),
      transformResponse: (response: ICartLogsApiResponse[]): ICartLogs[] =>
        transformCartLogsResponse(response),
    }),
  }),
});
export const {
  useCreateOrdreMutation,
  useListOrdresQuery,
  useGetOrdersDetailsQuery,
  useGetOrdersDatesQuery,
  useUpdateOrdersMutation,
  useSynchronizeOrderMutation,
  useGetOrdersLogsQuery,
  useGetCartLogsQuery,
  useInvoiceOrderMutation,
} = ordersApi;
