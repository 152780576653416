import {
  ICodePromoApiResponse,
  ICodePromoApiResult,
  ICodePromoResponse,
  ICodePromoResult,
  ISelectedCodePromo,
  ISelectedCodePromoApi,
  ITargetCodePromo,
  ITargetCodePromoApi,
} from 'types/models/CodePromo/codePromo.schema';
import { GlobalVariables } from 'config/constant';

export const transformPromoListResponse = (response: ICodePromoApiResponse): ICodePromoResponse => {
  return {
    lastPage: response?.lastPage,
    total: response?.total,
    nextPage: response?.nextPage,
    currentPage: response?.currentPage,
    previousPage: response?.previousPage,
    totalPerPage: response?.totalPerPage,
    data: response?.results?.map((item) => {
      return transformResponseItemCodePromo(item);
    }),
  };
};

const transformItemTargetSelector = (response: ISelectedCodePromoApi): ISelectedCodePromo => {
  return {
    all: response?.all,
    ids: response?.ids,
  };
};

export const transformResponseItemCodePromo = (response: ICodePromoApiResult): ICodePromoResult => {
  return {
    id: response?.id,
    code: response?.code,
    status: response?.status === GlobalVariables.STATUS.ACTIVE,
    endDate: response?.endDate,
    offer: response?.offer,
    deletedAt: response?.deletedAt,
    startDate: response?.startDate,
    usageCount: response?.usageCount,
    usageLimit: response?.usageLimit,
    targets: response?.targets?.map((target) => {
      return transformTargetCodePromoResponse(target);
    }),
  };
};
const transformTargetCodePromoResponse = (response: ITargetCodePromoApi): ITargetCodePromo => {
  return {
    id: response?.id,
    deletedAt: response?.deletedAt,
    selectedZones: transformItemTargetSelector(response?.selectedZones),
    selectedClientCategories: transformItemTargetSelector(response?.selectedClientCategories),
    selectedClientClasses: transformItemTargetSelector(response?.selectedClientClasses),
    selectedClients: transformItemTargetSelector(response?.selectedClients),
    selectedSectors: transformItemTargetSelector(response?.selectedSectors),
  };
};
