import { OptionType } from 'types/interfaces/Input.type';

export enum FilterStatusGroupedActionsEnum {
  All = '',
  Active = 'active',
  Inactive = 'inactive',
}

export const Filter_GROUPED_ACTIONS: OptionType<string>[] = [
  {
    label: 'category.all',
    value: FilterStatusGroupedActionsEnum.All,
  },
  {
    label: 'category.active',
    value: FilterStatusGroupedActionsEnum.Active,
  },
  {
    label: 'category.inactive',
    value: FilterStatusGroupedActionsEnum.Inactive,
  },
];
