import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import Paginator from 'types/interfaces/Paginator';
import { injectFilterStatus, paginatorToApiPagination } from 'utils/services/api.service';
import {
  IProfileAccess,
  IProfileAccessApi,
  IProfileAccessAPIResponse,
  IProfileAccessResponse,
} from 'types/models/ProfileAccess/ProfileAccess';
import {
  transformProfileAccessByIdResponse,
  transformProfileAccessResponse,
} from './profileAccess.transform';

export const profileAccessApi = createApi({
  reducerPath: 'profileAccessApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['ProfileAccess'],
  endpoints: (builder) => ({
    getProfileAccess: builder.query({
      query: (params: { paginator?: Paginator; status: boolean | null }) =>
        injectParams(ENDPOINTS.PROFILEACCESS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterStatus(params.status),
        }),
      transformResponse: (response: IProfileAccessAPIResponse): IProfileAccessResponse => {
        return transformProfileAccessResponse(response);
      },
      providesTags: ['ProfileAccess'],
    }),
    getProfileAccessById: builder.query({
      query: (id: number) => ENDPOINTS.PROFILEACCESS.GETBYID(id),
      transformResponse: (response: IProfileAccessApi): IProfileAccess => {
        return transformProfileAccessByIdResponse(response);
      },
      providesTags: ['ProfileAccess'],
    }),
    createProfileAccess: builder.mutation({
      query: ({ profileAccessData, status }) => ({
        url: ENDPOINTS.PROFILEACCESS.GET,
        method: 'POST',
        body: { ...profileAccessData, status },
      }),
      invalidatesTags: ['ProfileAccess'],
    }),
    updateProfileAccess: builder.mutation({
      query: ({ id_profileAccess, profileAccessData, status }) => ({
        url: ENDPOINTS.PROFILEACCESS.GETBYID(id_profileAccess),
        method: 'PATCH',
        body: { ...profileAccessData, status: status },
      }),
      invalidatesTags: ['ProfileAccess'],
    }),
    deleteProfileAccess: builder.mutation({
      query: (id) => ({
        url: ENDPOINTS.PROFILEACCESS.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['ProfileAccess'],
    }),
  }),
});

export const {
  useGetProfileAccessQuery,
  useUpdateProfileAccessMutation,
  useDeleteProfileAccessMutation,
  useCreateProfileAccessMutation,
  useGetProfileAccessByIdQuery,
} = profileAccessApi;
