import { alpha, Theme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Backdrop(theme: Theme) {
  const varLow = alpha(theme.palette.grey[800], 0.3);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: varLow,
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
          },
        },
      },
    },
  };
}
