import { isEqual, isNaN, isNil, isUndefined, sortBy } from 'lodash';
import { IPackingUnitResponse } from 'types/models/Article/article';
import {
  AddCodePromoFormEnum,
  ALL_OPTION,
} from 'features/CodePromo/AddCodePromo/AddCodePromoForm/AddCodePromoForm.config';
import { IGenericTargetData } from 'features/CodePromo/AddCodePromo/CodeTab/CodeTab.type';
import { OptionType } from 'types/interfaces/Input.type';
export const arrayIsEmpty = <T = unknown>(array: T[] | null | undefined) => {
  if (!array) return true;
  return array?.length?.toString() === '0';
};
export const generateArray = (lengthArray: number) => {
  const array: number[] = [0];
  for (let i = 1; i < lengthArray; i++) {
    array.push(i);
  }
  return array;
};
export function verifyAttributesNotNull<T>(array: T[] | undefined): boolean {
  if (!array || array?.length == 0) return false;
  return array.every((obj) =>
    Object.values(obj).every((value) => value !== null && !isNaN(value) && !isUndefined(value)),
  );
}
export function isAllArrayNil<T>(array: T[] | undefined): boolean {
  if (!array || array?.length === 0) return false;
  return array.every((obj) => Object.values(obj).every((value) => isNil(value)));
}

export const generatePackingUnitForSelector = (
  packingUnitData: IPackingUnitResponse[] | undefined,
) => {
  if (!packingUnitData) return [];
  return packingUnitData?.map((unit) => ({
    label: unit.label,
    value: unit.id,
  })) as OptionType<number>[];
};
export const dataFilter = <T extends { id: number }>(
  enumType:
    | AddCodePromoFormEnum.ClientClass
    | AddCodePromoFormEnum.CategoryClient
    | AddCodePromoFormEnum.Sector,
  targetDataArray: T[],
  genericTargetData: IGenericTargetData[],
): number[] => {
  const result: number[] = [];
  genericTargetData?.flatMap((targetData) => {
    return targetData[enumType]?.map((targetDataItem) =>
      targetDataItem?.value == ALL_OPTION.value
        ? result.push(...targetDataArray.map((item) => item.id))
        : result.push(targetDataItem?.value),
    );
  });
  return Array.from(new Set(result));
};

export function generateLabelsAndValues<
  T extends {
    id: number;
    nameFr?: string;
    firstName?: string;
    lastName?: string;
  },
>(
  selectedIds: number[] | undefined,
  data: T[] | undefined,
  labelKey: keyof T,
): OptionType<number>[] {
  if (!selectedIds || !data) {
    return [];
  }

  const labelsAndValues: OptionType<number>[] = [];

  selectedIds.forEach((selectedId) => {
    const matchingItem = data.find((item) => item.id === selectedId);
    if (matchingItem) {
      labelsAndValues.push({
        label: matchingItem[labelKey] as unknown as string,
        value: matchingItem.id,
      });
    }
  });

  return labelsAndValues;
}
export const compareAndTransformArrayValue = <T, D>(
  valueToReturn: T[] | undefined | null,
  defaultValue: D,
): T[] | D => {
  if (isNil(valueToReturn) || isNaN(valueToReturn.length) || valueToReturn.length === 0)
    return defaultValue;
  return valueToReturn.filter(Boolean);
};

export const isSameArray = <T>(array1: T[] | undefined, array2: T[] | undefined): boolean => {
  if (!array1 || !array2) return false;
  const sortedArray1 = sortBy(array1);
  const sortedArray2 = sortBy(array2);

  return array1.length === array2.length && isEqual(sortedArray1, sortedArray2);
};
