import { LoginResponse, LoginResponseData } from 'types/models/Login/login.schema';
import { toCamelCase } from 'utils/helpers/object.helpers';

export const transformLoginResponse = (data: LoginResponseData): LoginResponse => {
  return {
    ...data,
    user: toCamelCase(data.user),
    token: data.accessToken,
    refreshToken: data.refreshToken,
  };
};
