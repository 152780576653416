import {
  StyledMenuList,
  StyledPopper,
} from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.style';
import { Grow, Paper } from '@mui/material';
import { CustomAutoCompleteOptions } from 'components/CustomInputs/CustomAutoComplete/Components/CustomAutoCompleteOptions';
import { useTheme } from '@mui/material/styles';
import { ICusAutoCompletePopperProps } from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.type';

export default function CustomAutocompletePopper<T>({
  isOptionExisting,
  localOptions,
  options,
  onClickMenuItem,
  anchorRef,
  searchValue,
  isLoading,
  useTooltip,
  onScrollEnd,
  open,
  autoWidthPopper,
}: ICusAutoCompletePopperProps<T>) {
  const theme = useTheme();
  return (
    <StyledPopper
      theme={theme}
      open={open}
      width={!autoWidthPopper ? anchorRef?.current?.offsetWidth : undefined}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom"
      transition

      // Check the constants file if you want to change the behavior of the popper
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper>
            <StyledMenuList
              id="composition-menu"
              aria-labelledby="composition-button"
              onScrollCapture={onScrollEnd}
            >
              <CustomAutoCompleteOptions<T>
                onClickMenuItem={onClickMenuItem}
                isOptionExisting={isOptionExisting}
                useTooltip={useTooltip ?? false}
                anchorRef={anchorRef}
                options={options}
                isLoading={isLoading}
                localOptions={localOptions}
                searchValue={searchValue}
                autoWidthPopper={autoWidthPopper}
              />
            </StyledMenuList>
          </Paper>
        </Grow>
      )}
    </StyledPopper>
  );
}
