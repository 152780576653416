import OfflineDialog from 'components/Dialogs/OfflineDialog/OfflineDialog';
import { Router } from 'routes';
import './assets/sass/common.scss';
import AlertDialog from 'components/Dialogs/AlertDialog/AlertDialog';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useAppSelector } from 'redux/hooks';
import generateTheme from 'theme/theme';
import { useMemo } from 'react';
import GlobalStyles from 'theme/globalStyles';
import { CustomSnack } from 'components/CustomSnack/CustomSnack';
import useInitApp from 'hooks/useInitApp';

function App() {
  const appConfig = useAppSelector((state) => state.appReducer);
  const theme = useMemo(() => generateTheme(appConfig.mode), [appConfig.mode]);
  useInitApp();
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />

        <Router />

        <CustomSnack />

        <OfflineDialog />
        <AlertDialog />
      </ThemeProvider>
    </div>
  );
}

export default App;
