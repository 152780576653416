import { DarkColorsConfig } from 'config/constant/colors.config';
import { AppPaletteMode } from 'config/enums/app.enum';

const darkPalette = {
  mode: AppPaletteMode.Dark,
  primary: DarkColorsConfig.primary,
  secondary: DarkColorsConfig.secondary,
  info: DarkColorsConfig.info,
  success: DarkColorsConfig.success,
  warning: DarkColorsConfig.warning,
  error: DarkColorsConfig.error,
  grey: DarkColorsConfig.grey,
  actions: DarkColorsConfig.actions,
  background: DarkColorsConfig.background,
  text: DarkColorsConfig.text,
};

export default darkPalette;
