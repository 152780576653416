import { Box, Menu, MenuItem, Stack, styled } from '@mui/material';
import { ReactComponent as DropDownIcon } from 'assets/icons/arrow_down.svg';
import { GlobalFonts } from 'config/constant/fonts.config';
import { GlobalVariables } from 'config/constant';

export const RootStyle = styled(Box)({
  width: '100%',
});

export const SelectStyle = styled(Stack)(
  ({
      open,
      error,
      autowidth,
      disabled,
    }: {
      open: boolean;
      error?: boolean;
      autowidth?: string;
      disabled?: boolean;
    }) =>
    ({ theme }) => ({
      cursor: disabled ? 'unset' : 'pointer',
      padding: 15,

      paddingLeft: 23,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderColor: error ? theme.palette.error.main : theme.palette.grey[300],
      borderBottom: error
        ? `1px solid ${theme.palette.error.main}`
        : open
        ? `1px solid ${theme.palette.background.paper}`
        : `1px solid ${theme.palette.grey[300]}`,
      borderRadius: open ? '8px 8px 0px 0px' : '8px',
      boxShadow: open ? `0px 13px 45px ${theme.palette.grey[300]}` : 'none',
      width: autowidth === GlobalVariables.TRUE ? 'auto' : 'max-content',
    }),
);

export const SelectIconStyle = styled(DropDownIcon)(
  ({ open }: { open: boolean }) =>
    ({ theme }) => ({
      transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
      fill: theme.palette.primary.main,
      marginLeft: 10,
    }),
);

export const MenuStyle = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderTop: 'none',
    borderRadius: '0px 0px 8px 8px',
    boxShadow: `0px 13px 45px 0px ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: '0px !important',
  },
}));

export const MenuItemStyle = styled(MenuItem)(({ theme, selected }) => ({
  padding: 15,
  paddingLeft: 23,
  width: '100%',
  fontSize: '1rem',
  lineHeight: '1.438rem',
  fontFamily: GlobalFonts.FONT_PRIMARY,
  color: selected ? theme.palette.text.primary : theme.palette.grey[500],
}));

export const DividerStyle = styled(Box)(({ theme }) => ({
  margin: '0px !important',
  width: '90%',
  height: 0,
  border: `1px solid ${theme.palette.grey[200]}`,
}));
