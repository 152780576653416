export const profileAccess = {
  add: 'Ajouter un profile',
  edit: 'Modifier un profile',
  no_data_yet: "Vous n'avez pas encore des profiles",
  search: 'Chercher le Profile',
  name: 'Désignation',
  status: 'Status',
  delete_warning_message: 'Êtes-vous sûr de vouloir supprimer ce profile ?',
  update: 'Modifier le profile',
};
