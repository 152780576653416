import {
  IProfileAccess,
  IProfileAccessApi,
  IProfileAccessAPIResponse,
  IProfileAccessResponse,
} from 'types/models/ProfileAccess/ProfileAccess';
import { GlobalVariables } from 'config/constant';
import { IAbilities, IAbilitiesApi } from 'types/models/Abilities/Abilities';
export const transformProfileAccessResponse = (
  responseData: IProfileAccessAPIResponse,
): IProfileAccessResponse => {
  return {
    data: responseData.results.map((res) => {
      return {
        id: res.id,
        abilities: res.abilities,
        descriptionAr: res.descriptionAr,
        descriptionFr: res.descriptionFr,
        nameAr: res.nameAr,
        nameFr: res.nameFr,
        status: res.status === GlobalVariables.STATUS.ACTIVE,
      };
    }),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
export const transformAbilitiesResponse = (responseData: IAbilitiesApi): IAbilities => ({
  id: responseData.id,
  action: responseData.action,
  labelAr: responseData.labelAr,
  labelFr: responseData.labelFr,
  subjectName: responseData.subjectName,
});

export const transformProfileAccessByIdResponse = (data: IProfileAccessApi): IProfileAccess => {
  return {
    id: data?.id,
    nameAr: data?.nameAr,
    nameFr: data?.nameFr,
    status: data?.status === GlobalVariables.STATUS.ACTIVE,
    deletedAt: data?.deletedAt,
    descriptionAr: data?.descriptionAr,
    descriptionFr: data?.descriptionFr,
    abilities: data?.abilities?.map(transformAbilitiesResponse),
  };
};
