export default function DateTimePicker() {
  return {
    MuiDateTimePicker: {
      styleOverrides: {
        root: {
          padding: 30,
          '& .MuiPickersCalendar-transitionContainer': {
            // Hide the lines between the numbers in the calendar
            borderCollapse: 'collapse',
            '& .MuiPickersDay-day:not(:last-child)': {
              borderRight: 'none',
            },
            '& .MuiPickersDay-day:not(:nth-last-child(-n+7))': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
  };
}
