import { BORouterPaths } from 'config/constant';
import useLoggedIn from 'hooks/useLoggedIn';
import { ReactNode, useMemo } from 'react';
import { matchPath, Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { GetRouteConfigStore, setSnackBarConfig } from 'redux/slices/app/appSlice';
import { AppActionEnum } from 'types/interfaces/sharedType.type';
import { ESnackBarSeverity } from 'types/interfaces/SnachBar';
import { BORouteIdEnum } from 'config/enums';
import { isUndefined } from 'lodash';
import NavItem from 'types/interfaces/NavItem';
import { arrayIsEmpty } from 'utils/helpers/array.helpers';

interface Props {
  guest: boolean;
  children: ReactNode;
}
/**
 *if guest is true and user is logged in, redirect to login page
 *if guest is false and user is not logged in, redirect to login page
 * @param guest boolean true for guest mode path like login and register and false for other path
 * @param children
 */
function AuthGuard({ guest, children }: Props) {
  const isLoggedIn = useLoggedIn();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const routeConfig = useAppSelector(GetRouteConfigStore);

  const authGuardValues = useMemo(() => {
    const shouldRedirectToLogin = !guest && !isLoggedIn;
    const shouldRedirectToFirstRoute =
      isLoggedIn && !arrayIsEmpty(routeConfig) && (!isPathMatch(routeConfig, pathname) || guest);
    const shouldShowSnackBar =
      !guest && !isUndefined(routeConfig?.[0]?.path) && pathname !== BORouterPaths.DashboardPath;

    return { shouldRedirectToLogin, shouldRedirectToFirstRoute, shouldShowSnackBar };
  }, [guest, isLoggedIn, routeConfig, pathname]);

  if (authGuardValues.shouldRedirectToLogin) {
    return <Navigate to={BORouterPaths.loginPath} replace />;
  }

  if (authGuardValues.shouldRedirectToFirstRoute) {
    if (authGuardValues.shouldShowSnackBar)
      dispatch(
        setSnackBarConfig({
          open: true,
          severity: ESnackBarSeverity.INFO,
          title: 'snack.info',
          message: 'snack.autorisation_path_error',
        }),
      );
    return <Navigate to={routeConfig?.[0]?.path ?? BORouteIdEnum.Root} replace />;
  }

  return <>{children}</>;
}

function isPathMatch(routeConfig: NavItem[], pathname: string) {
  return routeConfig.some((route) =>
    matchPath({ path: route.path, end: route.action !== AppActionEnum.Manage }, pathname),
  );
}
export default AuthGuard;
