export const user = {
  name: 'Nom',
  prename: 'Prénom',
  email: 'Email',
  phone: 'Téléphone',
  pinCode: 'Code Pin',
  password: 'Mot de passe',
  phone_number: 'Numéro de téléphone',
  phone_number_length: 'Le numéro de téléphone doit contenir 8 chiffres',
  profile: 'Type',
  your_profile: 'Your profile',
  status: 'statuts',
  search_for_user: 'Chercher le membre',
  no_users_yet: "Vous n'avez pas encore une équipe",
  hello_user: 'Hello, {{name}}!',
  delete_warning_message: 'Êtes-vous sûr de vouloir supprimer ce membre',
  name_required: 'Le nom est requis',
  name_too_short: 'Le nom est trop court',
  name_too_long: 'Le nom est trop long',

  prename_required: 'Le prénom est requis',
  prename_too_short: 'Le prénom est trop court',
  prename_too_long: 'Le prénom est trop long',

  email_required: "L'email est requis",
  email_invalid: "L'email est invalide",
  email_too_short: "L'email est trop court",
  email_too_long: "L'email est trop long",

  phone_required: 'Le téléphone est requis',
  phone_too_long: 'Le téléphone est trop long',
  phone_invalid: 'Le téléphone est invalide',
  profile_image: 'Photo de profil',
};
