import {
  PackingTypeEnum,
  PRODUCT_PACKAGE_ROWS_INPUT_ENUM,
  ProductPackageTypeEnum,
  SelectorAddProductsPackingOptionsValue,
} from 'features/Products/AddProducts/AddProduct.constants';
import { FieldValues } from 'react-hook-form';
import {
  InputStockFieldNameEnum,
  SelectorStockFieldNameEnum,
} from 'features/Products/AddProducts/AddProducts.types';
import { GlobalVariables } from 'config/constant';
import { compareAndTransformValue } from 'utils/helpers/string.helpers';
import { isNil } from 'lodash';
import { IArticleByIdResponse } from 'types/models/Article/article';

export function convertValueToObject(
  key: string,
  value: string,
  max: boolean,
  selectedValues: { [p: string]: string },
  convertedResult: { value: string; type: string; field: string }[],
) {
  const startKey = max
    ? `${SelectorStockFieldNameEnum.MAX}_`
    : `${SelectorStockFieldNameEnum.MOQ}_`;
  const id = max ? key.slice(9) : key.slice(4);
  if (selectedValues[`${startKey}${id}`] !== undefined) {
    switch (selectedValues[`${startKey}${id}`]?.toString()) {
      case PackingTypeEnum.Unit:
        return {
          fieldName: `${startKey}${id}`,
          value: Number(value),
        };
      case PackingTypeEnum.Rack:
        return {
          fieldName: `${startKey}${id}`,
          value:
            Number(
              convertedResult?.find((a) => a.field === ProductPackageTypeEnum.RackPackaging)
                ?.value ?? '1',
            ) * Number(value),
        };
      case PackingTypeEnum.Pack:
        return {
          fieldName: `${startKey}${id}`,
          value:
            Number(
              convertedResult?.find((a) => a.field === ProductPackageTypeEnum.PackPackaging)
                ?.value ?? '1',
            ) * Number(value),
        };
      case PackingTypeEnum.Pallet:
        return {
          fieldName: `${startKey}${id}`,
          value:
            Number(
              convertedResult?.find((a) => a.field === ProductPackageTypeEnum.PalletPackaging)
                ?.value ?? '1',
            ) * Number(value),
        };
      default:
        break;
    }
  }
}
export function getPackingTypeEnumKey(packingType: string): PRODUCT_PACKAGE_ROWS_INPUT_ENUM {
  switch (packingType) {
    case PackingTypeEnum.Unit:
      return PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbUnit;
    case PackingTypeEnum.Rack:
      return PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbRack;
    case PackingTypeEnum.Pack:
      return PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbPack;
    case PackingTypeEnum.Pallet:
      return PRODUCT_PACKAGE_ROWS_INPUT_ENUM.PcbPallet;
    default:
      throw new Error('Invalid packingType');
  }
}
export function getPackageTypeFromPackingType(packingType: string): ProductPackageTypeEnum | null {
  switch (packingType) {
    case PackingTypeEnum.Unit:
      return ProductPackageTypeEnum.UnitPackaging;
    case PackingTypeEnum.Rack:
      return ProductPackageTypeEnum.RackPackaging;
    case PackingTypeEnum.Pack:
      return ProductPackageTypeEnum.PackPackaging;
    case PackingTypeEnum.Pallet:
      return ProductPackageTypeEnum.PalletPackaging;
    default:
      return null;
  }
}

export function getFromPackageLabelFromPackingType(
  packingType: string,
): ProductPackageTypeEnum | null {
  switch (packingType) {
    case PackingTypeEnum.Unit:
      return ProductPackageTypeEnum.FromUnitPackaging;
    case PackingTypeEnum.Rack:
      return ProductPackageTypeEnum.FromRackPackaging;
    case PackingTypeEnum.Pack:
      return ProductPackageTypeEnum.FromPackPackaging;
    case PackingTypeEnum.Pallet:
      return ProductPackageTypeEnum.FromPalletPackaging;
    default:
      return null;
  }
}

export function getValueToUpdateToProductPrice(
  type: string | undefined,
  id: string,
  amount: number | undefined,
) {
  const key = getFieldNameFromPackingUnit(type, id);
  return amount !== undefined ? { key: key, value: amount } : {};
}
export function getFieldNameFromPackingUnit(type: string | undefined | null, id: string) {
  switch (type) {
    case PackingTypeEnum.Unit:
      return `unitPrice_${id}`;
    case PackingTypeEnum.Rack:
      return `rackPrice_${id}`;
    case PackingTypeEnum.Pack:
      return `packPrice_${id}`;
    case PackingTypeEnum.Pallet:
      return `palletPrice_${id}`;
    default:
      return '';
  }
}
export function extractCategoryIdFromFieldName(str: string) {
  const regex = /category-(\d+)-packing/;
  const match = str.match(regex);
  return match ? parseInt(match[1]) : null;
}
export function getTypeFromKey(key: string): PackingTypeEnum | undefined {
  if (key.startsWith('unitPrice')) {
    return PackingTypeEnum.Unit;
  } else if (key.startsWith('rackPrice')) {
    return PackingTypeEnum.Rack;
  } else if (key.startsWith('packPrice')) {
    return PackingTypeEnum.Pack;
  } else if (key.startsWith('palletPrice')) {
    return PackingTypeEnum.Pallet;
  }
  return undefined;
}
export const getKeyPriceFromType = (type: PackingTypeEnum) => {
  switch (type) {
    case PackingTypeEnum.Unit:
      return 'unitPrice';
    case PackingTypeEnum.Rack:
      return 'rackPrice';
    case PackingTypeEnum.Pack:
      return 'packPrice';
    case PackingTypeEnum.Pallet:
      return 'palletPrice';
    default:
      return '';
  }
};
export function filterAndExtractValues(
  inputObject: FieldValues,
  idCategory: number,
  checkedLabels: string[] | undefined,
): Array<{
  amount: number;
  type: PackingTypeEnum;
}> {
  const filteredKeys = Object.keys(inputObject).filter(
    (key) => key.endsWith('checkbox') && key.includes(`category-${idCategory}-packing`),
  );

  const extractedValues = filteredKeys.map((key) => {
    // Ensure that the checkbox of that input has not been disabled
    const isKeyExistInCheckedLabels = checkedLabels?.some((label) => key.includes(label));
    const amount = Number(inputObject[key]);
    const type = getTypeFromKey(key) as PackingTypeEnum;
    if (isNaN(amount) || isNil(type) || !isKeyExistInCheckedLabels) {
      return null;
    }

    return { amount, type };
  });

  return extractedValues.filter((value) => value !== null) as Array<{
    amount: number;
    type: PackingTypeEnum;
  }>;
}

export function extractUniqueIdsFromValues(values: FieldValues): number[] {
  const uniqueIds = new Set<number>();

  Object.keys(values).forEach((key) => {
    const categoryId = extractCategoryIdFromFieldName(key);
    if (categoryId !== null) {
      uniqueIds.add(categoryId);
    }
  });

  return Array.from(uniqueIds);
}

export function getArticlePriceDataForApi(
  values: FieldValues,
  selectedValues: { [p: string]: string },
  checkedLabels: string[] | undefined,
) {
  const categoryIds = extractUniqueIdsFromValues(values);

  return categoryIds?.map((categoryId) => {
    return {
      minOrderPackingType: compareAndTransformValue(
        selectedValues?.[`${SelectorStockFieldNameEnum.MOQ}_${categoryId}`],
        null,
      ),
      minOrderQty: compareAndTransformValue(
        Number(values?.[`${InputStockFieldNameEnum.Moq}_${categoryId}`]),
        null,
        0,
      ),
      maxOrderPackingType: compareAndTransformValue(
        selectedValues?.[`${SelectorStockFieldNameEnum.MAX}_${categoryId}`],
        null,
      ),
      maxOrderQty: compareAndTransformValue(
        Number(values?.[`${InputStockFieldNameEnum.MaxStock}_${categoryId}`]),
        null,
        0,
      ),
      clientCategoryId: categoryId,
      packingPrices: filterAndExtractValues(values, categoryId, checkedLabels),
    };
  });
}
export function extractValuePackingFromFieldName(str: string) {
  const regex = /-packing-(.+?)-checkbox/;
  const match = str.match(regex);
  return match ? match[1] : null;
}
export const checkMaxIsValid = (
  keyMax: string,
  max: string,
  keyMin: string,
  min: string,
  convertValue?: (
    key: string,
    value: string,
    max: boolean,
  ) => { fieldName: string; value: number } | undefined,
) => {
  const convertedMaxValue = convertValue?.(keyMax, max, true);
  const convertedMinValue = convertValue?.(keyMin, min, false);
  if (convertedMaxValue?.value && convertedMinValue?.value)
    return convertedMaxValue.value > convertedMinValue.value;
  return true;
};

export function convert(
  array: { value: string; type: string; field: string }[],
  watchedPcbPackInput: number,
  watchedPcbRackInput: number,
  arrayToConvert: { field: string; type: string }[],
): { value: string; type: string; field: string }[] {
  const result: { value: string; type: string; field: string }[] = [];

  array.map((item) => {
    if (item.type === GlobalVariables.EmptyString || item.value === GlobalVariables.EmptyString)
      return;

    switch (item.type) {
      case SelectorAddProductsPackingOptionsValue.Unit.toString():
      case item?.field?.startsWith('unit') ? '2' : '':
        result.push({
          value: item.value,
          type: item.type,
          field: item.field,
        });
        break;

      case SelectorAddProductsPackingOptionsValue.Pack.toString():
        convert(
          [
            {
              value: (Number(item.value) * Number(watchedPcbPackInput)).toString(),
              type: arrayToConvert.filter(
                (o) => o.field === ProductPackageTypeEnum.PackPackaging,
              )[0].type,
              field: item.field,
            },
          ],
          watchedPcbPackInput,
          watchedPcbRackInput,
          arrayToConvert,
        );
        break;
      case SelectorAddProductsPackingOptionsValue.Rack.toString():
        convert(
          [
            {
              value: (Number(item.value) * Number(watchedPcbRackInput)).toString(),
              type: arrayToConvert.filter(
                (o) => o.field === ProductPackageTypeEnum.RackPackaging,
              )[0].type,
              field: item.field,
            },
          ],
          watchedPcbPackInput,
          watchedPcbRackInput,
          arrayToConvert,
        );
        break;
      default:
        break;
    }
  });

  return result;
}
export const getKeyValuePriceFromSage = (
  articleFromSage: IArticleByIdResponse,
  checkboxId: string,
) => {
  const packingType = extractValuePackingFromFieldName(checkboxId);
  const categoryId = extractCategoryIdFromFieldName(checkboxId);
  if (packingType && categoryId) {
    const packingPrice = articleFromSage.prices
      .find((item) => item.clientCategoryId === categoryId)
      ?.packingPrices.find((item) => item.type === packingType);
    if (packingPrice) {
      const key = `${getKeyPriceFromType(packingType as PackingTypeEnum)}_${checkboxId}`;
      const value = packingPrice.amount;
      return { key, value };
    }
    return null;
  }
  return null;
};
