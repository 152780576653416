import { Box } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { AppPaletteMode } from 'config/enums/app.enum';
import { DRAWER_WIDTH } from '../Navbar/Navbar.constants';
import { BooleanToDom } from 'config/enums/common.enums';

export const RootStyle = styled(Box)(
  ({ theme }) =>
    ({ isopendrawer }: { isopendrawer?: BooleanToDom }) => ({
      flexShrink: 0,
      width: isopendrawer === BooleanToDom.TRUE ? DRAWER_WIDTH : 'auto',
    }),
);

export const getDrawerPaperStyle = (theme: Theme) => ({
  width: DRAWER_WIDTH,
  boxShadow: `0px 0px 89px ${
    theme.palette.mode === AppPaletteMode.Light ? theme.palette.grey[300] : theme.palette.grey[700]
  }`,
  background: `transparent linear-gradient(180deg, ${
    theme.palette.mode === AppPaletteMode.Light ? theme.palette.grey[50] : theme.palette.grey[900]
  } 0%,  
                ${
                  theme.palette.mode === AppPaletteMode.Light
                    ? theme.palette.grey[100]
                    : theme.palette.grey[800]
                } 100%)`,
  borderRadius: '0px 10px 10px 0px',
  borderRight: 'none',
});
