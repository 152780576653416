import { RadioButtonsGroup } from 'components/CustomButtons/RadioButtonsGroup/RadioButtonsGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { InputTypes } from 'types/interfaces/Input.type';
import { isObjectExist } from 'utils/helpers/object.helpers';
import CustomTextAreaField from '../CustomTextAreaField/CustomTextAreaField';
import CustomTextField from '../CustomTextField/CustomTextField';
import { GenericInputProps } from './GenericInput.type';
import CustomPhoneInput from '../CustomPhoneInput/CustomPhoneInput';
import { MultiRadioButtonsGroup } from 'components/CustomButtons/MultiRadioButtonsGroup/MultiRadioButtonsGroup';
import CustomDatePicker from 'components/CustomInputs/CustomDatePicker/CustomDatePicker';
import CustomDateTimePicker from 'components/Dates/CustomDateTimePicker/CustomDateTimePicker';
import CustomCurrencyTextField from 'components/CustomCurrencyTextField/CustomCurrencyTextField';
import CustomAutoComplete from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete';
import CustomAsyncSelector from 'components/CustomInputs/CustomAsyncSelector/CustomAsyncSelector';

function GenericInput({ inputObject, disabledForm, required, textAlign }: GenericInputProps) {
  const {
    inputType,
    fieldName,
    label,
    defaultValue,
    valueOptions,
    config = {},
    disabled,
    placeholder,
    size,
    options,
    isPassword,
    copy,
    radioSpacing,
    isDirectionRadioRow,
    selector,
    autoComplete,
    clearDateButton,
    useGridLayoutRadio,
    customOnChange,
    useTooltip,
  } = inputObject;

  const rules = {
    ...config,
    required: isObjectExist(required) ? required && config.required : config.required,
  };

  const isDisabled = disabledForm || disabled;

  const { control } = useFormContext();
  switch (inputType) {
    case InputTypes.PRICE:
      return (
        <Controller
          render={({ field, fieldState }) => {
            return (
              <CustomCurrencyTextField
                fieldName={fieldName}
                label={label}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  customOnChange?.(value);
                }}
                error={fieldState.error}
                required={Boolean(rules.required)}
                disabled={isDisabled}
                placeholder={placeholder}
                size={size}
                useTooltip={useTooltip}
              />
            );
          }}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue ?? 0}
        />
      );
    case InputTypes.AUTOCOMPLETE:
      return (
        <Controller
          render={({ field, fieldState }) => {
            return (
              <CustomAutoComplete
                placeholder={placeholder}
                options={autoComplete?.options || []}
                onChange={(value: unknown) => {
                  field.onChange(value);
                  customOnChange?.(value);
                }}
                error={fieldState.error?.message}
                disabled={isDisabled}
                multiple={autoComplete?.multiple}
                value={field.value}
                label={label}
                onEndScroll={autoComplete?.onEndScroll}
                isLoading={autoComplete?.isLoading}
                onAsyncSearch={autoComplete?.onChangeTextField}
                fieldName={fieldName}
                size={size}
                useTooltip={useTooltip}
                messageHelper={autoComplete?.messageHelper}
                autoWidthPopper={autoComplete?.autoWidthPopper}
                isTextArea={autoComplete?.isTextArea}
              />
            );
          }}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.SELECT:
      return (
        <Controller
          render={({ field, fieldState }) => {
            return (
              <CustomAsyncSelector
                fieldName={fieldName}
                label={label}
                options={selector?.options || []}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  customOnChange?.(value);
                }}
                error={fieldState.error?.message}
                disabled={isDisabled}
                placeholder={placeholder}
                isLoading={selector?.isLoading}
                onScrollEnd={selector?.onScrollEnd}
                autoWidth={selector?.autoWidth}
                valueFromReactHookForm={true}
              />
            );
          }}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.MULTI_RADIO:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <MultiRadioButtonsGroup
              error={fieldState.error}
              value={field.value}
              label={label}
              options={options || []}
              onChange={(newValues) => {
                field.onChange(newValues);
                customOnChange?.(newValues);
              }}
              placeholder={placeholder}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.RADIO:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <RadioButtonsGroup
              error={fieldState.error}
              value={field.value}
              label={label}
              options={options || []}
              onChange={(e, v) => {
                field.onChange(e, v);
                customOnChange?.(v);
              }}
              spacing={radioSpacing}
              radio_direction={isDirectionRadioRow ? 'row' : 'column'}
              disabled={isDisabled}
              useGridLayoutRadio={useGridLayoutRadio}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.TEXTAREA:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <CustomTextAreaField
              label={label}
              inputType={inputType}
              value={field.value || ''}
              onChange={field.onChange}
              error={fieldState.error}
              required={Boolean(rules.required)}
              valueOptions={valueOptions}
              disabled={isDisabled}
              placeholder={placeholder}
              size={size}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.PHONE_INPUT:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <CustomPhoneInput
              label={label}
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error}
              required={Boolean(rules.required)}
              name={fieldName}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.NUMBER: {
      return (
        <Controller
          render={({ field, fieldState }) => (
            <CustomTextField
              name={fieldName}
              label={label}
              inputType={inputType}
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e);
                customOnChange?.((e as React.ChangeEvent<HTMLInputElement>).target.value);
              }}
              error={fieldState.error}
              required={Boolean(rules.required)}
              valueOptions={valueOptions}
              disabled={isDisabled}
              placeholder={placeholder}
              size={size}
              textAlign={textAlign}
              useTooltip={useTooltip}
              isPassword={isPassword}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    }
    case InputTypes.DATE:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <CustomDatePicker
              name={fieldName}
              label={label}
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error}
              required={Boolean(rules.required)}
              valueOptions={valueOptions}
              disabled={isDisabled}
              placeholder={placeholder}
              size={size}
              textAlign={textAlign}
              clearDateButton={clearDateButton}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
    case InputTypes.DATETIME:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <CustomDateTimePicker
              name={fieldName}
              label={label}
              value={field.value}
              error={fieldState.error}
              onChange={field.onChange}
              required={Boolean(rules.required)}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );

    case InputTypes.TEXT:
    case InputTypes.PASSWORD:
    default:
      return (
        <Controller
          render={({ field, fieldState }) => (
            <CustomTextField
              name={fieldName}
              label={label}
              inputType={inputType}
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e);
                customOnChange?.((e as React.ChangeEvent<HTMLInputElement>).target.value);
              }}
              error={fieldState.error}
              required={Boolean(rules.required)}
              valueOptions={valueOptions}
              disabled={isDisabled}
              placeholder={placeholder}
              size={size}
              textAlign={textAlign}
              isPassword={isPassword}
              copy={copy}
              useTooltip={useTooltip}
            />
          )}
          name={fieldName}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
      );
  }
}

export default GenericInput;
