export const warehouse = {
  intern: 'Dépôt interne',
  extern: 'Dépôt externe',
  taxNumber_validation:
    'Le matricule fiscale doit avoir 8 chiffres suivie au moins 3 caractères alphabétiques, se termine par 8 chiffres supplémentaires.',
  search: 'Chercher le dépot',
  nameFr: 'Désignation',
  status: 'Status',
  no_warehouses_yet: "Vous n'avez pas encore de dépôts",
  delete_warning_message: 'Êtes-vous sûr de vouloir supprimer ce dépôt ?',
  search_for_warehouse: 'Chercher le dépôt',
  depot: 'Dépôt',
  stock_real: 'Stock réel du produit',
  stock_dispo: 'Stock disponible du produit',
};
