import React from 'react';
import { CircularProgress, useTheme } from '@mui/material';
import { LoadingButtonProps } from 'components/LoadingButton/LoadingButton.type';
import { StyledLoadingButton } from './LoadingButton.style';

export function LoadingButton({
  variant = 'outlined',
  isLoading,
  disabled,
  onClick,
  children,
  sx,
}: LoadingButtonProps) {
  const theme = useTheme();
  return (
    <StyledLoadingButton
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      type="submit"
      sx={sx}
    >
      {isLoading ? (
        <CircularProgress sx={{ color: theme.palette.grey[50], padding: '2px' }} size={24} />
      ) : (
        children
      )}
    </StyledLoadingButton>
  );
}
