export const product = {
  product: 'Produit',
  quantity: 'Quantité',
  quantity_short: 'Qté',
  total: 'Total',

  additional_products: 'Produits additionnels',
  link_product_to_session: 'Lier un produit de votre boutique à cette séance',
  link_product_to_session_description:
    'Nous afficherons en suggestion ce produit à votre client au moment de sa commande. Un moyen pour vous de faire une vente additionnelle à votre cours. Cette option est facultative pour votre client',

  add_product: 'Ajouter un produit',
  add_another_product: 'Ajouter un autre produit',
  create_product: 'Créer un produit',
  search_product: 'Rechercher un produit',

  tva_incl: 'TVA. incl',
  tva_percentage: '%',

  delete_warning_message: 'Êtes-vous sûr(e) de vouloir supprimer ce(s) produit(s) ?',
  no_products_has_been_selected: "Aucun produit n'a été sélectionné",

  no_products_yet: "Vous n'avez pas encore de produit",
  verif_max: 'Veuillez vérifier que   stock MAX > MIN',
};
