import {
  IZoneApiResponse,
  IZoneData,
  IZoneResponse,
  ZoneApiResults,
} from 'types/models/Zone/Zone.schema';
import { GlobalVariables } from 'config/constant';
import { ISectorApiResults, ISectorData } from 'types/models/Sectors/Sector.schema';
export const transformZoneItemResponse = (zone: ZoneApiResults): Required<IZoneData> => ({
  id: zone.id,
  nameFr: zone.nameFr,
  nameAr: zone.nameAr,
  status: zone.status === GlobalVariables.STATUS.ACTIVE,
  governorates: zone.governorates ?? [],
  delegations: zone.delegations ?? [],
  sector: transformSectorResponse(zone.sectors),
});
export function transformZoneResponse(response: IZoneApiResponse): IZoneResponse {
  return {
    totalPerPage: response.totalPerPage,
    total: response.total,
    lastPage: response.lastPage,
    nextPage: response.nextPage,
    previousPage: response.previousPage,
    data: response?.results?.map(transformZoneItemResponse) ?? [],
  };
}
export function transformSectorResponse(sectors: ISectorApiResults[] | undefined): ISectorData[] {
  if (!sectors) return [];
  return sectors.map((sector) => ({
    id: sector.id,
    nameFr: sector.nameFr,
    nameAr: sector.nameAr,
    status: sector.status === GlobalVariables.STATUS.ACTIVE,
    zone: sector.zone ?? [],
    delegations: sector.delegations ?? [],
    attractionAbility: sector.attractionAbility ?? [],
    sectorProfile: sector.sectorProfile ?? [],
    zoneId: sector?.zoneId,
  }));
}
