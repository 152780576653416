import { ChangeEvent, UIEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { IUseCustomAutoComplete } from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.type';
import { GlobalVariables } from 'config/constant';
import { OptionType } from 'types/interfaces/Input.type';
import { isEqual } from 'lodash';

export function useCustomAutoComplete<T>({
  useTooltip,
  options,
  value,
  onChange,
  onEndScroll,
  onAsyncSearch,
  messageHelper,
  disabled,
  multiple,
}: IUseCustomAutoComplete<T>) {
  // Region state declarations
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [openTooltipText, setOpenTooltipText] = useState(false);
  const [localOptions, setLocalOptions] = useState(options || []);
  const [messageHelp, setMessageHelp] = useState<string>();

  // EndRegion state declarations

  // Region ref declarations
  const anchorRef = useRef<HTMLButtonElement>(null);
  const refLastSearchValue = useRef<string | null>(null);
  // EndRegion ref declarations
  useEffect(() => setLocalOptions(options), [options]);

  // Region logic declarations

  const onOpenTooltip = useCallback(
    (valueToDisplay: string) => {
      if (!multiple && useTooltip && valueToDisplay.length > 6) {
        setOpenTooltipText(true);
      }
    },
    [multiple, useTooltip],
  );

  const onChangeTextFieldValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue((prevValue) => {
      // if user select all value of input and delete it , we have to clear the value
      if (!multiple && e.target.value === GlobalVariables.EmptyString) onChange(null);
      refLastSearchValue.current = prevValue;
      return e.target.value ?? '';
    });
    // don't need to filter options if onAsyncSearch is true , will work with api options
    if (onAsyncSearch) {
      onAsyncSearch(e.target.value);
    } else {
      const { value: targetValue } = e.target;
      const lowerCasedValue = targetValue.toLowerCase().trim();

      const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(lowerCasedValue),
      );
      setLocalOptions(e.target.value !== GlobalVariables.EmptyString ? filteredOptions : options);
    }
  };

  // this useEffect will use for async search only , update localOptions when options change
  const handleDeleteChip = (chip: OptionType<T>) => {
    if (multiple && value && Array.isArray(value)) {
      const filteredChips = value?.filter((item) => !isEqual(item, chip));
      onChange?.((filteredChips || []) as OptionType<T> & OptionType<T>[]);
    }
  };

  const renderTextFieldValue = useCallback(() => {
    let valueToReturn = '';
    if (multiple) {
      valueToReturn = searchValue;
    } else {
      // if last search value is not empty and search value is empty in that render ,so we assume that the user has cleared the input
      const shouldClearValue = Boolean(refLastSearchValue?.current && !searchValue);
      if (shouldClearValue) {
        onChange(null);
        setSearchValue('');
        valueToReturn = '';
        refLastSearchValue.current = null;
      } else {
        valueToReturn = searchValue || (value as OptionType<T>)?.label || '';
      }
    }
    return valueToReturn;
  }, [multiple, onChange, searchValue, value]);

  const handleClose = () => {
    anchorRef.current?.blur();
    setOpen(false);
  };

  const isOptionExisting = useCallback(
    (currentOption: OptionType<T>): boolean => {
      if (value && Array.isArray(value)) {
        return Boolean(value.find((chip) => isEqual(chip, currentOption)));
      } else {
        return isEqual(value, currentOption);
      }
    },
    [value],
  );

  const onClickMenuItem = useCallback(
    (currentOption: OptionType<T>) => {
      const isOptionSelected = isOptionExisting(currentOption);
      if (multiple) {
        // we have to check if the option is already selected or not
        if (value) {
          if (isOptionSelected) {
            const filteredChips = ((value as OptionType<T>[]) || []).filter(
              (chip) => !isEqual(chip, currentOption),
            );
            onChange(filteredChips as OptionType<T> & OptionType<T>[]);
          } else {
            onChange([...((value as OptionType<T>[]) || []), currentOption] as OptionType<T> &
              OptionType<T>[]);
          }
        } else {
          onChange([currentOption] as OptionType<T> & OptionType<T>[]);
        }
      }
      // if its not multiple
      else {
        onChange(currentOption as OptionType<T> & OptionType<T>[]);
        // close menu
        handleClose();
      }
      setSearchValue('');
      setLocalOptions(options);
      refLastSearchValue.current = null;
      onAsyncSearch?.('');
    },
    [isOptionExisting, multiple, onChange, options, value],
  );
  const onScrollEnd: UIEventHandler<HTMLUListElement> = (e) => {
    const element = e.target as HTMLUListElement;
    // checking if the scroll is at the bottom of the div
    if (element.scrollHeight - element.scrollTop - element.clientHeight < 50) {
      onEndScroll?.();
    }
  };
  const handleOpen = useCallback(
    (eventOnClick?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setMessageHelp(messageHelper);
      if (disabled || messageHelper) {
        return setOpen(false);
      }
      if (!eventOnClick) return setOpen(true);
    },
    [disabled, messageHelper],
  );
  // EndRegion logic declarations
  return {
    searchValue,
    open,
    setOpen,
    openTooltipText,
    setOpenTooltipText,
    localOptions,
    messageHelp,
    anchorRef,
    onOpenTooltip,
    onChangeTextFieldValue,
    handleDeleteChip,
    renderTextFieldValue,
    handleClose,
    isOptionExisting,
    onClickMenuItem,
    onScrollEnd,
    handleOpen,
  };
}
