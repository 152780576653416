export const notification = {
  title: 'Titre',
  content: 'Description',
  brand: 'Marque',
  fireDate: 'Ajouter date de diffusion',
  add: 'Ajouter une notification',
  name: 'Titre',
  Date: 'Date de début',
  zone: 'Zone',
  sector: 'Secteur',
  status: 'Statuts',
  search: 'Chercher une notification',
  list: 'Liste des Notifications',
  delete_warning_message: 'Êtes-vous sûr de vouloir supprimer cette notification ?',
  update: 'Modifier une notification',
  error_notification_content: 'Le champs description ne peut pas contenir une valeur vide',
  error_notification_title: 'Le champs titre ne peut pas contenir une valeur vide',
  no_data_yet: "Vous n'avez pas encore de notifications",
  invalid_date: 'Date doit être supérieure à la date actuelle',
};
