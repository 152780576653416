import {
  ICategoriesLogisticsApiResponse,
  ICategoriesLogisticsApiResults,
  ICategoriesLogisticsData,
  ICategoriesLogisticsDecodedResponse,
} from 'types/models/CategoriesLogistics/categoriesLogistics.schema';
import { GlobalVariables } from 'config/constant';
import { transformChildrenCategoryResponse } from 'redux/api/category/category.transform';

export const transformCategoriesLogisticsResponse = (
  response: ICategoriesLogisticsApiResponse,
): ICategoriesLogisticsDecodedResponse => {
  return {
    totalPerPage: response?.totalPerPage,
    total: response?.total,
    lastPage: response?.lastPage,
    nextPage: response?.nextPage,
    previousPage: response?.previousPage,
    data: response?.results?.map((item) => transformCategoriesLogisticsDataResponse(item)),
  };
};

export const transformCategoriesLogisticsDataResponse = (
  data: ICategoriesLogisticsApiResults,
): ICategoriesLogisticsData => {
  return {
    id: data?.id,
    nameFr: data?.nameFr,
    nameAr: data?.nameAr,
    status: data?.status === GlobalVariables.STATUS.ACTIVE,
    sageCode: data?.sageCode,
    catalogs: transformChildrenCategoryResponse(data?.catalogs ?? []),
    createdAt: data?.createdAt,
    updatedAt: data?.updatedAt,
    warehouse: data?.warehouse,
    warehouseId: data?.warehouseId,
  };
};
