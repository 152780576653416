import { Chip, ClickAwayListener, Stack, Typography } from '@mui/material';
import { CustomAutoCompleteType } from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.type';
import { StyledBox } from 'components/CustomInputs/CustomAutoCompleteGovernorates/CustomAutoCompleteGovernorates.style';
import { translate } from 'locales/i18n';
import { OptionType } from 'types/interfaces/Input.type';

import { BooleanToDom } from 'config/enums/common.enums';
import { useCustomAutoComplete } from 'components/CustomInputs/CustomAutoComplete/hooks/useCustomAutoComplete';
import CustomAutocompletePopper from 'components/CustomInputs/CustomAutoComplete/Components/CustomAutocompletePopper';
import CustomAutoCompleteTextField from 'components/CustomInputs/CustomAutoComplete/Components/CustomAutoCompleteTextField';

export default function CustomAutoComplete<T>({
  value,
  onChange,
  options,
  multiple,
  onAsyncSearch,
  disabled,
  onEndScroll,
  isLoading,
  error,
  placeholder,
  fieldName,
  label,
  size,
  useTooltip = false,
  messageHelper,
  autoWidthPopper,
  isTextArea,
}: CustomAutoCompleteType<T>) {
  const {
    searchValue,
    open,
    setOpen,
    openTooltipText,
    setOpenTooltipText,
    localOptions,
    messageHelp,
    anchorRef,
    onOpenTooltip,
    onChangeTextFieldValue,
    handleDeleteChip,
    renderTextFieldValue,
    handleClose,
    isOptionExisting,
    onClickMenuItem,
    onScrollEnd,
    handleOpen,
  } = useCustomAutoComplete<T>({
    options,
    multiple,
    useTooltip,
    onAsyncSearch,
    value,
    onChange,
    onEndScroll,
    messageHelper,
    disabled,
  });
  return (
    <Stack spacing={1.5}>
      {label && <Typography variant="h5">{translate(label)}</Typography>}
      <Stack>
        <ClickAwayListener onClickAway={handleClose}>
          <StyledBox
            has_chips={
              multiple && value && (value as OptionType<T>[]).length > 0
                ? BooleanToDom.TRUE
                : BooleanToDom.FALSE
            }
            error={error ? BooleanToDom.TRUE : BooleanToDom.FALSE}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(e) => handleOpen(e)}
            onFocus={() => handleOpen()}
            onBlur={() => setOpen(false)}
          >
            <Stack
              flexDirection={'row'}
              spacing={2}
              sx={{ flexWrap: 'wrap' }}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              {multiple &&
                Array.isArray(value) &&
                value?.map((chip, index) => (
                  <Chip
                    key={`${chip.value}-${index}`}
                    label={chip.label}
                    onDelete={() => handleDeleteChip(chip)}
                    style={{ margin: '5px' }}
                    color={'primary'}
                  />
                ))}
              <CustomAutoCompleteTextField<T>
                onChangeTextFieldValue={onChangeTextFieldValue}
                renderTextFieldValue={renderTextFieldValue}
                anchorRef={anchorRef}
                value={value}
                openTooltipText={openTooltipText}
                fieldName={fieldName}
                size={size}
                disabled={disabled}
                multiple={multiple}
                placeholder={placeholder}
                onMouseOut={() => setOpenTooltipText(false)}
                onMouseOver={() => onOpenTooltip(renderTextFieldValue())}
                isTextArea={isTextArea}
              />
              <CustomAutocompletePopper<T>
                anchorRef={anchorRef}
                open={open}
                isOptionExisting={isOptionExisting}
                localOptions={localOptions}
                onClickMenuItem={onClickMenuItem}
                onScrollEnd={onScrollEnd}
                searchValue={searchValue}
                options={options}
                isLoading={isLoading}
                useTooltip={useTooltip}
                autoWidthPopper={autoWidthPopper}
              />
            </Stack>
          </StyledBox>
        </ClickAwayListener>
        {(error || messageHelp) && (
          <Typography variant={'caption'} color={'error'}>
            {translate((error || messageHelp) ?? '')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
