import { alpha, Box, Button, List, styled, TextField } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_down.svg';
import { GlobalFonts } from 'config/constant/fonts.config';
import { ReactComponent as AddIcon } from 'assets/icons/add-circle.svg';
import { BooleanToDom } from 'config/enums/common.enums';
export const StyledTextFieldGovernorateSelector = styled('input')(
  ({ decreaseWidth }: { decreaseWidth?: boolean }) =>
    ({ theme }) => ({
      flex: 1,
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
      },
      '&:focus-visible': {
        border: 'none',
      },
      '&:focus-visible, *:focus': {
        outline: 'none',
      },
      background: 'inherit',
      marginTop: '0px !important',
      paddingLeft: '10px',
      fontSize: '16px',
      fontFamily: GlobalFonts.FONT_PRIMARY,
      color: theme.palette.grey[800],
      width: decreaseWidth ? '50%' : 'auto',
      textOverflow: 'ellipsis',
    }),
);
export const StyledTextFieldAutoComplete = styled(TextField)(
  ({ decreasewidth }: { decreasewidth?: BooleanToDom }) =>
    ({ theme }) => ({
      flex: 1,
      '& .MuiInputBase-input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        display: '-webkit-box',
        width: '100%',
      },

      '& .Mui-disabled': {
        WebkitTextFillColor: theme.palette.grey[700],
      },
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
      },
      '&:focus-visible': {
        border: 'none',
      },
      '&:focus-visible, *:focus': {
        outline: 'none',
      },
      background: 'inherit',
      marginTop: '0px !important',
      paddingLeft: '10px',
      fontSize: '16px',
      fontFamily: GlobalFonts.FONT_PRIMARY,
      color: theme.palette.grey[800],
      width: decreasewidth === BooleanToDom.TRUE ? '50%' : 'auto',
      textOverflow: 'ellipsis',
    }),
);
export const StyledArrowIcon = styled(ArrowIcon)(
  ({
    is_open,
    rotation_open,
    rotation_close,
  }: {
    is_open: BooleanToDom;
    rotation_open?: string;
    rotation_close?: string;
  }) => ({
    transform:
      is_open === BooleanToDom.TRUE
        ? rotation_open || 'rotate(180deg)'
        : rotation_close || 'rotate(0deg)',
  }),
);
export const StyledBox = styled(Box)(
  ({ theme }) =>
    ({ has_chips, error }: { has_chips: BooleanToDom; error?: BooleanToDom }) => ({
      border:
        error === BooleanToDom.TRUE
          ? `1px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '5px',
      padding: has_chips === BooleanToDom.TRUE ? '6px' : '10px',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      backgroundColor: theme.palette.common.white,
    }),
);
export const AddButtonStyle = styled(Button)(({ theme }) => ({
  fontFamily: GlobalFonts.FONT_PRIMARY_500,
  fontWeight: 500,
  textAlign: 'center',
  paddingBlock: 10,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: 0,
  border: `1px solid ${theme.palette.primary.main}`,
}));
export const StyledList = styled(List)(({ theme }) => ({
  maxHeight: '40vh',
  overflowY: 'scroll',
  height: 'max-content',
}));
export const AddIconStyle = styled(AddIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));
