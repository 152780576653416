import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import { baseQueryConfig } from '../BaseQueryConfig';
import { injectParams } from 'utils/helpers/api-url';
import Paginator from 'types/interfaces/Paginator';
import {
  injectEnableFilterByClientsScopeForWarehouse,
  injectFilterClientCategoryIdArray,
  injectFilterClientsArray,
  injectFilterSectorId,
  injectFilterStatus,
  injectFilterZoneIds,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { IWarehouseAPIResponse, IWarehouseResponse } from 'types/models/Warehouse/Warehouse';
import { transformWarehouseResponse } from './warehouse.transform';
import { FilterEnum } from 'types/interfaces/filter.enum';
export const warehouseApi = createApi({
  reducerPath: 'warehouseApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['Warehouses'],
  endpoints: (builder) => ({
    getWarehouse: builder.query({
      query: (params: {
        paginator?: Paginator;
        status: boolean | null;
        zoneIds?: number[] | null;
        clientIds?: number[] | null;
        enableFilterByClientsScope?: boolean;
        clientsCategoryIds?: number[] | null;
        clientClassIds?: number[] | null;
        sectorIds?: number[] | null;
      }) =>
        injectParams(ENDPOINTS.WAREHOUSE.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterZoneIds(params?.zoneIds),
          ...injectFilterClientsArray(params?.clientIds),
          ...injectFilterStatus(params.status),
          ...injectEnableFilterByClientsScopeForWarehouse(params.enableFilterByClientsScope),
          ...injectFilterSectorId(params.sectorIds, FilterEnum.sectorIds),
          ...injectFilterClientCategoryIdArray(
            params.clientsCategoryIds,
            FilterEnum.clientCategoryIds,
          ),
          ...injectFilterClientsArray(params.clientClassIds, FilterEnum.ClientClassIds),
        }),
      transformResponse: (response: IWarehouseAPIResponse): IWarehouseResponse => {
        return transformWarehouseResponse(response);
      },
      providesTags: ['Warehouses'],
    }),
    createWarehouse: builder.mutation({
      query: ({ warehouseData }) => ({
        url: ENDPOINTS.WAREHOUSE.GET,
        method: 'POST',
        body: { ...warehouseData },
      }),
      invalidatesTags: ['Warehouses'],
    }),
    updateWarehouse: builder.mutation({
      query: ({ id, warehouseData, status }) => ({
        url: ENDPOINTS.WAREHOUSE.GETBYID(id),
        method: 'PATCH',
        body: { ...warehouseData, status: status },
      }),
      invalidatesTags: ['Warehouses'],
    }),
    getWarehouseById: builder.query({
      query: (id: number) => ENDPOINTS.WAREHOUSE.GETBYID(id),
      providesTags: ['Warehouses'],
    }),
    deleteWarehouse: builder.mutation({
      query: ({ id }) => ({
        url: ENDPOINTS.WAREHOUSE.GETBYID(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Warehouses'],
    }),
  }),
});

export const {
  useCreateWarehouseMutation,
  useGetWarehouseQuery,
  useDeleteWarehouseMutation,
  useUpdateWarehouseMutation,
  useGetWarehouseByIdQuery,
} = warehouseApi;
