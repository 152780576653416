import {
  IClient,
  IClientApi,
  IClientAPIResponse,
  IClientResponse,
  IClientScopeCategories,
  IClientScopeCategoriesApi,
  IClientScopes,
  IClientScopesApi,
} from 'types/models/Client/client';
import { IClientDetails, IClientDetailsApi } from 'types/models/Clients/Client';
import { GlobalVariables } from 'config/constant';
import { arrayIsEmpty } from 'utils/helpers/array.helpers';

function transformCategoryScopeResponse(
  category: IClientScopeCategoriesApi,
): IClientScopeCategories {
  return {
    id: category?.id,
    level: category?.level,
    status: category?.status === GlobalVariables.STATUS.ACTIVE,
    nameAr: category?.nameAr,
    nameFr: category?.nameFr,
    deletedAt: category?.deletedAt,
    sageId: category?.sageId,
    children: category?.children?.map((child) => transformCategoryScopeResponse(child)),
  };
}
function transformClientScopes(clientScope: IClientScopesApi[]): IClientScopes[] {
  if (!clientScope || arrayIsEmpty(clientScope)) return [];
  return clientScope.map((scope) => {
    const category = scope?.catalog;
    return {
      assignedAgent: {
        id: scope?.assignedAgent?.id,
        email: scope?.assignedAgent?.email,
        sageCode: scope?.assignedAgent?.sageCode,
        firstName: scope?.assignedAgent?.firstName,
        lastName: scope?.assignedAgent?.lastName,
        isSuper: scope.assignedAgent?.isSuper,
        status: scope?.assignedAgent?.status === GlobalVariables.STATUS.ACTIVE,
      },
      catalog: transformCategoryScopeResponse(category),
      clientId: scope?.clientId,
      catalogId: scope?.catalogId,
      warehouseId: scope?.warehouseId,
      salesLineId: scope?.salesLineId,
      warehouse: scope?.warehouse,
    };
  });
}

function transformClientAddress(res: IClientApi) {
  if (!res?.addresses) return [];
  return res?.addresses?.map((address) => {
    return {
      id: address?.id,
      address: address?.address,
      deletedAt: address?.deletedAt,
      latitude: address?.latitude,
      longitude: address?.longitude,
      postalCode: address?.postalCode,
      streetNumber: address?.streetNumber,
    };
  });
}

export const transformClientsResponse = (responseData: IClientAPIResponse): IClientResponse => {
  return {
    data: responseData?.results?.map(transformClientItemResponse),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
export const transformClientItemResponse = (res: IClientApi): IClient => {
  return {
    id: res.id,
    phoneNumber: res.phoneNumber,
    firstName: res.firstName,
    lastName: res.lastName,
    email: res.email,
    cin: res.cin,
    sageCode: res.sageCode,
    clientRisk: res.clientRisk,
    createdAt: res.createdAt,
    paymentMethod: res.paymentMethod,
    clientStatus: res.clientStatus,
    activationStatus: res.activationStatus,
    zoneChief: res.zoneChief,
    clientClass: res.clientClass,
    clientCategory: res.clientCategory,
    zone: res.zone,
    sector: res.sector,
    clientScopes: transformClientScopes(res.clientScopes),
    addresses: transformClientAddress(res),
  };
};
export const transformGetClientByIdResponse = (data: IClientDetailsApi): IClientDetails => {
  return {
    id: data?.id,
    phoneNumber: data?.phoneNumber,
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    cin: data?.cin,
    sageCode: data?.sageCode,
    clientRisk: data?.clientRisk,
    createdAt: data?.createdAt,
    paymentMethod: data?.paymentMethod,
    clientStatus: data?.clientStatus,
    activationStatus: data.activationStatus === GlobalVariables.STATUS.ACTIVE,
    zoneChief: {
      id: data?.zoneChief?.id,
      firstName: data?.zoneChief?.firstName,
      lastName: data?.zoneChief?.lastName,
      email: data?.zoneChief?.email,
      sageCode: data?.zoneChief?.sageCode,
      status: data?.zoneChief?.status === GlobalVariables.STATUS.ACTIVE,
      isSuper: data?.zoneChief?.isSuper,
    },
    clientClass: data?.clientClass,
    clientCategory: data?.clientCategory,
    clientScopes: transformClientScopes(data.clientScopes),
    budgetLimit: data?.budgetLimit,
    currentBudgetLimit: data?.currentBudgetLimit,
    defaultShop: data?.defaultShop,
    deletedAt: data?.deletedAt,
    addresses: data?.addresses?.map((address) => {
      return {
        id: address?.id,
        address: address?.address,
        deletedAt: address?.deletedAt,
        latitude: address?.latitude,
        longitude: address?.longitude,
        postalCode: address?.postalCode,
        streetNumber: address?.streetNumber,
      };
    }),
    avatarMedia: {
      uuid: data?.avatarMedia?.uuid,
      clientMediaTag: data?.avatarMedia?.clientMediaTag,
      createdAt: data?.avatarMedia?.createdAt,
      mediaType: data?.avatarMedia?.mediaType,
      id: data?.avatarMedia?.id,
      path: data?.avatarMedia?.path,
      updatedAt: data?.avatarMedia?.updatedAt,
      url: data?.avatarMedia?.url,
    },

    zone: { id: data?.zone?.id, nameFr: data?.zone?.nameFr, nameAr: data?.zone?.nameAr },
    sector: {
      id: data?.sector?.id,
      nameFr: data?.sector?.nameFr,
      nameAr: data?.sector?.nameAr,
      attractionAbility: data?.sector?.attractionAbility,
    },
  };
};
