import { InputObject, OptionType } from 'types/interfaces/Input.type';
import { ProductPackageTypeEnum } from 'features/Products/AddProducts/AddProduct.constants';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { IArticleByIdResponse } from 'types/models/Article/article';
export type IAddProductFormConfig = {
  label?: string;
  inputConfig: InputObject;
};
export interface IAddProductPricesFormConfig {
  label?: string;
  inputConfigs: { label: string; inputConfig: InputObject }[];
}
export interface IAddProductPackagingFormConfig {
  label?: string;
  inputConfig: InputObject;
}

export interface IProductPackageType {
  [ProductPackageTypeEnum.FromUnitPackaging]?: string;
  [ProductPackageTypeEnum.FromPackPackaging]?: string;
  [ProductPackageTypeEnum.FromRackPackaging]?: string;
  [ProductPackageTypeEnum.FromPalletPackaging]?: string;
  [ProductPackageTypeEnum.UnitPackaging]: number;
  [ProductPackageTypeEnum.RackPackaging]: number;
  [ProductPackageTypeEnum.PackPackaging]: number;
  [ProductPackageTypeEnum.PalletPackaging]: number;
}
export interface IInputImage {
  articleMediaTag: string;
  file: File | null;
}
export enum ImageTypeEnum {
  Diverse = 'diverse',
  Unit = 'unit',
  Carton = 'carton',
  Display = 'display',
  Pallet = 'pallet',
}
export enum SelectorStockFieldNameEnum {
  MOQ = 'MOQ',
  MAX = 'MAX',
}
export enum InputStockFieldNameEnum {
  Moq = 'moq',
  MaxStock = 'maxStock',
}
export interface IWatchedPcbInput {
  watchedPcbUnitInput: number;
  watchedPcbRackInput: number;
  watchedPcbPackInput: number;
  watchedPcbPaletteInput: number;
}
export interface IUpdateProductProps {
  handleChangeBrand: (value: OptionType<number>) => void;
  handleAddSubCategory: (value: OptionType<number>) => void;
  setSelectedCategory: (value: OptionType<number>) => void;
  setValue: UseFormSetValue<FieldValues>;
  setActive: (active: boolean) => void;
  articleById: IArticleByIdResponse | undefined;
}
