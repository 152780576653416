import { GlobalVariables } from 'config/constant/global.variables';
import NoDataFound from 'components/NoDataFound/NoDataFound';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, IconButton, MenuItem, Skeleton } from '@mui/material';
import CustomTooltip from 'components/Tooltips/CustomTooltip/CustomTooltip';
import { generateArray } from 'utils/helpers/array.helpers';
import { StyledTypographyLabel } from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.style';
import { ReactComponent as AddIcon } from 'assets/icons/add-circle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { OptionItemProps } from 'components/CustomInputs/CustomAutoComplete/CustomAutoComplete.type';
export function CustomAutoCompleteOptions<T>({
  isOptionExisting,
  anchorRef,
  onClickMenuItem,
  useTooltip,
  options,
  isLoading,
  localOptions,
  searchValue,
  autoWidthPopper,
}: OptionItemProps<T>) {
  const boxOptionsWidth =
    autoWidthPopper || !anchorRef?.current?.offsetWidth
      ? 'auto'
      : anchorRef?.current?.offsetWidth - anchorRef.current.offsetWidth / 12;
  const theme = useTheme();
  if (
    (!options?.length && !isLoading) ||
    (localOptions.length === 0 && !isLoading && searchValue !== GlobalVariables.EmptyString)
  )
    return <NoDataFound message={'common.no_data_yet'} minHeight={100} />;
  return (
    <>
      {(localOptions || []).map((currentOption, index) => (
        <MenuItem
          key={`${currentOption.value}-${currentOption.label}-${index}`}
          onClick={() => onClickMenuItem(currentOption)}
          sx={{
            backgroundColor: isOptionExisting(currentOption)
              ? alpha(theme.palette.primary.main, 0.1)
              : '',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flex={1}
            maxWidth={boxOptionsWidth}
            sx={{ overflowX: 'hidden' }}
          >
            {useTooltip ? (
              <CustomTooltip title={currentOption.label} placement={'top'} enterDelay={2000}>
                <StyledTypographyLabel>{currentOption.label}</StyledTypographyLabel>
              </CustomTooltip>
            ) : (
              <StyledTypographyLabel>{currentOption.label}</StyledTypographyLabel>
            )}

            <IconButton onMouseDown={() => onClickMenuItem(currentOption)}>
              {isOptionExisting(currentOption) ? (
                <CheckIcon stroke={theme.palette.primary.dark} height="20" width="20" />
              ) : (
                <AddIcon fill={theme.palette.primary.light} />
              )}
            </IconButton>
          </Box>
        </MenuItem>
      ))}
      {isLoading && (
        <>
          {generateArray(7).map((key) => (
            <MenuItem key={key.toString()}>
              <Skeleton height={40} width="95%" />
            </MenuItem>
          ))}
        </>
      )}
    </>
  );
}
