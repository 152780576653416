import { Box, Card, Stack, Typography, styled } from '@mui/material';

export const RootCard = styled(Card)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(2),
  boxShadow: `0px 0px 0px ${theme.palette.grey[50]};`,
  background: theme.palette.grey[200],
}));
export const AddPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
export const RootStyleForm = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
export const TypographyCardTitle = styled(Typography)({
  flexGrow: 1,
  letterSpacing: 1,
  textUnderlineOffset: 3,
  textDecoration: 'underline',
  fontSize: 'large',
});
export const TypographyFallbackError = styled(TypographyCardTitle)({
  textDecoration: 'none',
  textAlign: 'center',
});
const Point = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
export const PrimaryPoint = styled(Point)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));
export const SecondaryPoint = styled(Point)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
}));
export const ErrorPoint = styled(Point)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
}));
