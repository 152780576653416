import { CircularProgress, MenuItem, Skeleton, Stack } from '@mui/material';
import { generateArray } from 'utils/helpers/array.helpers';
import React from 'react';

const CustomLoader = ({
  minHeight,
  size,
}: {
  minHeight?: number | string;
  size?: string | number;
}) => {
  return (
    <Stack minHeight={minHeight} justifyContent={'center'} alignItems={'center'}>
      <CircularProgress color="primary" size={size} />
    </Stack>
  );
};

export default CustomLoader;

/**
 * Generate skeleton for options of select component
 * @param length length of array , default is 7
 * @param height height of skeleton , default is 40
 */
export function generateOptionsSkeleton({ length, height }: { length?: number; height?: number }) {
  return generateArray(length || 7).map((key) => (
    <MenuItem key={key.toString()}>
      <Skeleton height={height || 40} width="95%" />
    </MenuItem>
  ));
}
