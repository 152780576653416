import { styled, TextField } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

export const RootStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: 50,
  input: {
    fontSize: '0.938rem',
    '&::placeholder': {
      fonFamily: GlobalFonts.FONT_PRIMARY_300,
      color: theme.palette.grey[500],
      opacity: 1,
    },
  },
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 68,
  },
  '& .Mui-focused': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 13px 45px ${theme.palette.grey[300]}`,
    border: 'none',
    '& .MuiInputAdornment-root': {
      fill: theme.palette.primary.main,
    },
  },
}));
