import { ListItemText } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { ItemStyle } from './NavSectionItem.style';
import { NavItemProps } from './NavSectionItem.type';
import { setSearchText } from 'redux/slices/app/appSlice';
import { useAppDispatch } from 'redux/hooks';

function NavSectionItem({ item, checkIsActive }: NavItemProps) {
  const { title, path } = item;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const isActiveRoot = checkIsActive(item.path);
  const handleNavigationClick = () => {
    dispatch(setSearchText(GlobalVariables.EmptyString));
  };

  return (
    <ItemStyle
      onClick={handleNavigationClick}
      component={RouterLink}
      to={path}
      active={isActiveRoot ? GlobalVariables.TRUE : GlobalVariables.FALSE}
    >
      <ListItemText disableTypography primary={t(title)} />
    </ItemStyle>
  );
}

export default NavSectionItem;
