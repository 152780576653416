import { ThemeOptions } from '@mui/material';
import breakpoints from './breakpoints';
import lightPalette from './palettes/palette.light';
import typography from './typography';

// A custom theme for this app
const lightTheme: ThemeOptions = {
  palette: lightPalette,
  typography,
  breakpoints,
};

export default lightTheme;
