import { IBrandAPIResponse, IBrandResponse } from 'types/models/Brands/Brands';

export const transformBrandResponse = (responseData: IBrandAPIResponse): IBrandResponse => {
  return {
    data: responseData.results.map((res) => {
      return {
        id: res.id,
        nameFr: res.nameFr,
        nameAr: res.nameAr,
        media: res.media,
        name: res.name,
      };
    }),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
