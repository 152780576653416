import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import {
  injectExpand,
  injectFilterClientCategoryId,
  injectFilterZoneId,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { transformHomeSectionsResponse } from 'redux/api/homeSections/homeSection.decoder';
import { IHomeSectionsSchemaResponseApi } from 'types/models/HomeSections/HomeSections.schema';
import { ExpandEnum } from 'types/interfaces/sharedType.type';

export const homeSectionApi = createApi({
  reducerPath: 'homeSectionApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['homeSectionApi'],
  endpoints: (builder) => ({
    getHomeSections: builder.query({
      query: (params: {
        paginator: Paginator;
        zoneId?: number | null;
        clientCategoryId?: number | null;
        expand?: ExpandEnum[] | null;
      }) =>
        injectParams(ENDPOINTS.HOME_SECTIONS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterZoneId(params.zoneId),
          ...injectFilterClientCategoryId(params.clientCategoryId),
          ...injectExpand(params.expand),
        }),
      transformResponse: (response: IHomeSectionsSchemaResponseApi) =>
        transformHomeSectionsResponse(response),
      providesTags: ['homeSectionApi'],
    }),
    addHomeSection: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.HOME_SECTIONS.ADD,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['homeSectionApi'],
    }),
    editHomeSection: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.HOME_SECTIONS.ADD,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['homeSectionApi'],
    }),
  }),
});
export const { useGetHomeSectionsQuery, useEditHomeSectionMutation, useAddHomeSectionMutation } =
  homeSectionApi;
