import { BooleanToDom, ErrorOperationEnum, GovernoratesStartCode } from 'config/enums/common.enums';
import { FilterStatusGroupedActionsEnum } from 'shared/Filter/FilterStatus';
import { GlobalVariables } from 'config/constant';
import { PackingTypeEnum } from 'features/Products/AddProducts/AddProduct.constants';
import { isNaN, isNil } from 'lodash';
import { DealTypeEnum, Nullable, OrderStatusEnum } from 'types/interfaces/sharedType.type';
import { translate } from 'locales/i18n';
import { IErrorApi } from 'types/interfaces/ErrorApi';

export const namePrenameToString = (
  name: string | null | undefined,
  prename: string | null | undefined,
) => {
  const fullName = [name, prename].filter(Boolean).join(' ');
  return fullName || '';
};
export function onChangeStatusFilterStringToBoolean(value: string) {
  if (value === FilterStatusGroupedActionsEnum.Active) {
    return true;
  } else if (value === FilterStatusGroupedActionsEnum.Inactive) {
    return false;
  } else {
    return null;
  }
}
export const onChangeStatusBooleanToString = (value: boolean): string =>
  value ? GlobalVariables.STATUS.ACTIVE : GlobalVariables.STATUS.INACTIVE;

export function getGpsLocation(gpsField: string | undefined) {
  const [latitude, longitude] = gpsField
    ? gpsField.split(',').map((value: string) => value.trim())
    : [undefined, undefined];
  return { latitude, longitude };
}
export function filterDelegations(array: string[]): string[] {
  return array.filter((item) => !item.startsWith(GovernoratesStartCode));
}
export function filterGovernorates(array: string[]): string[] {
  return array.filter((item) => item.startsWith(GovernoratesStartCode));
}
export const concatenateGpsLocation = (latitude: Nullable<string>, longitude: Nullable<string>) =>
  latitude && longitude ? `${latitude},${longitude}` : GlobalVariables.EmptyString;
export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const isEmptyString = (str: string) => str === GlobalVariables.EmptyString;
export function extractSelectedValueFromIdCheckBox(inputString: string): string | null {
  const regex = /packing-(.*?)-checkbox/;
  const matches = inputString.match(regex);
  if (matches && matches.length === 2) {
    return matches[1].toString();
  }

  return null;
}

export function extractPackingTypeLabel(value: PackingTypeEnum) {
  switch (value) {
    case PackingTypeEnum.Unit:
      return translate('inputs.unit');
    case PackingTypeEnum.Rack:
      return translate('inputs.rack');
    case PackingTypeEnum.Pack:
      return translate('inputs.pack');
    case PackingTypeEnum.Pallet:
      return translate('inputs.pallet');
    default:
      break;
  }
}
export const setAutoCompleteError = (message: string | undefined) =>
  message === GlobalVariables.EmptyString || message === undefined ? undefined : { message };
export const injectTypographyToTable = <T>(text: Nullable<T>) =>
  isNil(text) ||
  isNaN(text) ||
  (typeof text === 'string' && text?.trim()) === GlobalVariables.EmptyString
    ? GlobalVariables.DoubleDash
    : String(text);

const orderStatusObject = {
  [OrderStatusEnum.New]: translate('page.Ordre.Status.new'),
  [OrderStatusEnum.Shipped]: translate('page.Ordre.Status.shipped'),
  [OrderStatusEnum.Delivered]: translate('page.Ordre.Status.delivered'),
  [OrderStatusEnum.Canceled]: translate('page.Ordre.Status.canceled'),
  [OrderStatusEnum.Confirmed]: translate('page.Ordre.Status.validated'),
  [OrderStatusEnum.Updated]: translate('page.Ordre.Status.updated'),
  [OrderStatusEnum.Preparing]: translate('page.Ordre.Status.preparing'),
  [OrderStatusEnum.Invoiced]: translate('page.Ordre.Status.invoiced'),
  [OrderStatusEnum.Created]: translate('page.Ordre.Status.created'),
};
export const extractLabelStatusOrderFromEnum = (value: OrderStatusEnum): string =>
  orderStatusObject[value];

export const extractEnumFromLabelStatusOrder = (value: string): OrderStatusEnum | undefined => {
  return Object.keys(orderStatusObject).find(
    (key) => orderStatusObject[key as keyof object] === value,
  ) as OrderStatusEnum | undefined;
};
export const isValidationStatusOrder = (value: OrderStatusEnum) =>
  value !== (OrderStatusEnum.Canceled || OrderStatusEnum.New);

export const extractLabelPromoTypeFromEnum = (value: DealTypeEnum): string => {
  const object = {
    [DealTypeEnum.Standard]: translate('page.Deals.standard_type'),
    [DealTypeEnum.PackageDeal]: translate('page.Deals.package_type'),
    [DealTypeEnum.Discount]: translate('page.Deals.discount_type'),
  };
  return object[value];
};
/**
 * @description This function is used to inject error message from the backend to the frontend
 * @param error error from the backend (IErrorApi)
 * @param codeToCompare code to compare { code: string; option?: Record<string, string | number> }[]
 * @returns message to display
 *  */
export function injectErrorApiResponse(
  error: unknown,
  codeToCompare: { code: string; option?: Record<string, string | number> }[],
): string {
  const e = error as IErrorApi;

  let messageToReturn = '';
  codeToCompare.forEach((codeObject) => {
    if (e?.data?.errorCode === codeObject.code) {
      const isForeignKeyUpdateOperation =
        codeObject.code === GlobalVariables.ErrorCodes.FOREIGN_KEY_CONSTRAINT &&
        e.data?.data?.operation === ErrorOperationEnum.UPDATE;
      if (isForeignKeyUpdateOperation)
        messageToReturn = translate('errorApi.FOREIGN_KEY_CONSTRAINT_UPDATE');
      else messageToReturn = translate(`errorApi.${codeObject.code}`, codeObject.option);
    }
  });
  return messageToReturn;
}

/**
 * @description This function is used to compare and transform value to return
 * @param valueToReturn
 * @param defaultValue
 * @param excludedValue
 * @returns defaultValue if valueToReturn is null, undefined, NaN, empty string or excludedValue otherwise valueToReturn
 */
export function compareAndTransformValue<T, D>(
  valueToReturn: T,
  defaultValue: D,
  excludedValue?: T,
): T | D {
  if (
    isNil(valueToReturn) ||
    isNaN(valueToReturn) ||
    (typeof valueToReturn === 'string' && valueToReturn === '') ||
    valueToReturn === excludedValue
  )
    return defaultValue;

  return valueToReturn;
}
export const convertBooleanToBooleanToDom = (value: boolean | null | undefined): BooleanToDom =>
  value ? BooleanToDom.TRUE : BooleanToDom.FALSE;
export const convertIsUnderCutOffToBoolean = (value: boolean | null): boolean => value !== false;
export const getErrorDisableWithMessage = (state: boolean, message: string) =>
  state ? message : undefined;
