import {
  Article,
  ArticleApi,
  ArticlesApiResponse,
  ArticlesResponse,
  IArticleByIdResponse,
  IArticleByIdResponseApi,
  IArticlePrice,
  IArticlePriceApi,
  IPackingUnitResponse,
  IPackingUnitsApiResponse,
  IPcbAllObjectsApi,
  IPcbObject,
  IPcbResponse,
  IPcbResponseApi,
  IPrices,
  IPricesApi,
  IStockArticleResponse,
  IStockArticleResponseApi,
  mediaArticleApi,
  PricesObject,
  PricesObjectApi,
} from 'types/models/Article/article';
import { onChangeStatusFilterStringToBoolean } from 'utils/helpers/string.helpers';
import { GlobalVariables } from 'config/constant';
import { transformChildrenCategoryResponse } from 'redux/api/category/category.transform';

export function transformMediaArticle(res: mediaArticleApi[]) {
  return res?.map((media) => {
    return {
      id: media?.id,
      url: media?.url,
      mediaType: media?.mediaType,
      path: media?.path,
      uuid: media?.uuid,
      articleMediaTag: media?.articleMediaTag,
      isDefaultArticleMedia: media?.isDefaultArticleMedia,
    };
  });
}

export function transformPcbObjectArticle(res: IPcbAllObjectsApi | undefined): IPcbObject[] {
  return res
    ? Object.values(res)?.map((obj) => {
        return {
          pcb: obj?.pcb,
          pcbInUnit: obj?.pcbInUnit,
          packingType: obj?.packingType,
          unitLabel: obj?.unitLabel,
          unitLabelFr: obj?.unitLabelFr,
          unitType: obj?.unitType,
        };
      })
    : [];
}
export const transformItemPricesArticle = (price: IPricesApi): IPrices => {
  return {
    id: price?.id ?? null,
    clientCategory: {
      id: price?.clientCategory?.id,
      nameAr: price?.clientCategory?.nameAr,
      nameFr: price?.clientCategory?.nameFr,
      status: price?.clientCategory?.status,
      minimumOrderPrice: price?.clientCategory?.minimumOrderPrice,
    },
    maxOrderQty: price?.maxOrderQty,
    maxOrderPackingType: price?.maxOrderPackingType,
    minOrderPackingType: price?.minOrderPackingType,
    minOrderQty: price?.minOrderQty,
    clientCategoryId: price?.clientCategoryId,
    createdAt: price?.createdAt,
    discounts: price?.discounts?.map((discount) => {
      return {
        id: discount?.id,
        createdAt: discount?.createdAt,
        discountPackingPrices: discount?.discountPackingPrices?.map((discountPackingPrice) => {
          return {
            type: discountPackingPrice?.discountPackingPrices?.[0]?.type,
            percentage: discountPackingPrice?.discountPackingPrices?.[0]?.percentage,
          };
        }),
        discountStock: discount?.discountStock,
        nameFr: discount?.nameFr,
        discountRemainingStock: discount?.discountRemainingStock,
        endDate: discount?.endDate,
        startDate: discount?.startDate,
        updatedAt: discount?.updatedAt,
      };
    }),
    maxOrderQtyInUnit: price?.maxOrderQtyInUnit,
    packingPrices: price?.packingPrices?.map((packingPrice) => {
      return {
        type: packingPrice?.type,
        amount: packingPrice?.amount,
      };
    }),
    minOrderQtyInUnit: price?.minOrderQtyInUnit,
    updatedAt: price?.updatedAt,
  };
};
const transformArticleStocks = (stocks: ArticleApi['stocks']) => {
  return stocks?.map((stock) => ({
    articleId: stock?.articleId,
    warehouseId: stock?.warehouseId,
    currentStock: stock?.currentStock,
    realStock: stock?.realStock,
    warehouse: stock?.warehouse,
  }));
};

export function transformItemArticleResponse(res: ArticleApi | undefined): Article | undefined {
  if (res)
    return {
      id: res?.id,
      nameAr: res?.nameAr,
      nameFr: res?.nameFr,
      dealStock: res?.dealStock,
      dealType: res?.dealType,
      descriptionAr: res?.descriptionAr,
      descriptionFr: res?.descriptionFr,
      ean13Code: res?.ean13Code,
      sageLabel: res?.sageLabel,
      sageReference: res?.sageReference,
      status: res?.status === GlobalVariables.STATUS.ACTIVE,
      pricesObject: transformPricesObject(res?.pricesObject),
      brand: {
        id: res?.brand?.id,
        nameAr: res?.brand?.nameAr,
        nameFr: res?.brand?.nameFr,
      },
      media: transformMediaArticle(res?.media),
      pcbsArray: transformPcbObjectArticle(res?.pcbsObject),
      stocks: transformArticleStocks(res?.stocks),
      dealEndDate: res?.dealEndDate,
      dealStartDate: res?.dealStartDate,
      prices: res?.prices?.map(transformItemPricesArticle) ?? [],
      hasSupport: res?.hasSupport,
      type: res?.type,
      dealRemainingStock: res?.dealRemainingStock,
      catalogs: transformChildrenCategoryResponse(res?.catalogs ?? []) ?? [],
      sageCatalogs:
        res?.sageCatalogs?.map((sageCatalog) => {
          return {
            label: sageCatalog?.label,
            level: sageCatalog?.level,
            id: sageCatalog?.id,
          };
        }) ?? [],
    };
}

export const transformArticlesResponse = (responseData: ArticlesApiResponse): ArticlesResponse => {
  return {
    data: responseData?.results?.map((res) => transformItemArticleResponse(res)) as Article[],
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: responseData?.previousPage ? responseData.previousPage + 1 : 1,
  };
};
export const transformPricesObject = (pricesObject?: PricesObjectApi): PricesObject | undefined => {
  if (!pricesObject) return undefined;

  const transformedPrices: PricesObject = {};

  Object.keys(pricesObject).forEach((key) => {
    if (pricesObject[key as keyof PricesObjectApi] !== undefined) {
      transformedPrices[key as keyof PricesObject] = pricesObject[key as keyof PricesObjectApi];
    }
  });
  if (Object.keys(transformedPrices).length === 0) {
    return undefined;
  }
  return transformedPrices;
};
export const transformPackingUnitsResponse = (
  response: IPackingUnitsApiResponse[],
): IPackingUnitResponse[] => {
  return response.map((res) => {
    return {
      id: res?.id,
      type: res?.type,
      label: res?.labelFr,
    };
  });
};
export const transformGetArticleByIdResponse = (
  response: IArticleByIdResponseApi,
): IArticleByIdResponse => {
  const catalogWithLevelZero = response?.catalogs?.find(
    (catalog) => catalog?.level?.toString() === '0',
  );
  const catalogWithLevelOne = response?.catalogs?.find(
    (catalog) => catalog?.level?.toString() === '1',
  );

  return {
    id: response?.id,
    type: response?.type,
    descriptionFr: response?.descriptionFr,
    descriptionAr: response?.descriptionAr,
    ean13Code: response?.ean13Code,
    sageLabel: response?.sageLabel,
    sageReference: response?.sageReference,
    status: onChangeStatusFilterStringToBoolean(response?.status) ?? false,
    nameFr: response?.nameFr,
    nameAr: response?.nameAr,
    childCatalogId: catalogWithLevelOne
      ? {
          id: catalogWithLevelOne?.id,
          nameAr: catalogWithLevelOne?.nameAr,
          nameFr: catalogWithLevelOne?.nameFr,
          level: catalogWithLevelOne?.level,
          status: onChangeStatusFilterStringToBoolean(catalogWithLevelOne?.status) ?? false,
          deletedAt: catalogWithLevelOne?.deletedAt,
        }
      : undefined,

    catalogs: catalogWithLevelZero
      ? [
          {
            id: catalogWithLevelZero?.id,
            nameAr: catalogWithLevelZero?.nameAr,
            nameFr: catalogWithLevelZero?.nameFr,
            level: catalogWithLevelZero?.level,
            status: onChangeStatusFilterStringToBoolean(catalogWithLevelZero?.status) ?? false,
            deletedAt: catalogWithLevelZero?.deletedAt,
          },
        ]
      : [],
    brand: {
      id: response?.brand?.id,
      nameAr: response?.brand?.nameAr,
      nameFr: response?.brand?.nameFr,
      media: response?.brand?.media?.map((media) => {
        return {
          id: media?.id,
          mediaType: media?.mediaType,
          path: media?.path,
          uuid: media?.uuid,
          url: media?.url,
        };
      }),
    },
    prices: response?.prices?.map(transformItemPricesArticle) ?? [],
    media: response?.media?.map((media) => {
      return {
        id: media?.id,
        url: media?.url,
        mediaType: media?.mediaType,
        path: media?.path,
        uuid: media?.uuid,
        articleMediaTag: media?.articleMediaTag,
        isDefaultArticleMedia: media?.isDefaultArticleMedia,
      };
    }),

    hasSupport: response?.hasSupport,
    sageCatalogs:
      response?.sageCatalogs?.map((sageCatalog) => {
        return {
          id: sageCatalog?.id,
          level: sageCatalog?.level,
          label: sageCatalog?.label,
        };
      }) ?? [],
  };
};
export const transformGetArticlePcbResponse = (response: IPcbResponseApi[]): IPcbResponse[] => {
  return response.map((res) => {
    return {
      pcb: res?.pcb,
      packingType: res?.packingType,
      packingUnit: transformPackingUnitsResponse([res?.packingUnit])?.[0] ?? {},
      fromPackingUnitLabel: res?.fromPackingUnit?.labelFr ?? '',
    };
  });
};
export const transformGetStockArticle = (
  response: IStockArticleResponseApi,
): IStockArticleResponse => {
  return {
    data: response.results?.map((res, index) => {
      return {
        id: index,
        currentStock: res?.currentStock,
        realStock: res?.realStock,
        warehouse: res?.warehouse,
        warehouseId: res?.warehouseId,
      };
    }),
    total: response?.total,
    totalPerPage: response?.totalPerPage,
    nextPage: response?.nextPage,
    previousPage: response?.previousPage,
    lastPage: response?.lastPage,
    currentPage: response?.currentPage,
  };
};
export const transformArticlePricesResponse = (response: IArticlePriceApi[]): IArticlePrice[] => {
  return response?.map((res) => {
    return {
      packingPrices: res?.packingPrices?.map((price) => {
        return {
          type: price?.type,
          amount: price?.amount,
        };
      }),
      clientCategory: {
        id: res?.clientCategory?.id,
        nameFr: res?.clientCategory?.nameFr,
        nameAr: res?.clientCategory?.nameAr,
        status: onChangeStatusFilterStringToBoolean(res?.clientCategory?.status) ?? false,
        minimumOrderPrice: res?.clientCategory?.minimumOrderPrice,
      },
      maxOrderQty: res?.maxOrderQty,
      maxOrderPackingType: res?.maxOrderPackingType,
      minOrderPackingType: res?.minOrderPackingType,
      minOrderQty: res?.minOrderQty,
    };
  });
};
