import { CONFIG, GlobalVariables } from 'config/constant';
import { clearLocalStorage } from 'utils/services/storage.service';
import { isString } from 'utils/validators/input.validators';
import { AES, enc } from 'crypto-js';

/**
 * Save a data to local storage by a key
 * @param key string,
 * @param data unknown
 * @returns void
 */
export const persistData = <T>(key: string, data: T) => {
  const dataString: string = isString(data) ? String(data) : JSON.stringify(data);
  localStorage.setItem(key, encryptData(dataString));
};
export const encryptData = (data: string) => {
  return AES.encrypt(data === null ? '' : data, CONFIG.AUTH_SECURITY_KEY).toString();
};
/**
 * Decrypt data
 * @param encryptedData string
 * @returns string
 */
export const deCryptData = (encryptedData: string) => {
  try {
    return AES.decrypt(
      encryptedData === null ? '' : encryptedData,
      CONFIG.AUTH_SECURITY_KEY,
    ).toString(enc.Utf8);
  } catch (error) {
    clearLocalStorage();
  }
};

// Extract data from local storage buy a key
/**
 * return a data from local storage by a key
 * if parse is true, data will be parsed to JSON
 * @param key string
 * @param parse boolean ,
 * @returns string | object
 */
export const getPersistData = (key: string, parse: boolean) => {
  return parse
    ? JSON.parse(deCryptData(localStorage.getItem(key) ?? '') || GlobalVariables.EmptyJsonString)
    : deCryptData(localStorage.getItem(key) || GlobalVariables.EmptyString);
};

// Remove data from the local storage buy a key
export const removePersistData = (key: string) => {
  localStorage.removeItem(key);
};
