export const article = {
  reference: 'Référence',
  name: 'Nom',
  stock: 'Stock',
  category: 'Catégorie',
  lastupdate: 'Date de dernière mise à jour',
  status: 'Status',
  active: 'Actif',
  inactive: 'Inactif',
  no_articles_yet: "Vous n'avez pas encore d'articles",
  filterBy: 'filtrer par',
  catalog: 'Catégorie',
  ean_too_short: 'Code EAN trop court',
  search: 'chercher le produit',
};
