import { AlertObject } from 'types/interfaces/AlertObject';

export const OFFLINE_DIALOG: AlertObject = {
  title: 'common.offline.title',
  active: true,
  message: 'common.offline.message',
  type: 'error',
  color: 'error',
};

export const ONLINE_DIALOG: AlertObject = {
  title: 'common.online.title',
  active: true,
  autoHidden: 3000,
  message: 'common.online.message',
  type: 'success',
  color: 'success',
};
