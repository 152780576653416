import {
  ICategories,
  ICategoryApiResponse,
  ICategoryByIdResponse,
  ICategoryResponse,
  ICategoryResultsApiResponse,
  Media,
} from 'types/models/Category/Category.schema';
import { GlobalVariables } from 'config/constant';
import { transformCategoriesLogisticsDataResponse } from 'redux/api/CategoriesLogistics/categoriesLogistics.transform';

export const transformCategoryResponse = (response: ICategoryApiResponse): ICategoryResponse => {
  return {
    totalPerPage: response.totalPerPage,
    total: response.total,
    lastPage: response.lastPage,
    nextPage: response.nextPage,
    previousPage: response.previousPage,
    data: transformChildrenCategoryResponse(response.results),
  };
};

export const transformCatagoriesMediaResponse = (data: Media[]) => {
  if (!data) return [];
  return data?.map((media) => {
    return {
      id: media.id,
      mediaType: media.mediaType,
      url: media.url,
      catalogMediaTag: media.catalogMediaTag,
    };
  });
};
export const transformChildrenCategoryResponse = (
  data: ICategoryResultsApiResponse[],
): ICategories[] => {
  return data?.map((category) => {
    return {
      id: category.id,
      nameFr: category?.nameFr,
      nameAr: category?.nameAr,
      level: category?.level,
      name: category?.name,
      status: category.status === GlobalVariables.STATUS.ACTIVE,
      media: category?.media ? transformCatagoriesMediaResponse(category.media) : [],
      salesLines: category?.salesLines
        ? category.salesLines.map(transformCategoriesLogisticsDataResponse)
        : [],
      children: category?.children ? transformChildrenCategoryResponse(category.children) : [],
      parent: category?.parent ? transformCategoryByIDResponse(category.parent).data : undefined,
      sageId: category?.sageId,
    };
  });
};

export const transformCategoryByIDResponse = (
  response: ICategoryResultsApiResponse,
): ICategoryByIdResponse => {
  return {
    data: {
      id: response?.id,
      nameFr: response?.nameFr,
      nameAr: response?.nameAr,
      name: response?.name,
      level: response?.level,
      status: response.status === GlobalVariables.STATUS.ACTIVE,
      salesLines: response?.salesLines
        ? response?.salesLines.map(transformCategoriesLogisticsDataResponse)
        : [],

      media: transformCatagoriesMediaResponse(response.media),
      children: response?.children ? transformChildrenCategoryResponse(response.children) : [],
      parent: response?.parent ? transformCategoryByIDResponse(response.parent).data : undefined,
      sageId: response?.sageId,
    },
  };
};
