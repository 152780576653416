import { useRef, useEffect } from 'react';

/**
 * @description This hook is used to check if the component is rendered for the first time
 */
function useFirstRender() {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    isFirstRenderRef.current = false;
  }, []);

  return isFirstRenderRef.current;
}

export default useFirstRender;
