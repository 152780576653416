import { useAppDispatch } from 'redux/hooks';
import { clientClassApi } from 'redux/api/clientClass/clientClassApi';
import { articlesApi } from 'redux/api/article/articlesApi';
import { categoriesApi } from 'redux/api/category/category.api';
import { clientCategoryApi } from 'redux/api/clientCategory/clientCategoryApi';
import { clientApi } from 'redux/api/client/clientApi';
import { usersApi } from 'redux/api/users/users.api';
import { profileAccessApi } from 'redux/api/profileAccess/profileAccess';
import { warehouseApi } from 'redux/api/warehouse/warehouseApi';
import { zonesApi } from 'redux/api/zones/zones.api';
import { sectorApi } from 'redux/api/sectors/sectors.api';
import { governoratesApi } from 'redux/api/governorate/governorates.api';
import { abilitiesApi } from 'redux/api/Abilities/Abilities.api';
import { adsApi } from 'redux/api/ads/ads.api';
import { brandsApi } from 'redux/api/Brands/brands.api';
import { notificationsApi } from 'redux/api/Notifications/notification.api';
import { promoApi } from 'redux/api/promo/promo.api';
import { dealsApi } from 'redux/api/deals/dealsApi';
import { ordersApi } from 'redux/api/ordres/orders.api';
import { authApi } from 'redux/api/auth/login/loginApi';
import { homeSectionApi } from 'redux/api/homeSections/homeSection.api';
import { categoriesLogisticsApi } from 'redux/api/CategoriesLogistics/categoriesLogistics.api';

export default function useClearApiCache() {
  const dispatch = useAppDispatch();
  const onClearApiCachedData = () => {
    dispatch(articlesApi.util.resetApiState());
    dispatch(categoriesApi.util.resetApiState());
    dispatch(clientClassApi.util.resetApiState());
    dispatch(clientCategoryApi.util.resetApiState());
    dispatch(clientApi.util.resetApiState());
    dispatch(usersApi.util.resetApiState());
    dispatch(profileAccessApi.util.resetApiState());
    dispatch(warehouseApi.util.resetApiState());
    dispatch(zonesApi.util.resetApiState());
    dispatch(sectorApi.util.resetApiState());
    dispatch(governoratesApi.util.resetApiState());
    dispatch(abilitiesApi.util.resetApiState());
    dispatch(adsApi.util.resetApiState());
    dispatch(brandsApi.util.resetApiState());
    dispatch(notificationsApi.util.resetApiState());
    dispatch(promoApi.util.resetApiState());
    dispatch(dealsApi.util.resetApiState());
    dispatch(ordersApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
    dispatch(homeSectionApi.util.resetApiState());
    dispatch(categoriesLogisticsApi.util.resetApiState());
  };
  return { onClearApiCachedData };
}
