import { Box } from '@mui/material';
import { GlobalVariables } from 'config/constant';
import { useTranslation } from 'react-i18next';
import { TextFieldStyle, TypographyStyle } from './CustomTextAreaField.style';
import { CustomTextAreaFieldProps } from './CustomTextAreaField.type';
function CustomTextAreaField({
  label,
  inputType,
  value,
  error,
  required,
  valueOptions,
  placeholder,
  size,
  onChange,
}: CustomTextAreaFieldProps) {
  const { t } = useTranslation();

  return (
    <Box>
      {label && <TypographyStyle>{t(label)}</TypographyStyle>}
      <TextFieldStyle
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          maxLength: valueOptions?.maxLength,
        }}
        multiline
        onChange={onChange}
        type={inputType}
        value={value}
        error={!!error}
        helperText={t(error?.message ?? GlobalVariables.EmptyString)}
        fullWidth
        required={required}
        placeholder={placeholder && t(placeholder)}
        size={size}
      />
    </Box>
  );
}

export default CustomTextAreaField;
