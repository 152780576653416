import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from 'redux/api/BaseQueryConfig';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import {
  injectFilterStatus,
  injectFilterWarehouseId,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import {
  transformCategoriesLogisticsDataResponse,
  transformCategoriesLogisticsResponse,
} from 'redux/api/CategoriesLogistics/categoriesLogistics.transform';
import {
  ICategoriesLogisticsApiResponse,
  ICategoriesLogisticsApiResults,
} from 'types/models/CategoriesLogistics/categoriesLogistics.schema';
import { categoriesApi } from 'redux/api/category/category.api';

export const categoriesLogisticsApi = createApi({
  reducerPath: 'categoriesLogisticsApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['logisticsApi'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getCategoriesLogistics: builder.query({
      query: (params: {
        paginator?: Paginator;
        warehouseId?: number | null;
        status: boolean | null;
      }) =>
        injectParams(ENDPOINTS.CATEGORIES_LOGISTICS.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterWarehouseId(params.warehouseId),
          ...injectFilterStatus(params.status),
        }),
      transformResponse: (response: ICategoriesLogisticsApiResponse) =>
        transformCategoriesLogisticsResponse(response),
      providesTags: ['logisticsApi'],
    }),
    deleteCategoryLogistics: builder.mutation({
      query: (id) => ({
        url: ENDPOINTS.CATEGORIES_LOGISTICS.DELETE(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['logisticsApi'],
    }),
    addCategoryLogistics: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.CATEGORIES_LOGISTICS.ADD,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['logisticsApi'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(categoriesApi.util.resetApiState());
      },
    }),
    updateCategoryLogistics: builder.mutation({
      query: ({ id, body }) => ({
        url: ENDPOINTS.CATEGORIES_LOGISTICS.UPDATE(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['logisticsApi'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(categoriesApi.util.resetApiState());
      },
    }),
    getDetailsCategoryLogistics: builder.query({
      query: (id) => ENDPOINTS.CATEGORIES_LOGISTICS.GET_DETAILS(id),
      transformResponse: (response: ICategoriesLogisticsApiResults) =>
        transformCategoriesLogisticsDataResponse(response),
      providesTags: ['logisticsApi'],
    }),
  }),
});
export const {
  useGetCategoriesLogisticsQuery,
  useDeleteCategoryLogisticsMutation,
  useAddCategoryLogisticsMutation,
  useUpdateCategoryLogisticsMutation,
  useGetDetailsCategoryLogisticsQuery,
} = categoriesLogisticsApi;
