import { IAbilities, IAbilitiesApi } from 'types/models/Abilities/Abilities';

export const transformAbilitiesResponse = (abilities: IAbilitiesApi[]): IAbilities[] => {
  return abilities.map((ability) => {
    return {
      id: ability?.id,
      subjectName: ability?.subjectName,
      action: ability?.action,
      labelFr: ability?.labelFr,
      labelAr: ability?.labelAr,
    };
  });
};
