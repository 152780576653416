export const common = {
  app_name: 'Pokemon App',
  not_found_page_title: '404 Page not found',

  refetch: 'Refetch',
  fetching: 'Fetching',
  false: 'False',
  this_button_clicked_times: 'This button is clicked {{buttonClickedCount}} times',
  this_button_clicked_time: 'This button is clicked {{buttonClickedCount}} time',
  this_data_is_refetched_times: 'This data is refetched {{refetchCount}} times',
  this_data_is_refetched_time: 'This data is refetched {{refetchCount}} time',
  no_data_yet: 'Aucune donnée pour le moment',
  home: 'Home',
  settings: 'Settings',
};
