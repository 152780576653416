import { Alert, Snackbar, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MessageStyle } from './CustomSnackbar.style';
import { CustomSnackbarProps } from './CustomSnackbar.type';

function CustomSnackbar({ alert, onClose }: CustomSnackbarProps) {
  const { t } = useTranslation();
  return (
    <Snackbar
      open={alert.active}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={alert.autoHidden}
      TransitionComponent={Zoom}
    >
      <Alert severity={alert.type} variant={'filled'} onClose={onClose} sx={{ width: '100%' }}>
        <MessageStyle>{t(alert.title || 'error')}</MessageStyle>
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
