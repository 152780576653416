import { IWarehouseAPIResponse, IWarehouseResponse } from 'types/models/Warehouse/Warehouse';

export const transformWarehouseResponse = (
  responseData: IWarehouseAPIResponse,
): IWarehouseResponse => {
  return {
    data: responseData.results.map((res) => {
      return {
        id: res.id,
        nameAr: res.nameAr,
        nameFr: res.nameFr,
        surfaceArea: res.surfaceArea,
        manager: res.manager,
        taxNumber: res.taxNumber,
        type: res.type,
        zoneId: res.zoneId,
        class: res.class,
        deletedAt: res.deletedAt,
        status: res.status,
      };
    }),
    lastPage: responseData.lastPage,
    total: responseData.total,
    totalPerPage: responseData.totalPerPage,
    nextPage: responseData.nextPage,
    previousPage: responseData.previousPage,
    currentPage: 1,
  };
};
