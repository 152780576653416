import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { CONFIG } from 'config/config';
import { RootState } from 'redux/store';
import { Mutex } from 'async-mutex';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { handleRefreshToken } from 'utils/services/api.service';
import { setLogOut } from 'redux/slices/Auth/authSlice';
import { HttpStatusCodeEnum } from 'types/interfaces/ErrorApi';
const mutex = new Mutex();
export const baseQuery: FetchBaseQueryArgs = {
  baseUrl: CONFIG.BASE_URL_API,

  prepareHeaders: (headers: Headers, { getState }) => {
    const token = (getState() as RootState).authReducer.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
};
const fetchWithBaseQuery = fetchBaseQuery(baseQuery);
export const baseQueryConfig: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await fetchWithBaseQuery(args, api, extraOptions);

  const rememberMe = (api.getState() as RootState).authReducer.rememberMe;
  const accessToken = (api.getState() as RootState).authReducer.token;
  if (
    accessToken &&
    accessToken.length > 0 &&
    result.error &&
    result.error.status === HttpStatusCodeEnum.UNAUTHORIZED &&
    rememberMe
  ) {
    if (!mutex.isLocked()) {
      result = await handleRefreshToken(result, api, extraOptions, args, mutex);
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await fetchWithBaseQuery(args, api, extraOptions);
    }
  } else if (
    accessToken &&
    accessToken.length > 0 &&
    result.error &&
    result.error.status === HttpStatusCodeEnum.UNAUTHORIZED &&
    !rememberMe
  ) {
    api.dispatch(setLogOut());
  }

  return result;
};
