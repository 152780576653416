/** @define injectParams function to inject parameters to url
 * @param url string
 * @param object Record<string, unknown> | Record<string, unknown>[]
 * @returns string
 * @example injectParams('/api/v1/clients', { id: 1, name: 'John' }) => '/api/v1/clients?id=1&name=John'
 * @example injectParams('/api/v1/clients', {client:[1,2]}) => '/api/v1/clients?client=1&client=2'
 * */
export function injectParams(
  url: string,
  object: Record<string, unknown> | Record<string, unknown>[],
): string {
  const processValue = (key: string, value: unknown): string => {
    if (typeof value === 'object' && value !== null) {
      return Object.entries(value)
        .map(([innerKey, innerValue]) => `${innerKey}=${innerValue}`)
        .join('&');
    } else {
      return `${key}=${value}`;
    }
  };

  const processObject = (obj: Record<string, unknown>): string =>
    Object.entries(obj)
      .filter(([_, value]) => Boolean(value))
      .map(([key, value]) => {
        return Array.isArray(value)
          ? value.map((item) => processValue(key, item)).join('&')
          : processValue(key, value);
      })
      .join('&');

  if (Array.isArray(object)) {
    return object.map((item) => processObject(item)).join('&');
  } else {
    return processObject(object) ? `${url}?${processObject(object)}` : url;
  }
}
