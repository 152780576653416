import _ from 'lodash';
import lodash from 'lodash';
import { OptionType } from 'types/interfaces/Input.type';
import { arrayIsEmpty } from 'utils/helpers/array.helpers';
import { HttpStatusCodeEnum, IErrorApi } from 'types/interfaces/ErrorApi';
export const isObjectExist = (object: unknown) => {
  return typeof object !== 'undefined';
};

// eslint-disable-next-line
export const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((item) => toCamelCase(item));
  }

  if (data !== null && typeof data === 'object') {
    // eslint-disable-next-line
    const camelData: any = {};

    lodash.forEach(data, (value, key) => {
      camelData[lodash.camelCase(key)] = toCamelCase(value);
    });

    return camelData;
  }

  return data;
};
export const isAllObjectEmpty = <T>(object: T): boolean => {
  return Object.values(object).every((item) => item === null || item === undefined || item === '');
};
export const createOptionsForSelectors = <T extends { id: number; nameFr: string }[]>(
  data: T | undefined,
) => {
  if (!data) return [];
  return data.map((item) => ({
    label: item?.nameFr,
    value: item?.id,
  }));
};
export function isObjectNotEmpty(obj: object | undefined): boolean {
  if (!obj) return false;
  return Object.getOwnPropertyNames(obj).length > 0;
}

/**
 * Check if object has null value or not ,include that object has array of objects
 * @param object , T
 * @param doNotUseRecursively boolean , if true , it will not check if array of objects has null value or not , just check if array is empty or not
 * @returns boolean
 */
export const objectHasNullValue = <T>({
  object,
  doNotUseRecursively,
}: {
  object: T;
  doNotUseRecursively?: boolean;
}): boolean => {
  if (!object) return true;
  if (Object.keys(object).length === 0) return true;
  const results = Object.keys(object).map((key) => {
    const item = object[key as keyof object] as unknown;

    if (Array.isArray(item)) {
      // should return true if all items are not null
      return doNotUseRecursively
        ? arrayIsEmpty(item)
        : item.some((i) => objectHasNullValue({ object: i, doNotUseRecursively }));
    } else {
      return item === null || item === undefined || item === '';
    }
  });

  return results.some((result) => result);
};

export function isAllTrue(field: OptionType<number>[] | null) {
  return field && field[0]?.value?.toString() === '0';
}
export function hasDuplicateObjects(arr: unknown[]): boolean {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (_.isEqual(arr[i], arr[j])) {
        return true; // Found duplicate objects
      }
    }
  }

  return false; // No duplicate objects found
}
export const redirectTabBaseOnResponseApi = ({
  responseApi,
  arrayToCheck,
}: {
  arrayToCheck: { keys: string[]; callbackFn: () => void }[];
  responseApi: IErrorApi | null;
}) => {
  let validation = true;
  if (
    responseApi &&
    responseApi.status === HttpStatusCodeEnum.UNPROCESSABLE_ENTITY &&
    responseApi.data.errors
  ) {
    const keysFromResponse = Object.keys(responseApi.data.errors);
    arrayToCheck.forEach((item) => {
      if (keysFromResponse.some((itemRes) => item.keys.includes(itemRes))) {
        item.callbackFn();
        validation = false;
      }
    });
  }
  return validation;
};
