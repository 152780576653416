import { FormHelperText, styled, Theme, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import Input from 'theme/overrides/Input';

export const TypographyStyle = styled(Typography)({
  marginBottom: 10,
  fontSize: '1rem',
});

export const FormHelperTextStyle = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const inputStyle = (error: FieldError | undefined, theme: Theme) => {
  const inputTheme = Input(theme);

  const { ['& .MuiOutlinedInput-notchedOutline']: _, ...newRoot } =
    inputTheme.MuiOutlinedInput.styleOverrides.root;
  return Object.assign(
    {
      width: '100%',
      borderColor: error && theme.palette.error.main,
    },
    newRoot,
  );
};

export const buttonStyle = (theme: Theme) => {
  return {
    borderRadius: '8px',
    borderColor: 'black',
  };
};
