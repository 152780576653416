import { Theme, alpha } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

export default function Calendar(theme: Theme) {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          border: `2px solid ${theme.palette.grey[300]}`,
          minHeight: 375,
          width: 340,
        },
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          minHeight: '375px !important',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          padding: '0px 20px !important',
          margin: '0px !important',
        },
        weekContainer: {
          margin: 0,
          paddingInline: 20,
          paddingTop: 5,
        },
        weekDayLabel: {
          marginTop: 10,
          fontFamily: GlobalFonts.FONT_PRIMARY_500,
          fontSize: '0.9375rem',
          lineHeight: '1.4375rem',
          textTransform: 'capitalize',
          color: theme.palette.grey[500],
          fontWeight: 500,
          width: 60,
          height: 40,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: 60,
          height: 40,
          borderRadius: '5px',
          fontFamily: GlobalFonts.FONT_PRIMARY_500,
          fontSize: '0.9375rem',
          lineHeight: '1.4375rem',
          fontWeight: 500,
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main} !important`,
            backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
            fontWeight: 500,
          },
        },
        today: {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          border: 'none !important',
        },
      },
    },
  };
}
