import { Box, styled } from '@mui/material';
import { ReactComponent as CertificateIcon } from 'assets/icons/certification.svg';

export const RootStyle = styled(Box)({
  height: 63,
  width: 68,
  padding: '0px 5px 2px 0px',
  position: 'relative',
});

export const NotificationBoxStyle = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.grey[200],
  borderRadius: '50%',
  height: 44,
  width: 44,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
    boxShadow: `0px 13px 45px ${theme.palette.grey[300]}`,
  },
}));

export const CertificateIconStyle = styled(CertificateIcon)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 'calc(100% - 16px)',
  g: {
    g: {
      fill: theme.palette.primary.main,
      '& #check': {
        fill: theme.palette.background.default,
      },
    },
    circle: {
      fill: theme.palette.grey[50],
    },
  },
}));
