import { Card, CardContent, styled } from '@mui/material';

export const RootStyle = styled(Card)(({ theme }) => ({
  minHeight: '50%',
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '98%',
  },
  [theme.breakpoints.up('sm')]: {
    minWidth: 600,
  },
}));

export const ContentStyle = styled(CardContent)(() => ({
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
}));
