import { Box, Skeleton } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import NavSectionItem from './NavSectionItem/NavSectionItem';
import { StyledList } from './NavSection.style';
import { useAppSelector } from 'redux/hooks';

import NavItem from 'types/interfaces/NavItem';
import {
  GetIsErrorRouteConfig,
  GetIsLoadingRouteConfig,
  GetRouteConfigStore,
} from 'redux/slices/app/appSlice';
import { generateArray } from 'utils/helpers/array.helpers';
import { useNavigate } from 'react-router';
import { CustomFallbackError } from 'components/CustomFallbackError/CustomFallbackError';

export default function NavSection({ ...other }) {
  const { pathname } = useLocation();

  const transformedNavConfig: NavItem[] = useAppSelector(GetRouteConfigStore);
  const navigate = useNavigate();
  const checkIsActive = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const isLoadingRouteConfig = useAppSelector(GetIsLoadingRouteConfig);
  const isErrorRouteConfig = useAppSelector(GetIsErrorRouteConfig);
  const reloadPage = () => navigate(0);
  return (
    <Box {...other}>
      <StyledList disablePadding>
        {isLoadingRouteConfig ? (
          generateArray(7).map((item) => <Skeleton key={item} height={40} sx={{ marginY: 3 }} />)
        ) : isErrorRouteConfig ? (
          <CustomFallbackError fallbackFn={reloadPage} />
        ) : (
          <>
            {transformedNavConfig.map(
              (item) =>
                !item.notToShow && (
                  <Box key={item.title}>
                    <NavSectionItem item={item} checkIsActive={checkIsActive} />
                  </Box>
                ),
            )}
          </>
        )}
      </StyledList>
    </Box>
  );
}
