import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GlobalVariables } from 'config/constant';

type ActiveType = {
  active: string;
  color?: string;
};

export const ITEM_BORDER_RADIUS = '53px';
export const LIST_ITEM_BORDER_RADIUS = '24px';

export const ItemStyle = styled((props: Record<string, unknown> & ActiveType) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} />
))((props) => ({ theme }) => ({
  padding: 10,
  marginTop: 2,
  paddingInline: 15,
  cursor: 'pointer',
  color: props.active === GlobalVariables.TRUE ? theme.palette.grey[800] : theme.palette.grey[500],
  backgroundColor: props.active === GlobalVariables.TRUE ? theme.palette.grey[200] : 'transparent',
  fontSize: '0.938rem',
  textDecoration: 'none',
  textTransform: 'none',
  fontStyle: 'italic',
  borderRadius: ITEM_BORDER_RADIUS,
  fontWeight: props.active === GlobalVariables.TRUE ? 800 : 400, // Change the fontWeight value here
  '&:hover': {
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.grey[200],
  },
}));
