export const APP_BAR_MOBILE = 200;
export const APP_BAR_TABLET = 120;
export const APP_BAR_DESKTOP = 120;
export const StylesConstants = {
  DashboardRootStyleBigScreenMinWidth: 1600,
  DashboardRootStyleMinWidth: '1150px',
  DashboardRootStyleDisplay: 'flex',
  DashboardRootStyleMinHeight: '100%',
  DashboardRootStyleOverFlow: 'auto',
};
