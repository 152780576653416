import { ErrorOperationEnum } from 'config/enums/common.enums';
import { BackendEntityEnum } from 'types/interfaces/sharedType.type';

export interface IErrorApi {
  status: number;
  data: {
    message: string;
    errorCode: string;
    errors?: Record<string, string | string[]>;
    data: {
      id: number;
      articleId?: number;
      maxOrderQty?: number;
      clientId?: number;
      minOrderPrice?: number;
      orderTotal?: number;
      overlappingDeals?: IOverlappingDealsError[];
      overlappingDiscounts?: IOverlappingDiscountsError[];
      overlappingPromoCodes?: IOverlappingPromoCodesError[];
      operation?: ErrorOperationEnum;
      entity?: BackendEntityEnum;
      [key: string]: unknown;
    };
  };
}

export interface IOverlappingDealsError {
  id: number;
  name: string;
  articles: {
    id: number;
    name: string;
    nameAr: string;
    nameFr: string;
  }[];
}
export interface IOverlappingPromoCodesError {
  code: string;
  appliesTo: string;
  articles: {
    id: number;
    name: string;
    nameAr: string;
    nameFr: string;
  }[];
}
export interface IOverlappingDiscountsError {
  id: number;
  name: string;
  articles: {
    id: number;
    name: string;
    nameAr: string;
    nameFr: string;
  }[];
}
export enum HttpStatusCodeEnum {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  NETWORK_CONNECT_TIMEOUT_ERROR = 599,
  UNPROCESSABLE_ENTITY = 422,
}
