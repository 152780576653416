export enum BORouteIdEnum {
  Root = '',
  Any = '*',
  id = ':id',
  Home = 'home',
  SignIn = 'sign-in',
  BO = 'bo',
  Dashboard = 'dashboard',
  Teams = 'teams',
  Users = 'users',
  ProfileAccess = 'profile-access',
  Clients = 'clients',
  ClientsClass = 'client-class',
  ClientsCategory = 'client-category',
  Order = 'commandes',
  Products = 'products',
  Categories = 'categories',
  WarehousePath = 'warehouse',
  Schedule = 'Schedule',
  Ad = 'ad',
  Notification = 'notification',
  Deals = 'deals',
  Promo = 'promo',
  ManageLP = 'manage-lp',
  Auth = 'auth',
  Add = 'add',
  Zone = 'zone',
  Sector = 'sectors',
  Discount = 'discount',
  Positioning = 'positioning',
  CategoriesLogistic = 'categories-logistic',
}
