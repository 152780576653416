export const common = {
  app_name: 'Ekmandi - App',
  not_found_page_title: '404 Page not found',
  upload_images: 'Télécharger des images',
  search: 'Rechercher',
  back: 'Retour',
  formulaire_incomplete: 'Veuillez remplir tous le formulaire',
  home: 'Home',
  status: 'Statut',
  settings: 'Settings',
  min_value: 'Valeur minimale doit être  supérieure à {{value}}',
  max_value: 'Valeur maximale doit être  inférieure à {{value}}',
  add: 'Ajouter',
  edit: 'Éditer',
  contact: 'Contacter',
  view: 'Consulter',
  delete: 'Supprimer',
  share: 'Partager',
  duplicate: 'Dupliquer',
  cancel: 'Annuler',
  remove: 'Enlever',
  see: 'Voir',
  accept: 'Accepter',
  refuse: 'Refuser',
  discuss: 'Discuter',
  error_fallback:
    "Une erreur est survenue . <newLine/> <newLine/> Veuillez réessayer d'actualiser la page",
  reload: 'Actualiser',
  close: 'Fermer',
  refund: 'Rembourser',
  invoice: 'Facture',

  required: 'Ce champ est requis.',
  optional: 'Optionnel',

  old: 'Ancien',
  new: 'Nouveau',

  send: 'Envoi',
  send_by_mail: 'Envoi par mail',
  send_by_sms: 'Envoi par sms',
  by_mail: 'Par e-mail',
  by_sms: 'Par SMS',
  by_mail_and_sms: 'Par e-mail et SMS',
  export: 'Exporter',
  export_pdf: 'Exporter en PDF',
  export_csv: 'Exporter en CSV',

  no_results: 'Pas de resultats',

  yes: 'Oui',
  no: 'Non',
  save: 'Enregistrer',
  save_and_validate: 'Enregistrer et valider',
  validate: 'Valider',
  calculate: 'Calculer',
  confirm: 'Confirmer',
  relance_client: 'Relancer le client',
  ok: 'OK',
  min: 'min',

  HT: 'HT',
  number_of_months: 'Nbr de mois',
  TTC: 'TTC',

  turnover: { short: 'C.A', long: "Chiffre d'affaire" },
  months: '{{number}} mois',

  grouped_action: 'Action groupée',

  elements_per_page: 'Éléments par page',
  of: 'sur',
  displayed: 'affichés',
  total_price: 'Montant total',
  net_price: 'Montant net',
  excluding_service_fees: 'hors frais de service',
  excluding_service_fees_info: 'Soit {{price}} € net',

  price_and_payment: 'Montant et paiement',

  auth: {
    login: 'Login',
    logout: 'Déconnexion',
  },

  pay: {
    pay: 'Payer',
    pay_with_card: 'Payer avec une carte',
    free: 'Gratuit',
    starting_from_hour: 'à partir de {{price}} {{currency}}/h',
  },

  something_went_wrong: "Quelque chose s'est mal passé",

  done: 'Terminé',

  offline: {
    title: 'You are currently offline.',
    message: 'Veuillez vérifier votre connexion internet.',
  },

  online: {
    title: 'Connected',
  },

  copy_link: 'Copier le lien',
  copied_to_clipboard: 'Lien copié',

  actions: 'Actions',
  select: 'Sélectionner',
  noData: 'Aucune donnée',
  enable: 'Activé',
  disable: 'Désactivé',
  enableAction: 'Activer',
  disableAction: 'Désactiver',
  no_data_yet: 'Aucune donnée pour le moment',
  no_white_space: "Pas d'espace blanc",
  details: 'Détails',
  all: 'Tous',
  manage: 'Gérer',
  alt: {
    logo: 'tejer',
  },
  config_mismatch: "Il y a un conflit d'activation pour cette entité",
  localization: 'Localisation',
  distance: 'Distance',
};
