import { Stack } from '@mui/material';
import FormCard from 'components/FormCard/FormCard';
import LoginForm from 'features/Auth/LoginForm/LoginForm';
import { RootStyle } from './Login.style';
function Login() {
  return (
    <RootStyle>
      <Stack
        justifyContent="center"
        alignItems="center"
        height={'100%'}
        spacing={{ md: '10px', lg: '15px' }}
      >
        {/* The Logo will be changed. */}
        {/* <LogoSection /> */}
        <FormCard sx={{ width: '20%' }}>
          <LoginForm />
        </FormCard>
      </Stack>
    </RootStyle>
  );
}
export default Login;
