type ResponsiveQuery = 'up' | 'down' | 'only' | 'between';

export default ResponsiveQuery;

export enum ResponsiveQueryEnum {
  UP = 'up',
  DOWN = 'down',
  ONLY = 'only',
  BETWEEN = 'between',
}
