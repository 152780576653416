export const auth = {
  connection: 'Login',
  logout: 'Logout',
  email: 'Email',
  password: 'Password',
  invalid_email: 'Invalid email',
  password_must_be: `Your password must contain at least 6 characters.`,
  stay_connected: 'Stay connected',
  forget_password: 'Forget password ?',
  Privacy_Policy: 'Privacy Policy. GTC. Language',
  you_have_no_account: `You have no account ?`,
  register_me: 'Register me !',
  french: 'French',
  english: 'English',
};
