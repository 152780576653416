import { GlobalVariables } from 'config/constant';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
export const dateToTimestamp = (date?: Date | string): number => {
  return dayjs(date).unix();
};
export const isAfter = (date: number | string, dateToCompare: number | string): boolean => {
  return dayjs(date).isAfter(dayjs(dateToCompare));
};

export const isBefore = (date: number, dateToCompare: number): boolean => {
  return dayjs(date).isBefore(dateToCompare);
};

export const timestampToDayjs = (timestamp: number): Dayjs => {
  return dayjs.unix(timestamp);
};

export const cleanDateString = (dateString: Date | string | null): string | null => {
  return dateString ? dayjs(dateString).format(GlobalVariables.Date.DefaultFormat) : null;
};
export const ConvertDayToNumber = (day: string) => {
  switch (day) {
    case 'lundi': {
      return 1;
    }
    case 'mardi': {
      return 2;
    }
    case 'mercredi': {
      return 3;
    }
    case 'jeudi': {
      return 4;
    }
    case 'vendredi': {
      return 5;
    }
    case 'samedi': {
      return 6;
    }
    case 'dimanche': {
      return 7;
    }
    default: {
      return null;
    }
  }
};
export const ConvertNumberToDay = (day: number) => {
  switch (day) {
    case 1: {
      return 'lundi';
    }
    case 2: {
      return 'mardi';
    }
    case 3: {
      return 'mercredi';
    }
    case 4: {
      return 'jeudi';
    }
    case 5: {
      return 'vendredi';
    }
    case 6: {
      return 'samedi';
    }
    case 7: {
      return 'dimanche';
    }
    default: {
      return null;
    }
  }
};
const translateDateToMidnight = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};
export const transformDateToEndOfTheDay = (date: Date | string | undefined | null) =>
  date ? dayjs(date).endOf('day') : date;

export const transformDateToStartOfTheDay = (date: Date | string | null | undefined) =>
  date ? dayjs(date).startOf('day') : date;

export const isValidDate = (
  watchedEndDate: string,
  watchedBeginDate?: string,
  includeToday?: boolean,
) => {
  const endDate = new Date(watchedEndDate);
  const currentDate = watchedBeginDate
    ? dateToTimestamp(new Date(watchedBeginDate))
    : includeToday
    ? dateToTimestamp(translateDateToMidnight(new Date()))
    : Date.now() / 1000;
  return includeToday
    ? dateToTimestamp(endDate) >= currentDate
    : dateToTimestamp(endDate) > currentDate;
};
export const extractDateWithFormat = (date: Date | string, format: string) =>
  dayjs(date).format(format);

export function findRecentDate(dateStrings: string[]): string | null {
  if (dateStrings.length === 0) {
    return null;
  }

  let recentDate = dayjs(dateStrings[0]);

  dateStrings.forEach((dateString) => {
    const currentDate = dayjs(dateString);

    if (currentDate.isAfter(recentDate)) {
      recentDate = currentDate;
    }
  });

  return recentDate.format(GlobalVariables.Date.DefaultFormat);
}
export const isSuperiorOrSameDate = (dateToTest: Date | string, date2: Date | string): boolean => {
  return dayjs(dateToTest).isSame(date2) || dayjs(dateToTest).isAfter(date2);
};
