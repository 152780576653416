import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckedRadioBoxStyle, CheckedRadioStyle } from './MultiRadioButtonsGroup.style';
import { MultiRadioButtonsGroupProps } from './MultiRadioButtonsGroup.type';
import { RadioBoxStyle } from 'components/CustomButtons/RadioButtonsGroup/RadioButtonsGroup.style';
import { BooleanToDom } from 'config/enums/common.enums';

export function MultiRadioButtonsGroup({
  label,
  placeholder,
  options,
  value,
  error,
  onChange,
}: MultiRadioButtonsGroupProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: number | string) => {
    if (value.includes(newValue)) {
      onChange(value.filter((item) => item !== newValue));
    } else {
      onChange([...value, newValue]);
    }
  };

  return (
    <FormControl error={!!error}>
      <Stack spacing={1}>
        {label && (
          <Stack direction="row" spacing={0.5}>
            {label && <Typography variant="h4">{t(label)}</Typography>}
            {placeholder && (
              <Typography variant="h4" color="grey.500">
                ({t(placeholder)})
              </Typography>
            )}
          </Stack>
        )}

        <Grid container rowSpacing={4}>
          {options.map((option, i) => (
            <Grid key={`${option.label}${i}`} justifyContent="flex-start" item xs={4}>
              <FormControlLabel
                value={option.value}
                control={
                  <Radio
                    size="small"
                    icon={<RadioBoxStyle error={error ? BooleanToDom.TRUE : BooleanToDom.FALSE} />}
                    checkedIcon={
                      <CheckedRadioBoxStyle>
                        <CheckedRadioStyle />
                      </CheckedRadioBoxStyle>
                    }
                    checked={value.includes(option.value)}
                    onClick={() => handleChange(option.value)}
                  />
                }
                label={t(option.label)}
              />
            </Grid>
          ))}
        </Grid>
        {error && <FormHelperText>{t(error.message as string)}</FormHelperText>}
      </Stack>
    </FormControl>
  );
}
