export const Schedule = {
  Day: 'Jour',
  CutOff: 'CutOff',
  EditButton: 'Modifier',
  SaveButton: 'Enregistrer modification',
  schedule_title: 'Tableau des planifications :',
  error_required_depot: 'Le dépôt est requis',
  error_required_sector: 'Le secteur est requis',
  category_data_empty: 'Aucune donnée de catégorie',
};
