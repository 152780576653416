import { BORouteIdEnum } from 'config/enums';
import { joinPaths } from 'utils/helpers/path.helpers';
export namespace RouterPaths {
  export namespace RootPaths {
    export const anyPath = '*';
    export const rootPath = '/';
  }
}

// NOTE - BO routes
export namespace BORouterPaths {
  export const loginPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.Auth,
    BORouteIdEnum.SignIn,
  ]);
  export const DashboardPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO]);
  export const teamsPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Teams]);
  export const usersPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Users]);
  export const clientsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Clients,
  ]);
  export const ProductsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Products,
  ]);
  export const CategoriesPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Categories,
  ]);
  export const WarehousePath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.WarehousePath,
  ]);
  export const SchedulePath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Schedule,
  ]);
  export const AdPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Ad]);
  export const NotificationPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Notification,
  ]);
  export const PromoPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Promo]);
  export const ClientsCategoryPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.ClientsCategory,
  ]);
  export const ClientsClassPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.ClientsClass,
  ]);
  export const ZonesPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Zone]);
  export const SectorPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Sector]);
  export const ProfileAccessPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.ProfileAccess,
  ]);
  export const AddSelectorPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Sector,
    BORouteIdEnum.Add,
  ]);
  export const AddCodePromoPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Promo,
    BORouteIdEnum.Add,
  ]);
  export const AddDealsPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Deals,
    BORouteIdEnum.Add,
  ]);
  export const DealsPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Deals]);
  export const AddOrder = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Order,
    BORouteIdEnum.Add,
  ]);
  export const OrderPath = joinPaths([BORouteIdEnum.Root, BORouteIdEnum.BO, BORouteIdEnum.Order]);
  export const ManageLandingPagePath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.ManageLP,
  ]);
  export const CategoriesLogisticListPath = joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.CategoriesLogistic,
  ]);
}
export const EditSectorPath = (idSector: string) =>
  joinPaths([
    BORouteIdEnum.Root,
    BORouteIdEnum.BO,
    BORouteIdEnum.Sector,
    BORouteIdEnum.Add,
    idSector,
  ]);
