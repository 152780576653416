import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-icon.svg';
import { APP_DESKTOP, APP_MOBILE, APP_TABLET } from './Navbar.constants';
import { BooleanToDom } from 'config/enums/common.enums';

export const RootStyle = styled(AppBar)(
  ({ theme }) =>
    ({ isdraweropen }: { isdraweropen: BooleanToDom }) => ({
      boxShadow: 'none',
      position: 'sticky',
      top: 0,
      backdropFilter: 'blur(6px)',
      WebkitBackdropFilter: 'blur(6px)',
      backgroundColor: alpha(theme.palette.background.default, 0.9),
      //  width: isdraweropen === BooleanToDom.TRUE ? `calc(100% - ${DRAWER_WIDTH + 1}px)` : '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'row',
    }),
);

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  padding: '0 !important',
  minHeight: APP_DESKTOP,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_DESKTOP,
  },
  [theme.breakpoints.down('md')]: {
    minHeight: APP_TABLET,
  },
  [theme.breakpoints.down('md')]: {
    minHeight: APP_MOBILE,
  },
  flexGrow: 1,
}));

export const MenuIconStyle = styled(MenuIcon)(({ theme }) => ({
  width: 30,
  height: 30,
  fill: theme.palette.primary.dark,
}));
export const UserNameStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: 5,
}));
export const ContentStyle = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: '20px 10px',
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));
