import { Typography, Stack } from '@mui/material';
import { GlobalFonts } from 'config/constant/fonts.config';

import { useTranslation } from 'react-i18next';

const NoDataFound = ({ message, minHeight }: { message: string; minHeight?: number | string }) => {
  const { t } = useTranslation();
  return (
    <Stack minHeight={minHeight} justifyContent={'center'} alignItems={'center'}>
      <Typography
        p={0}
        textAlign={'center'}
        color={'grey.400'}
        fontFamily={GlobalFonts.FONT_PRIMARY_500}
      >
        {t(message)}
      </Typography>
    </Stack>
  );
};

export default NoDataFound;
