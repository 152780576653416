export const client = {
  client: 'Client',
  clients: 'Clients',
  client_s: 'Client(s)',

  first_name: 'Prénom du client',
  first_name_placeholder: 'Natasha',

  last_name: 'Nom du client',
  last_name_placeholder: 'Dupont',

  email: 'Email de client',
  email_placeholder: 'natasha.dupont@gmail.com',

  phone: 'Téléphone',
  phone_placeholder: '+33 6 ….',

  person: 'Personne',
  persons: 'Personnes',
  person_minified: 'Pers.',
  number_person_minified: '{{number}} pers.',

  search_or_add_client: 'Rechercher ou ajouter un client',
  search_for_client: 'Rechercher un client',
  new_client: 'Nouveau client',
  add_client: 'Ajouter un client',
  add_new_client: 'Ajouter un nouveau client',
  add_client_message:
    'En ajoutant le client vous pourrez lui envoyer votre proposition de réservation.',

  sex: 'Sexe',
  last_course: 'Dernier cours',
  last_course_date: 'Date dernier cours',
  no_clients_yet: "Vous n'avez pas encore de clients",

  delete_warning_message: 'Êtes-vous sûr(e) de vouloir supprimer ce client ?',
  create_session: 'Créer une séance',

  name: 'Prénom Nom',
  zone: 'Zone',
  class: 'Classes',
  category: 'Catégories',
  manager: 'Responsable',
  seller: 'Télévendeuse',
  status: 'status',
};
