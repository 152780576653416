export interface ISnackBar {
  open: boolean;
  message: string;
  title?: string;
  severity: ESnackBarSeverity;
}

export enum ESnackBarSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}
